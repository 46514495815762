import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./pages/Login";
import Register from "./pages/Register";
import VerificationRequired from "./pages/VerificationRequired";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import Logs from "./pages/Logs";
import LogsDetails from "./pages/LogsDetails";
import Conversation from "./pages/Conversation";
import Contacts from "./pages/Contacts";
import Verification from "./pages/Verification";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";
// import VCard from "./pages/VCard";
import Campaigns from "./pages/Campaigns";
import NewCampaign from "./pages/NewCampaign.jsx";
import Billing from "./pages/Billing.jsx";
// import { isAuthenticated } from "./auth/auth";
import { refreshCSRF } from "./api/authAPI";
import {logoutSessionWithoutRedirect, logoutSession } from "./api/authAPI";
import { ContextProvider } from "./components/context/ContextProvider";
import Activate from "./pages/Activate";
import CampaignDetails from "./pages/CampaignDetails.jsx"
import ApiDoc from "./pages/ApiDoc.jsx"
import SenderId from "./pages/SenderId.jsx"
import PaymentSuccess from "./pages/paymentSuccess.jsx";
import RequestVerification from "./pages/RequestVerification"
import SendSMS from "./pages/SendSMS.jsx";
import LoginModal from "./components/modals/LoginModal.jsx";

import "./styles/common.css";
import { useState } from "react";

function App() {

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLoginModalClose = () => {
    setShowLoginModal(false);
  }

  const handleLoginModalShow = () => {
    setShowLoginModal(true);
  }


  // create a private route for admin
  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        <Component {...props} />
        // !isAuthenticated() ? (
        //   <Component {...props} />
        // ) : (
        //   <Redirect
        //     to={{ pathname: "/home", state: { from: props.location } }}
        //   />
        // )
      }
    />
  );

  // create a private route for admin
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        <Component {...props} />
        // isAuthenticated() ? (
        //   <Component {...props} />
        // ) : (
        //   <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        // )
      }
    />
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.response.status === 401 &&
        error.response.request.responseURL !==
          "https://api-dev.sms160.io/v1/web/login" &&
        error.response.status === 401 &&
        error.response.request.responseURL !==
          "https://api.sms160.io/v1/web/login"
      ) {
        document.cookie =
        "auth_t=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("csrf_t");
      localStorage.removeItem("selected_project");
      localStorage.removeItem("region");
        handleLoginModalShow();
      } else {
        if (
          error.config &&
          error.response &&
          error.response.status === 403 &&
          error.response.data.code === 1005
        ) {
        logoutSession();
        window.location.href = "/"
          return refreshCSRF(
            (data) => {
              if (
                window.location.hostname === "localhost" ||
                window.location.hostname === "127.0.0.1"
              ) {
                localStorage("csrf_t", data.id);
              }

              error.config.headers["x-csrf-token"] = data.id;
              return axios.request(error.config);
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Switch>
          <ContextProvider>
            <LoginRoute path="/" exact component={Login} />
            <LoginRoute path="/sign-up" exact component={Register} />
            <LoginRoute
              path="/verification-required"
              exact
              component={VerificationRequired}
            />
            <LoginRoute
              path="/forgot-password"
              exact
              component={ForgetPassword}
            />
            <LoginRoute
              path="/reset-password"
              exact
              component={ResetPassword}
            />
            <PrivateRoute path="/home" exact component={Dashboard} />
            <PrivateRoute path="/logs" exact component={Logs} />
            <PrivateRoute path="/logs/:id" exact component={LogsDetails} />
            <PrivateRoute
              path="/home/conversation"
              exact
              component={Conversation}
            />
            <PrivateRoute path="/contacts" exact component={Contacts} />
            <PrivateRoute path="/settings/:id" exact component={Settings} />
            <PrivateRoute path="/profile" exact component={Profile} />
            <PrivateRoute path="/campaigns" exact component={Campaigns} />
            <PrivateRoute path="/new-campaign" exact component={NewCampaign} />
            <PrivateRoute path="/campaign/:id" exact component={CampaignDetails} />
            {/* <PrivateRoute path="/vcard" exact component={VCard} /> */}
            <PrivateRoute path="/billing" exact component={Billing} />
            <LoginRoute path="/api" exact component={ApiDoc} />
            <PrivateRoute path="/sender-id" exact component={SenderId} />
            <PrivateRoute path="/verify" exact component={Verification} />
            <PrivateRoute path="/send-sms" exact component={SendSMS} />
            <LoginRoute
              path="/activate"
              exact
              component={Activate}
            />
            <LoginRoute
              path="/activated"
              exact
              component={Activate}
            />
            <PrivateRoute path="/payment-complete" exact component={PaymentSuccess} />
            <PrivateRoute path="/request-verification" exact component={RequestVerification} />
            {
              showLoginModal && <LoginModal showLoginModal={showLoginModal} handleLoginModalClose={handleLoginModalClose}/>
            }
          </ContextProvider>
        </Switch>
      </Router>
    </>
  );
}

export default App;
