import React, { useState } from "react";
import {useHistory} from "react-router-dom";
import { Modal, Form, Row, Col } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import Close from "../../images/close.png";

function VerifyAccountModal({ showModal, closeModal }) {
  const history = useHistory();
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      centered
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">Verify Account</Modal.Title>
        <img
          src={Close}
          alt="close"
          onClick={closeModal}
          style={{ cursor: "pointer"}}
          className="modal-close-btn"
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <hr className="live-hr modal-hr" />
        <div>
          <div style={{fontSize: '14px', color: 'rgb(51 51 51)', marginBottom: '30px', marginTop: '30px'}}>Your identity needs to be verified before you switching the LIVE mode.</div>
        </div>
        <Row className="">
          <Form.Label column sm="4">
          </Form.Label>
          <Col sm="7" style={{display:"flex", justifyContent: "right"}}>
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm mt-3"
              onClick={closeModal}
              style={{ width: 90, color: "#333333" }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn-submit mt-3"
              onClick={() => history.push("/verify")}
              style={{width: "33%", marginLeft: "5%"}}
            >
              Verify
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default VerifyAccountModal;
