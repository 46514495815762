import axios from "axios";

import { getCSRFToken, getTeamId } from "../auth/auth";

export const addCampaign = (data, onSuccess, onError) => {
  console.log(data);
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/campaigns
    `,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};


export const getCampaignsForProject = (projectId, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/campaigns?${projectId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getCampaignDetails = (campaignId, proId, include, onSuccess, onError) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/web/campaigns/${campaignId}?project_id=${proId}&include_sms=${include}`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};