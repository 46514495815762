import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { getUserProfileAPI, deleteOtherSessionsAPI } from "../../api/authAPI";
import moment from "moment";
import ProjectTable from "./ProjectTable";
import { getTeamAPI } from "../../api/teamAPI";

import "../../styles/profile.css";
import LoginSessionsTable from "./LoginSessionsTable";
import { ProfileContext } from "../context/ContextProvider";

const ProfileComponent = () => {
  const { userProfile, setUserProfile } = useContext(ProfileContext);
  const [ loading, setLoading ] = useState(false);
  const [team, setTeam] = useState(
    localStorage.getItem("team")
      ? JSON.parse(localStorage.getItem("team"))
      : ""
  );

  useEffect(() => {
    if (!team) {
      getTeamAPI(
        (data) => {
          setTeam(data[0]);
          localStorage.setItem("team", JSON.stringify(data[0]));
        },
        (err) => {
          console.log(err);
        }
      );
    }
  })

  const handleLogoutFromAll = () => {
    console.log("logout");
    setLoading(true);
    deleteOtherSessionsAPI(
      (data) => {
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }

  let history = useHistory();

  return (
    <>
      <Container>
        <Link to="#" onClick={() => history.goBack()} className="dash-back-btn">
          <i className="fas fa-angle-left"></i> Back
        </Link>
        <Row className="m-0 cus-content-div">
          <Col className="p-0">
            {userProfile?.length === 0 ? (
              <div className="loading-container">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>
                <Col>
                  <h5 className="sec-heading">Account Settings</h5>
                </Col>

                <div className="form-wrapper">
                  <Col xl="6">
                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Email
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            readOnly
                            type="text"
                            className="custom-form-control"
                            name={userProfile.email}
                            value={userProfile.email}
                          />
                          {/* <div className="input-action-btn">Change Email</div> */}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          First Name
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            readOnly
                            type="text"
                            className="custom-form-control"
                            name={userProfile.first_name}
                            value={userProfile.first_name}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Last Name
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            readOnly
                            type="text"
                            className="custom-form-control"
                            name={userProfile.last_name}
                            value={userProfile.last_name}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Account Created
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            readOnly
                            type="text"
                            className="custom-form-control"
                            name={moment
                              .utc(userProfile.created_at)
                              .local()
                              .format("YYYY-MMM-DD")}
                            value={moment
                              .utc(userProfile.created_at)
                              .local()
                              .format("YYYY-MMM-DD")}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Region
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            readOnly
                            type="text"
                            className="custom-form-control"
                            name={team.region.country.name}
                            value={team.region.country.name}
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                </div>

                <Col className="mt-5">
                  <h5 className="sec-heading">Projects</h5>
                </Col>
                <ProjectTable />

                <Col xl="8" className="mt-5">
                  <Row className="login-sessions-main-wrapper">
                    <Col>
                      <h5 className="sec-heading">Login Sessions</h5>
                    </Col>
                    <Col xs={4} className="logout-sessions-wrapper">
                      <div className="input-action-btn" onClick={() => handleLogoutFromAll()}>
                        {loading ? <Spinner animation="border" size="md" /> : "Logout of all other sessions"}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <LoginSessionsTable loading={loading}/>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProfileComponent;
