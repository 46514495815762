import axios from "axios";
import qs from "qs";

import { getCSRFToken, getTeamId } from "../auth/auth";

export const sendMessage = (data, onSuccess, onError) => {
  // const data = qs.stringify({
  //   project_id: "pr_f4WLyqi3HB7mba566fpI05SI",
  //   mode: "LIVE",
  //   to: "+61491768579",
  //   from: "SerendibSys",
  //   body:
  //     "To get the duration of a difference between two moments, you can pass diff as an argument into moment#duration. See the docs on moment#duration for more info. To get the duration of a difference between two moments, you can pass diff as an argument into moment#duration. See the docs on moment#duration for more info.",
  //   status_callback:
  //     "https://api-dev.sms160.io/v1/web/messages/sms_f4h4hOq90LZGSicQKS6vYtBg86DpQP8s?project_id=pr_f2obMqORdYOtTqZ2p2HvJPeO",
  // });

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/messages
    `,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getMessages = (
  mode,
  // from,
  // to,
  range,
  id,
  pageNumb,
  pageSize,
  onSuccess,
  onError
) => {
  axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/v1/web/messages?mode=${mode}&range=${range}&project_id=${id}&page_num=${
        pageNumb - 1
      }&page_size=${pageSize}&sort_order=DESC
    `,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getMessageDetails = (smsId, proId, onSuccess, onError) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/web/messages/${smsId}?project_id=${proId}`,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};
