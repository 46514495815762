import React from "react";

import DashboardLayout from "../components/layouts/DashboardLayout";
import CampaignDetailsComp from "../components/campaigns/CampaignDetailsComp";

function CampaignDetails() {
  return (
    <DashboardLayout>
      <CampaignDetailsComp />
    </DashboardLayout>
  );
}

export default CampaignDetails;
