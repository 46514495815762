import React from "react";

import DashboardLayout from "../components/layouts/DashboardLayout";
import LogsComp from "../components/logs/LogsComp";
import PageTitle from "../components/common/PageTitle";

function Logs() {
  return (
    <DashboardLayout>
      <PageTitle page="Logs" />
      <LogsComp />
    </DashboardLayout>
  );
}

export default Logs;
