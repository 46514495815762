import React, { useContext, useEffect, useState } from "react";
import { Col, Table } from "react-bootstrap";
import { ProjectContext } from "../context/ContextProvider";
import Pagination from "@material-ui/lab/Pagination";

const ProjectTable = () => {
  const [page, setPage] = useState(1);

  const { projects } = useContext(ProjectContext);

  // pagination
  const itemsPerPage = 10;
  const noOfPages = Math.ceil(projects.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = projects.slice(indexOfFirst, indexOfLast);

  const handlePagination = (event, value) => {
    setPage(value);
  };

  return (
    <Col xl="8">
      <Table
        responsive
        style={{ width: "100%" }}
        className="custom-table sms-custom-table"
      >
        <thead className="table-header-bg">
          <tr>
            <th>NAME</th>
            <th>ROLE</th>
            <th>TIMEZONE</th>
            <th>ID</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((project) => (
              <tr key={project.id}>
                <td>{project.name}</td>
                <td className="project-role-text">{project.role}</td>
                <td>{project.timezone}</td>
                <td>{project.id}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="pagination-div">
        {noOfPages > 1 && (
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handlePagination}
            className="pagi"
          />
        )}
      </div>
    </Col>
  );
};

export default ProjectTable;
