import React from "react";
import ApiLayout from "../components/layouts/ApiLayout";
import ApiDocComp from "../components/api/ApiDocComp";
import PageTitle from "../components/common/PageTitle";

function ApiDoc() {
  return (
    <ApiLayout>
      <PageTitle page="Api Doc" />
      <ApiDocComp />
    </ApiLayout>
  );
}

export default ApiDoc;