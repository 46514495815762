import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import qs from "qs";
import classnames from "classnames";
import { Container, Row, Col, Form, ProgressBar, Spinner } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/campaigns.css";
import { getGroups } from "../../api/groupAPI";
import { getPendingSenderIdRequestsAPI , requestSenderIdAPI} from "../../api/ProjectAPI";
import { useHistory } from "react-router-dom";
import { uploadFileAPI } from "../../api/genericAPI";
import { toast } from "react-toastify";
import PendingSenderIdComp from "./PendingSenderIdComp";

const initial = {
  id: "",
  idErr: "",
  letterOneErr: "",
  letterTwoErr: ""
};

function RequestSendeIdComp() {
  let history = useHistory();
  const [state, setState] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [id, setId] = useState("");
  const [requestRegLetter, setRequestRegLetter] = useState("No file uploaded");
  const [isRequestRegLetter, setIsRequestRegLetter] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [requestBusLetter, setRequestBusLetter] = useState("No file uploaded");
  const [isRequestBusLetter, setIsRequestBusLetter] = useState(false);
  const [busUploadPercentage, setBusUploadPercentage] = useState(0);
  const [proId, setProId] = useState(
    localStorage.getItem("selected_project")
      ? localStorage.getItem("selected_project")
      : "pr_123456789abcde"
  );
  const [isPendingRequestsAvailable, setIsPendingRequestsAvailable] = useState(false);
  const [pendingRequestObj, setPendingRequestObj] = useState({});
  const [isPromotional, setIsPromotional] = useState(true);
  const [description, setDescription] = useState("");
  const [legalName, setLegalName] = useState("");

  useEffect(() => {
    fetchPendingRequests();
  }, []);

  const fetchPendingRequests = () => {
    setPageLoading(true);
    getPendingSenderIdRequestsAPI(
      proId,
      (data) => {
       if (data.length > 0) {
        setIsPendingRequestsAvailable(true);
        setPendingRequestObj(data);
       }
       setPageLoading(false);
      },
      (err) => {
        console.log(err);
        setPageLoading(false);
      }
    );
  };
 
  const handleChangeId = (e) => {
   setId(e.target.value);
  let idErr = "";
   setState({
    ...state,
    idErr
    });
  };

  const handleChangeLegalName = (e) => {
    setLegalName(e.target.value);
    let legalNameErr = "";
    setState({
     ...state,
     legalNameErr
     });
   };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
     let descriptionErr = "";
    setState({
     ...state,
     descriptionErr
     });
  };

  // validate
  const validate = () => {
    let idErr = "";
    let letterOneErr = "";
    let letterTwoErr = "";
    let descriptionErr = "";
    let legalNameErr = "";

    if (!id) {
      idErr = "Sender ID is Required ";
    }
    // if (!isRequestBusLetter) {
    //   letterOneErr = "Business Registration Letter is Required";
    // }
    // if (!isRequestRegLetter) {
    //   letterTwoErr = "Request Letter is Required";
    // }
    if (!description) {
      descriptionErr = "Description is Required";
    }
    if (!legalName) {
      legalNameErr = "Legal Name is Required";
    }
    
    if (idErr || letterOneErr || letterTwoErr || descriptionErr || legalNameErr) {
      setState({
        ...state,
        idErr,
        letterOneErr,
        letterTwoErr,
        descriptionErr,
        legalNameErr
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const data = {
        sender_id: id,
        is_promotional: isPromotional,
        description: description,
        legal_name: legalName,
        // documents: [
        //   {id: requestBusLetter, name : "business-registration-letter"},
        //   {id: requestRegLetter, name : "request-letter"},
        // ]
      };

      requestSenderIdAPI(
        proId,
        data,
        (data) => {
          setLoading(false);
          toast.success("Sender ID requested successfullly");
          fetchPendingRequests();
          history.replace("/settings/"+proId);
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again");
        }
      );
    }
  };

  const handleRemoveSubmitBusLetter = (e) => {
    setIsRequestBusLetter(false);
    setRequestBusLetter("");
  }


const handleSubmitBusLetter = (e) => {
  let imageFile = new FormData();
  imageFile.append("file", e.target.files[0]);

  uploadFileAPI(
    imageFile,
    (uploadProgress) => {
      setBusUploadPercentage(uploadProgress);
    },
    (data) => {
      setBusUploadPercentage(100);
      setRequestBusLetter(data.data.id);
      setIsRequestBusLetter(true);
      let letterOneErr = "";
      setState({
        ...state,
        letterOneErr
      });
    },
    (err) => {
      setLoading(false);
      toast.error("Something went wrong. Please try again");
    }
  );
};

const handleSubmitFileRemove = (e) => {
  setIsRequestRegLetter(false);
  setRequestRegLetter("");
}

const handleSubmitFile = (e) => {
  let imageFile = new FormData();
  imageFile.append("file", e.target.files[0]);
  
  uploadFileAPI(
    imageFile,
    (uploadProgress) => {
      setUploadPercentage(uploadProgress);
    },
    (data) => {
      setUploadPercentage(100);
      setRequestRegLetter(data.data.id);
      setIsRequestRegLetter(true);
      let letterTwoErr = "";
      setState({
        ...state,
        letterTwoErr
      });
    },
    (err) => {
      setLoading(false);
      toast.error("Something went wrong. Please try again");
    }
  );
};

const onChangeRadio = (val) => {
  setIsPromotional(val)
};

  return (
    (pageLoading) ? (
      <div className="loading-container">
        <Spinner animation="border" size="md" />
      </div>
    ) : (
      <>
        <Container className="container">
        <Link to={"/settings/"+proId} className="dash-back-btn">
          <i className="fas fa-angle-left"></i> Back
        </Link>
          <Row className="m-0 cus-content-div">
            <Form style={{paddingLeft: '0', paddingRight: '0'}}>
              <div className="main-header-div">
                <div className="header-div">
                  <h5 className="sec-heading2">Request an Alphanumeric Sender ID</h5>
                </div>
                <hr className="live-hr" />
              </div>
              <div>
                <div className="desc-title">What is an alphanumeric sender id?</div>
                <div className="desc-div">In certain countries we can display an alphanumeric word or phrase like ‘SMS160’ or ‘SMS160 Promo’ instead of the senders phone number. You can use ‘SMS160_Test’ as your test sender id when you register with us.</div>
              </div>
              <div>
                <div className="desc-title">Why do I need to request an id?</div>
                <div className="desc-div">Due to Governtment regulations in some countries, we need to request permission from our network providers to use sender ids when sending sms messages. Please contact support@sms160.io if your request is not approved within 7 business days.</div>
              </div>
              <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Sender ID
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      className={classnames("custom-form-control", {
                          "is-invalid": state.idErr,
                      })}
                      name="name"
                      value={id}
                      onChange={handleChangeId}
                      autoComplete="off"
                    />
                      <Form.Control.Feedback type="invalid">
                        {state.idErr}
                      </Form.Control.Feedback>
                      <div style={{background:"#e3f2fd", fontSize:"14px", padding:"10px", marginTop:"20px", marginBottom:"20px", textAlign:"left"}}>
                        Sender ids must :
                        <ul>
                          <li>Be between 3 and 11 characters long</li>
                          <li>Contain letters or numbers. Underscore and spaces are the only allowed special characters. </li>
                          <li>Contain at least one letter</li>
                          <li>Not be similar to a well known business or public figure</li>
                        </ul>
                      </div>
                  </Col>
                </Form.Group>
              </Form>
              <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Sender ID Type
                  </Form.Label>
                  <Col sm="7">
                    <div style={{background:"#e3f2fd", fontSize:"14px", padding:"10px", marginTop:"20px", marginBottom:"20px", textAlign:"left"}}>
                      Please specify the type of messages you will be sending with this sender id. Transactional sender ids can only be used for non-promotional customer specific messages. If you need to send any type of promotional or marketing messages you must select <b>Promotional</b> as the type. Alphanumeric sender ids are regulated by local authorities and operators and their use may be suspended if you violate this condition.
                    </div>
                    <div>
                      <Form.Check
                        label="Transactional"
                        name="group1"
                        type="radio"
                        onChange={event => onChangeRadio(false)}
                        style={{fontWeight: "500"}}
                      />
                      <p className="id-type-desc">
                        Eg: OTP messages, order or booking notifications, two-factor authentication, account notification or similar non promotional messages. Message segment lenght: 160 characters. 
                      </p>
                      <Form.Check
                        label="Promotional"
                        name="group1"
                        type="radio"
                        defaultChecked={true}
                        onChange={event => onChangeRadio(true)}
                        style={{fontWeight: "500"}}
                      />
                      <p className="id-type-desc">
                        Any form of marketing or promotional messages. Message segment length: 120 characters.
                      </p>
                    </div>
                  </Col>
                </Form.Group>
              </Form>
              <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Describe Sender ID Usage
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      multiline
                      rows={3}
                      as="textarea"
                      type="text"
                      className={classnames("custom-form-control", {
                          "is-invalid": state.descriptionErr,
                      })}
                      name="description"
                      value={description}
                      onChange={handleChangeDescription}
                      autoComplete="off"
                    />
                      <Form.Control.Feedback type="invalid">
                        {state.descriptionErr}
                      </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Form>
              <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                  Legal Name of Business or Individual
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      className={classnames("custom-form-control", {
                          "is-invalid": state.legalNameErr,
                      })}
                      name="legalName"
                      value={legalName}
                      onChange={handleChangeLegalName}
                      autoComplete="off"
                    />
                      <Form.Control.Feedback type="invalid">
                        {state.legalNameErr}
                      </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Form>
              {/* <h6 className="settings-subtitle" style={{ color: "#333333", fontSize: "1rem", fontWeight: "500" }}>Upload Supporting Documents</h6> */}
              {/* <Form>
                <Form.Group as={Row} style={{ marginTop: "8px" }}>
                  <Form.Label column sm="2">
                    Business Registration
                  </Form.Label>
                  <Col sm="5">
                    <h6 className="settings-subtitle" style={{paddingLeft:"0px"}}>Business Registration of your company</h6>
                    <div style={{fontSize: "14px"}}>{requestBusLetter}</div>

                    {busUploadPercentage > 0 && (
                        <div className="uploading-image-progressbar">
                          { busUploadPercentage > 0 && busUploadPercentage < 100
                              ? <ProgressBar now={busUploadPercentage} />
                              : ""}
                        </div>
                      )}
                    <div style={{display:"flex"}}>
                      {!isRequestBusLetter && (
                          <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex"}}>
                            <input type="file" name="file" id="icon-button-file-reg" style={{ display: 'none' }} onChange={handleSubmitBusLetter}/>
                            <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-reg">Upload</label>
                          </div>
                        )}

                        {isRequestBusLetter && (
                          <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex"}}>
                            <input type="button" name="file-remove" id="icon-button-file-remove-reg" style={{ display: 'none' }} onClick={handleRemoveSubmitBusLetter}/>
                            <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-remove-reg">Remove</label>
                          </div>
                        )}
                    </div>
                    <div id="letter-one-error" style={{color:"#e04d5b", fontSize:".875em"}}>{state.letterOneErr}</div>
                  </Col>
                </Form.Group>
              </Form> */}
              {/* <Form style={{marginTop: "33px"}}>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Request Letter
                  </Form.Label>
                  <Col sm="5">
                    <h6 className="settings-subtitle" style={{paddingLeft:"0px"}}>A letter clearly mentioning the business and website related to this sender id and how you intend to use it.</h6>
                    <div style={{fontSize: "14px"}}>{requestRegLetter}</div>

                    {uploadPercentage > 0 && (
                        <div className="uploading-image-progressbar">
                          { uploadPercentage > 0 && uploadPercentage < 100
                              ? <ProgressBar now={uploadPercentage} />
                              : ""}
                        </div>
                      )}
                    <div style={{display:"flex"}}>
                      {!isRequestRegLetter && (
                        <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex"}}>
                          <input type="file" name="file" id="icon-button-file" style={{ display: 'none' }} onChange={handleSubmitFile}/>
                          <label style={{cursor:"pointer",color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file">Upload</label>
                        </div>
                      )}
                      {isRequestRegLetter && (
                        <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex"}}>
                          <input type="button" name="file-remove" id="icon-button-file-remove" style={{ display: 'none' }} onClick={handleSubmitFileRemove}/>
                          <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-remove">Remove</label>
                        </div>
                      )}
                      <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", fontSize: "14px", color: "#5e9aff", marginLeft: "10px"}}>
                        <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank">Download Example</a>
                      </div>
                    </div>
                    <div id="letter-one-error" style={{color:"#e04d5b", fontSize:".875em"}}>{state.letterTwoErr}</div>
                  </Col>
                </Form.Group>
              </Form> */}
              <Row className="" style={{marginTop: "-30px"}}>
                  <Form.Label column sm="2">
                  </Form.Label>
                  <Col sm="7" style={{display: 'flex', justifyContent: 'right'}}>
                    <button
                      type="submit"
                      className="btn-submit mt-3 save-btn"
                      onClick={handleSubmit}
                      style={{width: "200px"}}
                    >
                      {loading ? (
                        <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}
                      {loading ? "" : "Request Sender ID"}
                    </button>
                  </Col>
              </Row>
              {isPendingRequestsAvailable &&
                <div>
                  <div className="main-header-div" style={{marginTop: "50px"}}>
                    <div className="header-div">
                      <h5 className="sec-heading2">Pending Sender ID Requests</h5>
                    </div>
                    <hr className="live-hr" />
                  </div>

                  {pendingRequestObj.map((el, i) => (
                    <div style={{marginTop: "20px", color: "#333333", fontSize: "14px"}}>
                      <div><b>{el.sender_id}</b></div>
                      <div>Type: {el.is_promotional ? "Promotional" : "Transactional"}</div>
                      <div>Requested: {el.created_at.split("T")[0]}</div>
                    </div>
                  ))}
                </div>
              }
            </Form>
          </Row>
        </Container>
      </>
    )
  );
}

export default withRouter(RequestSendeIdComp);
