import React from "react";

import DashboardLayout from "../components/layouts/DashboardLayout";
import ConversationComp from "../components/logs/ConversationComp";

function Conversation() {
  return (
    <DashboardLayout>
      <ConversationComp />
    </DashboardLayout>
  );
}

export default Conversation;
