import axios from "axios";
import { getCSRFToken, getTeamId } from "../auth/auth";
import { toast } from "react-toastify";

export const getAccountUsageForMonth = (mode, month, year, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/usage?mode=${mode}&month=${month}&year=${year}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
      toast.error('Error occured. Please try again later.', {position: "top-right"});
    });
};