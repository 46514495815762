import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Dropdown,
  Table,
  Spinner,
  Badge
} from "react-bootstrap";
import {
  Switch,
  FormControlLabel
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { sendMessage, getMessages } from "../../api/smsAPI";
import Delivered from "../../images/sms-status/delivered.png";
import Failed from "../../images/sms-status/failed.png";
import { withStyles } from '@material-ui/core/styles';
import VerifyAccountModal from "../modals/VerifyAccountModal";
import { ProfileContext } from "../context/ContextProvider";

function LogsComp({location}) {
  const history = useHistory()
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [proId, setProId] = useState(
    localStorage.getItem("selected_project")
      ? localStorage.getItem("selected_project")
      : ""
  );
  const [mode, setMode] = useState(
    localStorage.getItem("mode")
      ? localStorage.getItem("mode")
      : "LIVE"
  );
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [filterBy, setFilterBy] = useState("Today");
  const [preLoading, setPreLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [messageCount, setMessageCount] = useState(0);
  const [verifyModal, setVerifyModal] = useState(false);
  const { userProfile, setUserProfile } = useContext(ProfileContext);
  const [isIdVerified, setIsIdVerified] = useState(true);
  const [filterRange, setFilterRange] = useState('1d');

  useEffect(() => {
    if (userProfile && userProfile.length !== 0) {
      setIsIdVerified(userProfile.id_verified);
    }
  });

  useEffect(()=>{
    if(location && location.state && location.state.mode && location.state.page && location.state.from && location.state.to && location.state.filterBy && location.state.itemsPerPage) {
      setTimeout(function () {
        setPage(location.state.page);
        setFilterBy(location.state.filterBy);
        setFrom(location.state.from);
        setTo(location.state.to);
        setMode(location.state.mode);
        setItemsPerPage(location.state.itemsPerPage);
      }, 1500);
    }
  },[location])

  useEffect(() => {
    // sendMessage(
    //   (data) => {},
    //   (err) => {
    //     console.log(err);
    //   }
    // );

    // let from = getFromToDate("Today").from;
    // let to = getFromToDate("Today").to;

    // setFrom(from);
    // setTo(to);
  }, []);

  useEffect(() => {
    fetchMessages();
  }, [from, to, mode, page, itemsPerPage]);

  const fetchMessages = () => {
    setLoading(true);
    getMessages(
      mode,
      // from,
      // to,
      filterRange,
      proId,
      page,
      itemsPerPage,
      (data) => {
        setPreLoading(false);
        setMessages(data.results);
        setNoOfPages(data.total_pages);
        setLoading(false);
        setMessageCount(data.total)
      },
      (err) => {
        setPreLoading(false);
        setLoading(false);
        console.log(err);
      }
    );
  };

  const handleFilter = (type) => {
    setFilterBy(type);
    setPage(1);

    let from = getFromToDate(type).from;
    let to = getFromToDate(type).to;

    setFrom(from);
    setTo(to);

    switch (type) {
      case "Today":
        setFilterRange('1d');
        break;
      case "Last 7 days":
        setFilterRange('7d');
        break;
      case "Last 4 weeks":
        setFilterRange('4w');
        break;
      case "Last 3 months":
        setFilterRange('3m');
        break;
      case "Last 12 months":
        setFilterRange('12m');
        break;
      default:
        setFilterRange('1d');
        break;
    }
  };

  const handlePageSize = (size) => {
    setItemsPerPage(size);
    setPage(1);
  }

  const getFromToDate = (type) => {
    let from = null;
    let to = moment().format("YYYY-MM-DD");

    switch (type) {
      case "Today":
        from = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        from = moment(moment().subtract(7, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 4 weeks":
        from = moment(moment().subtract(30, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 3 months":
        from = moment(moment().subtract(90, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 12 months":
        from = moment(moment().subtract(365, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      default:
        from = null;
        break;
    }

    return { from: from, to: to };
  };

  const handleMode = (type) => {
    if (isIdVerified) {
      type = (type === "TEST") ? "LIVE" : "TEST";
      setMode(type);
      localStorage.setItem("mode", type);
      setPage(1);
    } else {
      setVerifyModal(true);
    }
  };

  const handleCloseVerifyModal = () => {
    setVerifyModal(false);
  };

  const renderStatus = (param) => {
    switch(param) {
      case 'DELIVERED':
        return'Delivered ';
      case 'ACCEPTED':
        return 'Queued ';
      case 'SENT':
        return 'Sent ';
      case 'SEND_ERROR':
        return 'Failed ';
      default:
        return 'Failed ';
    }
  }

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "SMS Log Report";
    const headers = [
      ["Date", "API", "From", "To", "Message ID", "Segments", "Status"],
    ];

    const dataAll = messages.map((elt) => [
      moment.utc(elt.accepted_at).local().format("YYYY-MM-DD hh:mm:ss a"),
      elt.direction === "OUTGOING"
        ? "Outgoing"
        : elt.direction === "INCOMING"
        ? "Incoming"
        : "Verify",
      elt.from,
      elt.to,
      elt.id,
      elt.segments,
      elt.status === "SENT"
        ? "Sent"
        : elt.status === "FAILED"
        ? "Failed"
        : "Delivered",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: dataAll,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  const CustomSwitch = withStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: 'white',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#EF6C00',
          borderColor: 'white',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid white`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#BDBDBD',
    },
    checked: {},
  })(Switch);

  return (
    <Container className="container main-container">
      <Row className="m-0 cus-content-div">
        <Col className="p-0">
          <div className="main-header-div" style={{ margin: "auto" }}>
            <div className="header-div">
              <h5 className="sec-heading2">SMS Logs</h5>
              <FormControlLabel
                label="Test Mode"
                labelPlacement="start"
                className={(mode === "TEST") ? "switch-label-test" : "switch-label-live"}
                control={
                  <CustomSwitch
                    checked= {(mode === "TEST")}
                    onChange={() => handleMode(mode)}
                    name="checkedB"
                    color="primary"
                  />
                }
              />
            </div>
            <hr className={mode=="LIVE" ? 'live-hr' : 'test-hr'}/>
            {mode == 'TEST' && (
              <Badge bg="warning" text="dark" className="test-label">
                TEST MODE
              </Badge>
            )}
          </div>
          {preLoading ? (
            <div className="loading-container">
              <Spinner animation="border" size="md" />
            </div>
          ) : (
            <>              
              <div style={{ margin: "auto" }}>
                <div className="page-secondary-header">
                  <div className="page-header-drop-downs">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-secondary"
                        id="dropdown-basic"
                        size="sm"
                        className="page-drop-down"
                      >
                        {filterBy}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handleFilter("Today")}
                        >
                          Today
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handleFilter("Last 7 days")}
                        >
                          Last 7 days
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handleFilter("Last 4 weeks")}
                        >
                          Last 4 weeks
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handleFilter("Last 3 months")}
                        >
                          Last 3 months
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handleFilter("Last 12 months")}
                        >
                          Last 12 months
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                          as="button"
                          onClick={() => handleFilter("All time")}
                        >
                          All time
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="page-size-selecter">
                      <Dropdown.Toggle
                        variant="outline-secondary"
                        id="dropdown-basic"
                        size="sm"
                        className="page-drop-down"
                      >
                        {itemsPerPage} Per Page
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handlePageSize(25)}
                        >
                          25 Per Page
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handlePageSize(50)}
                        >
                          50 Per Page
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handlePageSize(75)}
                        >
                          75 Per Page
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => handlePageSize(100)}
                        >
                          100 Per Page
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    {noOfPages > 0 && (
                      <div className="download-report-div create-btn-div">
                        <button onClick={() => exportPDF()}>Download</button>
                        {/* <button onClick={() => exportPDF()}>
                          Download Full Report
                        </button> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{ position: "relative", margin: "auto" }}
              >
                <div className="data-details">
                  {(noOfPages > 0) ? (
                    <p className="page-numbering">
                      Page <b>{page} of {noOfPages}</b>
                    </p>
                  ) : (
                    <p className="page-numbering">
                      Page <b>{0} of {0}</b>
                    </p>
                  )}
                  <p className="page-numbering">
                    <b>{messageCount}</b> Results
                  </p>
                </div>
                <Table
                  className="custom-table"
                  responsive
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>API</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Message ID</th>
                      <th>Segments</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7" align="center">
                          <Spinner animation="border" size="sm" />
                        </td>
                      </tr>
                    ) : messages.length > 0 ? (
                      messages.map((el, i) => (
                        <tr
                          key={i}
                          onClick={() => history.replace(`/logs/${el.id}`, {mode, page, from, to, filterBy, itemsPerPage})}
                        >
                          <td>
                            {moment
                              .utc(el.accepted_at)
                              .local()
                              .format("YYYY-MM-DD hh:mm:ss a")}
                          </td>
                          <td>
                            {el.direction === "OUTGOING"
                              ? "Outgoing"
                              : el.direction === "INCOMING"
                              ? "Incoming"
                              : "Verify"}
                          </td>
                          <td>{el.from}</td>
                          <td>{el.to}</td>
                          <td style={{fontFamily:'monospace'}}>{el.id}</td>
                          <td align="center">{el.segments}</td>
                          <td>
                            {renderStatus(el.status)}
                            {el.status === "DELIVERED" ? (
                              <img src={Delivered} alt="delivered" />
                            ) : el.STATUS === "FAILED" ? (
                              <img src={Failed} alt="failed" />
                            ) : (
                              ""
                            )}
                            
                            {/* {el.status === "SENT"
                              ? "Sent"
                              : el.status === "FAILED"
                              ? "Failed"
                              : "Delivered"}{" "}
                            {el.status === "DELIVERED" ? (
                              <img src={Delivered} alt="delivered" />
                            ) : el.STATUS === "FAILED" ? (
                              <img src={Failed} alt="failed" />
                            ) : (
                              ""
                            )} */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" align="center">
                          No records
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="pagination-div">
                {noOfPages > 1 && (
                  <Pagination
                    count={noOfPages}
                    page={page}
                    onChange={handlePagination}
                    className="pagi"
                  />
                )}
              </div>
            </>
          )}
        </Col>
      </Row>
      <VerifyAccountModal
        showModal={verifyModal}
        closeModal={handleCloseVerifyModal}
      />
    </Container>
  );
}

export default withRouter(LogsComp);
