import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Form, ProgressBar, Spinner } from "react-bootstrap";
import { getPendingUserVerificationRequests, requestUserVerificationAPI } from "../../api/authAPI";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getCSRFToken } from "../../auth/auth";
import { MdTimelapse } from "react-icons/md";
import moment from "moment";

const IdVerification = () => {
  let history = useHistory();

  const page1 = useRef();
  const page2 = useRef();

  const [frontPage, setFrontPage] = useState(null);
  const [backPage, setBackPage] = useState(null);
  const [accountType, setAccountType] = useState("individual");
  const [documentType, setDocumentType] = useState("National Identity Card");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const [pageReRender, setPageReRender] = useState(false);
  const [pendingData, setPendingData] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState([
    { id: 1, percentage: 0, error: false, error: false, uploaded: false },
    { id: 2, percentage: 0, error: false, error: false, uploaded: false },
  ]);

  const getPendingRequests = () => {
    getPendingUserVerificationRequests(
      (data) => {
        if (data && data.length > 0) {
          setIsPending(true);
          setIsChecking(false);
          setPendingData(data);
        } else {
          setIsPending(false);
          setIsChecking(false);
        }
      },
      (err) => {
        console.log(err);
        setIsChecking(false);
      }
    );
  };

  useEffect(() => {
    getPendingRequests();
  }, []);

  const handleFrontPage = (event) => {
    setFrontPage(event.target.files[0]);
  };

  const handleBackPage = (event) => {
    setBackPage(event.target.files[0]);
  };

  const handleChangeAccount = (event) => {
    if (event.target.value === "business") {
      setDocumentType("Business Registration");
    }
    if (event.target.value === "individual") {
      setDocumentType("National Identity Card");
    }
    setAccountType(event.target.value);
  };

  const handleChangeDoc = (event) => {
    setDocumentType(event.target.value);
  };

  const reset = (page) => {
    const newStatus = [...uploadPercentage];
    if (page === "page1") {
      page1.current.value = "";
      setFrontPage(null);
      newStatus[0] = { id: 1, percentage: 0, error: false, uploaded: false };
      setUploadPercentage(newStatus);
    }
    if (page === "page2") {
      page2.current.value = "";
      setBackPage(null);
      newStatus[1] = { id: 2, percentage: 0, error: false, uploaded: false };
      setUploadPercentage(newStatus);
    }
  };

  useEffect(() => {
    if (isError && frontPage !== null) {
      setIsError(false);
    }
  }, [frontPage]);

  const handleSubmit = async () => {
    if (frontPage === null) {
      setIsError(true);
    } else {
      setIsLoading(true);
      // setIsDisable(true);

      const selectedFiles = [];
      selectedFiles.push(frontPage);
      if (backPage) {
        selectedFiles.push(backPage);
      }

      let id = 0;
      let latestState = [];
      const newStatus = [...uploadPercentage];
      const uploadedFiles = [];
      for (const file of selectedFiles) {
        id++;
        const imageFile = new FormData();
        imageFile.append("file", file);

        let config = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);

            if (percent < 100) {
              newStatus[id - 1] = { id: id, percentage: percent, error: false, uploaded: false };
              setUploadPercentage(newStatus);
            }
          },
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-csrf-token": getCSRFToken(),
          },
        };

        await axios
          .post(`${process.env.REACT_APP_API_URL}/v1/web/generic/file_upload`, imageFile, config)
          .then((response) => {
            const docInfo = {
              name: documentType,
              id: response.data.id,
            };
            uploadedFiles.push(docInfo);
            newStatus[id - 1] = { id: id, percentage: 100, error: false, uploaded: true };
            setUploadPercentage(newStatus);
            latestState.push({ id: id, percentage: 100, error: false, uploaded: true });
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error("Something went wrong. Please try again");
            newStatus[id - 1] = { id: id, percentage: 0, error: true, uploaded: false };
            setUploadPercentage(newStatus);
            latestState.push({ id: id, percentage: 0, error: true, uploaded: false });
            console.log(error);
          });
      }

      const checkAllUploaded = latestState.every((doc) => doc.error === false);
      if (checkAllUploaded) {
        requestUserVerificationAPI(
          uploadedFiles,
          (data) => {
            setIsLoading(false);
            setIsDisable(true);
            setPageReRender(!pageReRender);
            toast.success("Verification request sent successfully");
            getPendingRequests();
          },
          (err) => {
            console.log(err);
            toast.error(err.response.data.description);
          }
        );
      } else{
        setIsLoading(false);
        setPageReRender(!pageReRender);
      }
    }
  };

  const isDelectable = (index) => {
    if (isLoading) {
      return false;
    }
    if (!uploadPercentage[index].uploaded && !uploadPercentage[index].error) {
      return true;
    }
    if (uploadPercentage[index].uploaded) {
      return false;
    }
    if (!uploadPercentage[index].error) {
      return false;
    }
    return true;
  };

  return (
    <Container className="main-container">
      {isChecking ? (
        <Row className="m-0 cus-content-div">
          <div className="data-checking-spinner">
            <Spinner animation="border" role="status"></Spinner>
            <span>Loading... Please wait.</span>
          </div>
        </Row>
      ) : (
        <Row className="m-0 cus-content-div">
          {isPending ? (
            <Col className="p-0">
              <div className="header-div-left">
                <h5 className="sec-heading2">ID Verification</h5>
                <p className="pending-verify-note">
                  You have a pending verification request. It may take up to 2 business days for a request to be assessed. You can contact support@sms160.io if it does not get resolved within this
                  time.
                </p>
              </div>

              {pendingData.map((data) => (
                <div key={data.id} className="verify-info-list">
                  <div className="pending-item-info">
                    <span className="pending-item-title">Submitted At:</span>
                    <span>{moment(data.created_at).format("Do MMMM YYYY, hh:mm a ")}</span>
                  </div>

                  <p className="pending-item-title">Documents:</p>
                  {data?.attachments.map((attach) => (
                    <div key={attach.id} className="verify-pending-list">
                      <i>
                        <MdTimelapse />
                      </i>
                      <span>{attach.name}</span>
                    </div>
                  ))}
                </div>
              ))}

              <div className="action-buttons-center">
                <Col sm="2">
                  <button type="button" className="btn-submit mt-3" onClick={() => history.replace("/home")}>
                    Go to Home
                  </button>
                </Col>
              </div>
            </Col>
          ) : (
            <Col className="p-0">
              <div className="header-div-left">
                <h5 className="sec-heading2">ID Verification</h5>
                <p style={{ fontSize: "15px" }}>
                  You need to submit a government issued ID document to get your account verified before you can start sending SMS. If you are an individual customer you may upload a copy of a
                  personal ID document. If you are a business customer you should upload a copy of your Business Registration document.
                </p>
              </div>

              <div className="id-verification-wrapper">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="accountType">
                    <Form.Label column sm="12" md="3" lg="2">
                      Account Type
                    </Form.Label>
                    <Col sm="12" sm="12" md="6" lg="3">
                      <Form.Control as="select" onChange={handleChangeAccount} value={accountType}>
                        <option value="individual">Individual</option>
                        <option value="business">Business</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  {accountType === "individual" ? (
                    <Form.Group as={Row} className="mb-3" controlId="accountType">
                      <Form.Label column sm="12" md="3" lg="2">
                        ID Document
                      </Form.Label>
                      <Col sm="12" sm="12" md="6" lg="3">
                        <Form.Control as="select" onChange={handleChangeDoc} value={documentType}>
                          <option value="National Identity Card">National Identity Card</option>
                          <option value="Passport">Passport</option>
                          <option value="Driving License">Driving License</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ) : null}

                  {accountType === "business" ? (
                    <Form.Group as={Row} className="mb-3" controlId="accountType">
                      <Form.Label column sm="12" md="3" lg="2">
                        ID Document
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control as="select" onChange={handleChangeDoc} value={documentType}>
                          <option value="Business Registration">Business Registration</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ) : null}

                  <Form.Group as={Row} className={`mb-3 ${isError && "error-red"}`} controlId="accountType">
                    <Form.Label column sm="12" md="3" lg="2" style={{ paddingTop: "6px" }}>
                      Page 1 or front
                    </Form.Label>
                    <Col sm="12" md="9" lg="5">
                      <Form.Group controlId="formFile1">
                        <Form.Control type="file" onChange={handleFrontPage} ref={page1} disabled={uploadPercentage[0]?.uploaded} accept="application/pdf,image/x-png,image/gif,image/jpeg" />
                        {frontPage ? (
                          <div className="docs-upload-info">
                            <img className="doc-image-preview" src={URL.createObjectURL(frontPage)} />

                            {uploadPercentage[0].percentage > 0 ? (
                              <div className="uploading-docs-progressbar">
                                {uploadPercentage[0].id === 1 && uploadPercentage[0].percentage > 0 && uploadPercentage[0].percentage < 100 ? <ProgressBar now={uploadPercentage[0].percentage} /> : ""}
                              </div>
                            ) : null}
                            {isDelectable(0) ? (
                              <button className="remove-uploads" type="button" onClick={() => reset("page1")}>
                                Delete
                              </button>
                            ) : null}
                            {uploadPercentage[0].error ? <div className="verify-upload-error">This document failed to upload. Please try again.</div> : null}
                          </div>
                        ) : null}
                      </Form.Group>
                      {isError && <span className="doc-error">Please select your document</span>}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="accountType">
                    <Form.Label column sm="12" md="3" lg="2" style={{ paddingTop: "6px" }}>
                      Page 2 or back (Optional)
                    </Form.Label>
                    <Col sm="12" md="9" lg="5">
                      <Form.Group controlId="formFile2">
                        <Form.Control type="file" onChange={handleBackPage} ref={page2} disabled={uploadPercentage[1]?.uploaded} accept="application/pdf,image/x-png,image/gif,image/jpeg" />
                        {backPage ? (
                          <div className="docs-upload-info">
                            <img className="doc-image-preview" src={URL.createObjectURL(backPage)} />

                            {uploadPercentage[1].percentage > 0 ? (
                              <div className="uploading-docs-progressbar">
                                {uploadPercentage[1].id === 2 && uploadPercentage[1].percentage > 0 && uploadPercentage[1].percentage < 100 ? <ProgressBar now={uploadPercentage[1].percentage} /> : ""}
                              </div>
                            ) : null}
                            {isDelectable(1) ? (
                              <button className="remove-uploads" type="button" onClick={() => reset("page2")}>
                                Delete
                              </button>
                            ) : null}
                            {uploadPercentage[1].error ? <div className="verify-upload-error">This document failed to upload. Please try again.</div> : null}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Form.Group>
                </Form>
              </div>

              <div className="action-buttons-center">
                <Col sm="2" md={{ span: 3, offset: 3 }} lg={{ span: 3, offset: 2 }}>
                  <button type="submit" className="btn-submit amake-payment-btn" disabled={isDisable} onClick={!isLoading ? handleSubmit : () => {}} style={{ width: "150px" }}>
                    {isLoading ? (
                      <div className="spinner-border spinner-border-sm text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {isLoading ? "" : "Submit"}
                  </button>
                </Col>
              </div>
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
};

export default IdVerification;
