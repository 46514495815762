import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import qs from "qs";
import classnames from "classnames";
import { Container, Row, Col, Table, Spinner, Form, Dropdown } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  Switch,
  FormControlLabel
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import {
  getContacts,
  updateContact,
  updateContactGroups,
  deleteContact,
  deleteMultiContact,
} from "../../api/contactAPI";
import { getGroups } from "../../api/groupAPI";

import AddNewContactModal from "../modals/AddNewContactModal";
import ManageGroupsModal from "../modals/ManageGroupsModal";
import DeleteModal from "../modals/DeleteModal";
import DeleteContactModal from "../modals/DeleteContactModal";
import AddContactCSV from "../modals/AddContactCSV"

const initail = {
  first_name: "",
  last_name: "",
  number: "",
  first_nameErr: "",
  last_nameErr: "",
  numberErr: "",
};

function ContactsComp() {
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [id, setId] = useState("");
  const [form, setForm] = useState(initail);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showAddContactModal, setAddContactModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [preLoading, setPreLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [multiDelLoading, setMultiDelLoading] = useState(false);

  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteContactModal, setDeleteContactModal] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [ showAddContactCsvModal,setAddContactCsvModal ] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPageRecords, setCurrentPageRecords] = useState([]);


  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    getGroups(
      (data) => {
        // const allGroups = data.map((el) => el.name);
        setGroups(data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    // sendMessage(
    //   (data) => {},
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }, []);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = () => {
    setPageLoading(true);
    getContacts(
      page,
      (data) => {
        setPreLoading(false);
        setContacts(data);
        setPageLoading(false);
        setNoOfPages(Math.ceil(data.length/itemsPerPage));
        const indexOfLastRecord = page * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        const currentPageRecordsTemp = data.slice(indexOfFirstRecord,indexOfLastRecord);
        setCurrentPageRecords(currentPageRecordsTemp);
      },
      (err) => {
        setPreLoading(false);
        setPageLoading(false);
        console.log(err);
      }
    );
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelect = (id) => {
    const index = selectedIds.findIndex((el) => el === id); // const index =  selectedIds.indexOf(id);

    let newChecked = [...selectedIds];

    if (index === -1) {
      newChecked = [...newChecked, id];
      // setSelectedIds([...selectedIds, id]);
    } else {
      newChecked.splice(index, 1);
    }

    setSelectedIds(newChecked);
  };

  const handleSelectAll = (id) => {
    const selectAll = contacts.map((el) => el.id);

    if (selectAll.length === selectedIds.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(selectAll);
    }
  };

  const handleUpdate = (id, number, first_name, last_name, groups) => {
    setId(id);
    setForm({ ...form, number, first_name, last_name });
    setSelectedGroups(groups);
  };

  // validate
  const validate = () => {
    let first_nameErr = "";
    let last_nameErr = "";
    let numberErr = "";

    if (!form.first_name) {
      first_nameErr = "First name is required";
    } else if (form.first_name.length < 2) {
      first_nameErr = "First name is too short";
    } else if (!form.first_name.match(/^\D+$/)) {
      first_nameErr = "First name is Invalid";
    }

    if (!form.number) {
      numberErr = "Number is required";
    }

    if (first_nameErr || last_nameErr || numberErr) {
      setForm({
        ...form,
        first_nameErr,
        last_nameErr,
        numberErr,
      });

      return false;
    }

    return true;
  };

  const CustomSwitch = withStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: 'white',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#EF6C00',
          borderColor: 'white',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid white`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#BDBDBD',
    },
    checked: {},
  })(Switch);

  const saveUpdate = () => {
    if (validate()) {
      setLoadingSave(true);

      const data = qs.stringify({
        first_name: form.first_name,
        last_name: form.last_name,
        number: form.number,
      });

      const selGroups = selectedGroups.map((el) => el.id);
      console.log(selGroups);

      const dataGroup = qs.stringify({
        groups: selGroups.length > 0 ? selGroups.toString() : [],
      });

      updateContact(
        id,
        data,
        (data) => {
          updateContactGroups(
            id,
            dataGroup,
            (data) => {
              setId();
              setForm(initail);
              setSelectedGroups(groups);
              fetchContacts();
              setLoadingSave(false);
            },
            (err) => {
              setLoadingSave(false);
              console.log(err);
            }
          );
        },
        (err) => {
          setLoadingSave(false);
          console.log(err);
        }
      );
    }
  };

  const handleContactDeleteModal = (id) => {
    setDeletingId(id);
    setDeleteContactModal(true);
  };

  const handleContactDelete = () => {
    setMultiDelLoading(true);
    deleteContact(
      deletingId,
      (data) => {
        fetchContacts();
        setDeletingId("");
        setDeleteContactModal(false);
        setLoading(false);
        setMultiDelLoading(false);
      },
      (err) => {
        setMultiDelLoading(false);
        console.log(err);
      }
    );
  };

  const checkSelectability = (id) => {
    const isSelect = selectedIds.find((el) => el === id);

    if (isSelect) {
      return true;
    } else {
      return false;
    }
  };

  const handleMultiDelete = (id) => {
    setMultiDelLoading(true);

    deleteMultiContact(
      selectedIds,
      (data) => {
        fetchContacts();
        setSelectedIds([]);
        setDeleteModal(false);
        // setNoOfPages(data.page_count);
        setMultiDelLoading(false);
      },
      (err) => {
        setMultiDelLoading(false);
        console.log(err);
      }
    );
  };

  const handleCancel = () => {
    setId("");
    setForm(initail);
  };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
    const indexOfLastRecord = value * itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
    const currentPageRecordsTemp = contacts.slice(indexOfFirstRecord,indexOfLastRecord);
    setCurrentPageRecords(currentPageRecordsTemp);
  };

  const handlePageSize = (size) => {
    setItemsPerPage(size);
    setNoOfPages(Math.ceil(contacts.length/size));
    setPage(1);
    const indexOfLastRecord = 1 * size;
    const indexOfFirstRecord = indexOfLastRecord - size;
    const currentPageRecordsTemp = contacts.slice(indexOfFirstRecord,indexOfLastRecord);
    setCurrentPageRecords(currentPageRecordsTemp);
  }

  return (
    <>
      <Container className="main-container">
        <Row className="m-0 cus-content-div">
          <Col className="p-0">
            <div className="main-header-div">
              <div className="header-div">
                <h5 className="sec-heading2">Contacts</h5>
              </div>
              <hr className="live-hr" />
            </div>
            {preLoading ? (
              <div className="loading-container">
                <Spinner animation="border" size="md" />
              </div>
            ) : (
              <>                
                <div
                  style={{ position: "relative", margin: "auto" }}
                >
                  <div className="page-secondory-header" style={{ marginTop: "1rem"}}>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          id="dropdown-basic"
                          size="sm"
                          className="page-drop-down"
                        >
                          {itemsPerPage} Per Page
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handlePageSize(25)}
                          >
                            25 Per Page
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handlePageSize(50)}
                          >
                            50 Per Page
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handlePageSize(75)}
                          >
                            75 Per Page
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handlePageSize(100)}
                          >
                            100 Per Page
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="download-report-div create-btn-div">
                      <button style={{ color: '#2979FF'}} onClick={() => setAddContactModal(true)}>
                        New Contact
                      </button>
                      <button style={{ color: '#2979FF'}} onClick={() => setAddContactCsvModal(true)}>
                        Contact Bulk Upload
                      </button>
                      <button style={{ color: '#2979FF'}} onClick={() => setShowGroupModal(true)}>
                        Manage Groups
                      </button>
                    </div>
                  </div>

                  <div className="contact-delete-btn">
                  {selectedIds.length > 0 && 
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => setDeleteModal(true)}
                        style={{ width: 170 }}
                      >
                        <i className="fas fa-trash-alt"></i> Delete All Selected
                      </button>
                  }
                  </div>

                  <div className="data-details-contact">
                    <span className="page-numbering-contact">
                      {(noOfPages > 0) ? (
                      <span className="page-numbering-contact">
                        Page <b>{page} of {noOfPages}</b>
                      </span>
                      ) : (
                      <span className="page-numbering-contact">
                        Page <b>{0} of {0}</b>
                      </span>
                      )}
                    </span>
                    <span className="page-numbering-contact">
                      <b>{contacts.length}</b> Results
                    </span>
                  </div>

                  <Table
                    className="custom-table without-hover-table"
                    responsive
                    style={{ width: "100%", marginBottom: "45px" }}
                  >
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={
                              contacts.length === selectedIds.length &&
                              contacts.length !== 0
                            }
                            onChange={handleSelectAll}
                          />{" "}
                          All
                        </th>
                        <th>Contact Number</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Groups</th>
                        <th>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageLoading ? (
                        <tr>
                          <td colSpan="6" align="center">
                            <Spinner animation="border" size="sm" />
                          </td>
                        </tr>
                      ) : currentPageRecords.length > 0 ? (
                        currentPageRecords.map((el, i) => (
                          <tr key={i}>
                            <td>
                              <input
                                type="checkbox"
                                checked={checkSelectability(el.id)}
                                onChange={() => handleSelect(el.id)}
                              />
                            </td>
                            <td>
                              {el.id === id ? (
                                <Form>
                                  <Form.Group className="p-0">
                                    <Form.Control
                                      type="text"
                                      className={classnames("form-control", {
                                        "is-invalid": form.numberErr,
                                      })}
                                      name="number"
                                      value={form.number}
                                      onChange={handleChange}
                                      autoComplete="off"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {form.numberErr}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Form>
                              ) : (
                                el.number
                              )}
                            </td>
                            <td>
                              {el.id === id ? (
                                <Form>
                                  <Form.Group className="p-0">
                                    <Form.Control
                                      type="text"
                                      className={classnames("form-control", {
                                        "is-invalid": form.first_nameErr,
                                      })}
                                      name="first_name"
                                      value={form.first_name}
                                      onChange={handleChange}
                                      autoComplete="off"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {form.first_nameErr}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Form>
                              ) : (
                                el.first_name
                              )}
                            </td>
                            <td>
                              {el.id === id ? (
                                <Form>
                                  <Form.Group className="p-0">
                                    <Form.Control
                                      type="text"
                                      className={classnames("form-control", {
                                        "is-invalid": form.last_nameErr,
                                      })}
                                      name="last_name"
                                      value={form.last_name}
                                      onChange={handleChange}
                                      autoComplete="off"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {form.last_nameErr}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Form>
                              ) : (
                                el.last_name
                              )}
                            </td>
                            <td>
                              {el.id === id ? (
                                <Form.Group>
                                  <Typeahead
                                    id="basic-typeahead-multiple"
                                    labelKey="name"
                                    multiple
                                    onChange={setSelectedGroups}
                                    options={groups}
                                    placeholder="Choose several groups..."
                                    selected={selectedGroups}
                                  />
                                </Form.Group>
                              ) : (
                                el.groups.length > 0 &&
                                el.groups.map((el, i) => (
                                  <span key={i}>{el.name} &nbsp;</span>
                                ))
                              )}
                            </td>
                            <td>
                              {el.id === id ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={saveUpdate}
                                    // style={{ marginRight: 10 }}
                                  >
                                    {loadingSave ? (
                                      <div
                                        className="spinner-border spinner-border-sm text-light"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : null}
                                    {loadingSave ? (
                                      ""
                                    ) : (
                                      <i className="fas fa-check"></i>
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={handleCancel}
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() =>
                                      handleUpdate(
                                        el.id,
                                        el.number,
                                        el.first_name,
                                        el.last_name,
                                        el.groups
                                      )
                                    }
                                  >
                                    <i className="fas fa-pencil-alt"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() => handleContactDeleteModal(el.id)}
                                  >
                                    {loading ? (
                                      <div
                                        className="spinner-border spinner-border-sm text-light"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : null}
                                    {loading ? (
                                      ""
                                    ) : (
                                      <i className="far fa-trash-alt"></i>
                                    )}
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" align="center">
                            No records
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="pagination-div">
                  {noOfPages > 1 && (
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handlePagination}
                      className="pagi"
                    />
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <AddNewContactModal
        showAddContactModal={showAddContactModal}
        setAddContactModal={setAddContactModal}
        fetchContacts={fetchContacts}
      />

      <AddContactCSV
       showAddContactCsvModal={showAddContactCsvModal}
       setAddContactCsvModal={setAddContactCsvModal}
       fetchContacts={fetchContacts}
      /> 

      {showGroupModal && (
        <ManageGroupsModal
          showGroupModal={showGroupModal}
          setShowGroupModal={setShowGroupModal}
        />
      )}

      {deleteModal && (
        <DeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          multiDelLoading={multiDelLoading}
          handleMultiDelete={handleMultiDelete}
        />
      )}

      {deleteContactModal && (
        <DeleteContactModal
          deleteModal={deleteContactModal}
          setDeleteModal={setDeleteContactModal}
          multiDelLoading={multiDelLoading}
          handleMultiDelete={handleContactDelete}
        />
      )}
    </>
  );
}

export default withRouter(ContactsComp);
