import React from "react";
import ActivateComp from "../components/register/ActivateComp";
import LoginLayout from "../components/layouts/LoginLayout";

function Activate() {
  return (
    <LoginLayout>
      <ActivateComp />
    </LoginLayout>
  );
}

export default Activate;