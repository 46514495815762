import React, { useState, useEffect, useRef, useContext } from "react";
import { Navbar, Nav, Container, Button, Spinner, Dropdown } from "react-bootstrap";
import Avatar from "react-avatar";
import { Select } from "react-select-tile";
import "../../styles/main.css";
import Logo from "../../images/logo_white.png";
import {
  NavLink,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { getAllProjectsAPI } from "../../api/ProjectAPI";
import { MdAdd } from "react-icons/md";
import CreateProjectModal from "./CreateProjectModal";
import { logoutSession, getUserProfileAPI } from "../../api/authAPI";
import {
  ProfileContext,
  ProjectContext,
  SelectedProjectContext,
} from "../context/ContextProvider";

const containerStyle = {
  background: "transparent",
  border: "none",
};

const inputStyle = {
  background: "transparent",
  color: "#ffffff",
  fontSize: "1.5rem",
  fontWeight: "600",
  paddingRight: ".5rem",
  paddingLeft: "1.3rem",
};

const menuStyle = {
  background: "#1d2125",
  border: "none",
  overflowY: "auto",
  maxHeight: "400px",
  marginTop: "8px",
  gridTemplateColumns: "repeat( 2, 1fr )",
};

const menuItemStyle = {
  borderRadius: "3px",
  textAlign: "center",
  fontWeight: "600",
};

const avatarStyles = {
  cursor: "pointer",
  "&:hover": {
    border: "2px solid rgba(60,64,67,0.08)",
  },
};

const Navigation = () => {
  const [value, setValue] = useState("");
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);
  const [selectedProjectName, setSelectedProjectName] = useState("");

  const { projects, setProjects } = useContext(ProjectContext);
  const { userProfile, setUserProfile } = useContext(ProfileContext);
  const { selectedProject, setSelectedProject } = useContext(
    SelectedProjectContext
  );

  const { id: paramId } = useParams();
  let matchUrl = useRouteMatch(`/settings/${paramId}`);
  let history = useHistory();
  const popupRef = useRef();

  useEffect(() => {
    if (value === "" && selectedProject !== "") {
      setValue(selectedProject);
    }
  }, []);

  useEffect(() => {
   const found = projects.find(element => element.id == selectedProject);
   if (found) {
     setSelectedProjectName(found.name)
   }
  }, [selectedProject, projects]);

  const gotoPage = (page) => {
    let bodyDiv = document.getElementsByClassName("main-body");
    bodyDiv[0].style.backgroundColor = "#d9effb";
    history.replace("/"+page)
  }

//   useEffect(() => {
//     if (!projects.length) {
//       getAllProjectsAPI(
//         (data) => {
//           let tempData = data;
//           tempData.forEach((project) => {
//             project["label"] = project.name;
//             project["value"] = project.id;
//           });
//           setProjects(tempData);
//           setIsProjectsLoading(false);
//           if (value === "") {
//             if (
//               localStorage.getItem("selected_project") !== null &&
//               localStorage.getItem("selected_project") !== ""
//             ) {
//               const project = localStorage.getItem("selected_project");
//               setValue(project);
//               setSelectedProject(project);
//               if (matchUrl !== null) {
//                 history.push({
//                   pathname: "/settings/" + project,
//                 });
//               }
//             } else {
//               const firstProject = data[0].id;
//               setValue(firstProject);
//               setSelectedProject(firstProject);
//               localStorage.setItem("selected_project", firstProject);
//               if (matchUrl !== null) {
//                 history.push({
//                   pathname: "/settings/" + firstProject,
//                 });
//               }
//             }
//           } else if (value !== selectedProject) {
//             setValue(selectedProject);
//             localStorage.setItem("selected_project", selectedProject);
//             if (matchUrl !== null) {
//               history.push({
//                 pathname: "/settings/" + selectedProject,
//               });
//             }
//           }
//         },
//         (error) => {
//           console.log(error);
//           setIsProjectsLoading(false);
//         }
//       );
//     }
//   }, [projects.length]);

//   useEffect(() => {
//     if (!userProfile.email)
//       getUserProfileAPI(
//         (data) => {
//           setUserProfile(data);
//           localStorage.setItem("currency", data.region.currency_symbol);
//           localStorage.setItem("display_balance", data.display_balance);
//         },
//         (error) => {}
//       );
//   }, [userProfile.email]);

//   const handleGetAllProjects = () => {
//     setProjects([]);
//   };

 

  const handleSelectProject = (value) => {
    if (value == "add-new") {
      setShowCreateProjectModal(true);
    } else {
      setValue(value);
      setSelectedProject(value);
      localStorage.setItem("selected_project", value);
      if (matchUrl !== null) {
        history.push({
          pathname: "/settings/" + value,
        });
      } else {
        window.location.reload();
      }
    }
  };

  const handleCloseCreateProjectModal = () => {
    setShowCreateProjectModal(false);
  };

  const handleUserAccountPopup = () => {
    setShowAccountInfo(true);
  };

  const handleClickOutside = (e) => {
    if (!popupRef.current?.contains(e.target)) {
      setShowAccountInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div className="nav-bar-main-div">
      <Navbar bg="dark" variant="dark" className="main-nav">
        <div className="api-nav-main-div" id="abc" style={{display:"flex", width:"100%", justifyContent: "space-between", marginLeft: "5%", marginRight: "5%", marginTop: "10px"}}>
          <div className="nav-icon-project-select-wrapper api-navigation-icon-wrapper">
            <div className="nav-logo">
              <img src={Logo} alt="sms160 logo" onClick={() => gotoPage('home')} style={{cursor:'pointer'}}/>
            </div>
            {/* <div className="nav-icon-project-select-box">
              {projects.length ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline"
                    id="dropdown-basic"
                    size="sm"
                  >
                    {selectedProjectName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {(projects) && projects.map((el, i) => (
                      <Dropdown.Item
                        as="button"
                        onClick={() => handleSelectProject(el.id)}
                        className={el.id == selectedProject ? "selected-item" : "un-selected-item"}
                      >
                        {el.name}
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleSelectProject("add-new")}
                      className={"un-selected-item"}
                    >
                      Create New Project
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div className="no-projects">
                  {isProjectsLoading ? (
                    <div className="projects-loading-spinner">
                      <Spinner
                        animation="border"
                        variant="secondary"
                        size="sm"
                      />
                    </div>
                  ) : (
                    "No Projects Available"
                  )}
                </div>
              )}
            </div> */}
          </div>
          <div className="main-nav-links-wrapper api-main-nav-links-wrapper">
            <div className="main-nav-links">
              <Nav className="mr-auto">
                <NavLink
                  to="/home"
                  className="nav-link"
                  activeClassName="active-nav-link"
                >
                  Dashboard
                </NavLink>
                <NavLink
                  to="/api"
                  className="nav-link"
                  activeClassName="active-nav-link"
                >
                  Api
                </NavLink>
                {/* <NavLink
                  to="/about"
                  className="nav-link"
                  activeClassName="active-nav-link"
                >
                  Support
                </NavLink> */}
              </Nav>
            </div>
            <div className="main-nav-avatar" ref={popupRef}>
              <Avatar
                name={userProfile.first_name}
                round={true}
                size="40"
                textSizeRatio={1.6}
                style={avatarStyles}
                onClick={handleUserAccountPopup}
              />
              {showAccountInfo ? (
                <div className="user-account-info-popup">
                  <div className="user-info-wrapper">
                    <h6>{userProfile.first_name ? `${userProfile.first_name} ${userProfile.last_name}` : "profile"}</h6>
                    <p>{userProfile.first_name ? userProfile.email : "Not Logged In"}</p>
                    <hr />
                    <button
                      type="button"
                      className="nav-account-btn"
                      onClick={()=> gotoPage('profile')}
                    >
                      Manage Account
                    </button>
                    <button
                      type="button"
                      className="nav-account-btn"
                      onClick={()=> gotoPage('billing')}
                    >
                      Billing
                    </button>
                    {userProfile.first_name ?
                        <button
                            type="button"
                            className="nav-account-btn"
                            onClick={() => logoutSession()}
                            >
                            Logout
                        </button> :
                        <button
                            type="button"
                            className="nav-account-btn"
                            onClick={() => gotoPage('')}
                        >
                            Login
                      </button>
                    }
                  </div>
                </div>
              ) :
              null
              }
            </div>
          </div>
        </div>
      </Navbar>
      <hr className='api-hr'/>
      {/* <CreateProjectModal
        showCreateProject={showCreateProjectModal}
        handleCloseCreateProjectModal={handleCloseCreateProjectModal}
        handleGetAllProjects={handleGetAllProjects}
      /> */}
    </div>
  );
};

export default Navigation;

