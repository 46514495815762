import React, { useState, useEffect, useRef, useContext } from "react";
import {Helmet} from "react-helmet";
import { ProjectContext, SelectedProjectContext } from "../context/ContextProvider";
import Logo from "../../images/circle_logo.ico";

function PageTitle(props) {
  const { projects, setProjects } = useContext(ProjectContext);
  const { selectedProject, setSelectedProject } = useContext(SelectedProjectContext);
  const [projectName, setProjectName] = useState("");

  useEffect(() => {
    const found = projects.find(element => element.id == selectedProject);
    if (found) {
        setProjectName(found.name)
    }
    }, [selectedProject, projects]);

return (
  <Helmet>
    <title>{props.page} - {projectName} - SMS160</title>
    <link
          rel="icon"
          type="image/png"
          href={Logo}
          sizes="16x16"
        />
  </Helmet>
  );
}

export default PageTitle;