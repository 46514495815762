import axios from "axios";
import { getCSRFToken, getTeamId } from "../auth/auth";

export const getAllProjectsAPI = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/projects`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const addANewProjectAPI = (projectName, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/projects`, projectName, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getProjectDetailsAPI = (projectId, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/projects/${projectId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const updateProjectAPI = (projectId, projectName, onSuccess, onError) => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/v1/web/projects/${projectId}`, projectName, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const updateProjectApiKeysAPI = (projectId, keyType, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/projects/${projectId}/update_keys`, keyType, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getProjectStatsAPI = (projectId, mode, from, to, filterBy, onSuccess, onError) => {
  let url = "";
  if (filterBy == "Custom") {
    url = `${process.env.REACT_APP_API_URL}/v1/web/stats/project_overview?project_id=${projectId}&mode=${mode}&from=${from}&to=${to}`;
  } else {
    let range = "";
    if (filterBy == "Today") {
      range = "1d";
    } else if (filterBy == "Last 7 days") {
      range = "7d";
    } else if (filterBy == "Last 4 weeks") {
      range = "4w";
    } else if (filterBy == "Last 3 months") {
      range = "3m";
    } else if (filterBy == "Last 12 months") {
      range = "12m";
    }
    url = `${process.env.REACT_APP_API_URL}/v1/web/stats/project_overview?project_id=${projectId}&mode=${mode}&range=${range}`
  }
  axios
    .get(url, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getGraphStatsAPI = (projectId, mode, from, to, filterBy, onSuccess, onError) => {
  let url = "";
  if (filterBy == "Custom") {
    url = `${process.env.REACT_APP_API_URL}/v1/web/stats/project_graphs?project_id=${projectId}&mode=${mode}&from=${from}&to=${to}`;
  } else {
    let range = "";
    if (filterBy == "Today") {
      range = "1d";
    } else if (filterBy == "Last 7 days") {
      range = "7d";
    } else if (filterBy == "Last 4 weeks") {
      range = "4w";
    } else if (filterBy == "Last 3 months") {
      range = "3m";
    } else if (filterBy == "Last 12 months") {
      range = "12m";
    }
    url = `${process.env.REACT_APP_API_URL}/v1/web/stats/project_graphs?project_id=${projectId}&mode=${mode}&range=${range}`
  }
  axios
    .get(url, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const requestSenderIdAPI = (proId, data, onSuccess, onError) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/projects/${proId}/sender_id_requests`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/JSON",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getPendingSenderIdRequestsAPI = (proId, onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/projects/${proId}/sender_id_requests?id=${proId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const UpdateProjectDailyLimit = (proId,data, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/projects/${proId}/daily_limit`,
    data,
    {
      withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    })
}

export const updateBlockedCodes = (proId, data, onSuccess, onError) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/projects/${proId}/blocked_codes`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};