import React, { useState, useEffect, useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import qs from "qs";
import classnames from "classnames";
import { Container, Row, Col, Form, ButtonGroup, Button, Badge } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/campaigns.css";
import { getGroups } from "../../api/groupAPI";
import { addCampaign } from "../../api/campaignAPI";
import { SelectedProjectContext } from "../context/ContextProvider";
import { getProjectDetailsAPI } from "../../api/ProjectAPI";
import { toast } from "react-toastify";
import {
  Switch,
  FormControlLabel
} from "@material-ui/core";
import ConfirmCampaignModal from "../modals/ConfirmCampaignModal";
import { withStyles } from '@material-ui/core/styles';

const initial = {
  name: "",
  nameErr: "",
  content: "",
  contentErr: "",
  selectedGroups: [],
  include_groupsErr: ""
};

function NewCampaignComp() {
  const [state, setState] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [isImmediate, setIsImmediate] = useState(true);
  const [mode, setMode] = useState("LIVE");
  const { selectedProject, setSelectedProject } = useContext(SelectedProjectContext);
  const [project, setProject] = useState([]);
  const [senders, setSenders] = useState([]);
  const [senderId, setSenderId] = useState('');
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    handleGetProjectDetails();
  }, [selectedProject]);

  const fetchGroups = () => {
    getGroups(
      (data) => {
        setGroups(data);
      },
      (err) => {
        console.log(err);
      }
    );
  };
 
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  const handleGetProjectDetails = () => {
    getProjectDetailsAPI(
      selectedProject,
      (data) => {
        setProject(data);
        setSenders(data.senders);
        if ((data.senders).length > 0) {
          setSenderId(data.senders[0].id);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  
  // validate
  const validate = () => {
    let nameErr = "";
    let contentErr = "";
    let include_groupsErr = "";

    if (!state.name) {
      nameErr = "Campaign name is required";
    }
    if (!state.content) {
      contentErr = "Content is required";
    }
    if (selectedGroups.length == 0) {
      include_groupsErr = "Groups are required";
    }

    if (nameErr || contentErr || include_groupsErr) {
      setState({
        ...state,
        nameErr,
        contentErr,
        include_groupsErr
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const selGroups = selectedGroups.map((el) => el.id);
      const data = qs.stringify({
        name: state.name,
        content: state.content,
        include_groups: selGroups.toString(),
        send_at: isImmediate ? null : startDate.toISOString().split('.')[0],
        mode: mode,
        project_id: selectedProject,
        from: senderId,
        is_preview: true
      });

      addCampaign(
        data,
        (data) => {
          console.log(data);
          setCount(data.message_count);
          setLoading(false);
          setConfirmDialogShow(!confirmDialogShow);
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again");
        }
      );
    }
  };

  const handleDropdownChange = (e) => {
    if (e.target.value == "immediate") {
      setIsImmediate(true);
    } else {
      setIsImmediate(false);
    }
  }

  const handleSenderChange = (e) => {
    setSenderId(e.target.value);
  }

  const changeSelectErrText = (e) => {
    setSelectedGroups(e);
    setState({
      ...state,
      include_groupsErr : ""
    });
  }

  const handleMode = (type) => {
    type = (type === "TEST") ? "LIVE" : "TEST";
    setMode(type);
  };

  const handleConfirmation = (result) => {
    if (result) {
      setLoading(true);
      setConfirmDialogShow(!confirmDialogShow);
      const selGroups = selectedGroups.map((el) => el.id);
      const data = qs.stringify({
        name: state.name,
        content: state.content,
        include_groups: selGroups.toString(),
        send_at: isImmediate ? null : startDate.toISOString().split('.')[0],
        mode: mode,
        project_id: selectedProject,
        from: senderId,
        is_preview: false
      });

      addCampaign(
        data,
        (data) => {
          toast.success("New Campaign has been created successfully");
          setTimeout(() => {
            window.location.href='/campaigns'
          }, 3000);
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again");
        }
      );
    } else {
      setConfirmDialogShow(!confirmDialogShow);
    }
  };

  const CustomSwitch = withStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: 'grey',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#EF6C00',
          borderColor: '#EF6C00',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid grey`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'white',
    },
    checked: {},
  })(Switch);

  return (
    <>
      <Container className="cus-container" style={{marginLeft: '2%'}}>
        <Row className="m-0 cus-content-div">
              <Form style={{paddingLeft: '0', paddingRight: '0'}}>
                <div className="main-header-div">
                  <div className="header-div">
                    <h5 className="sec-heading2">Payment Method</h5>
                  </div>
                </div>
                <h6 className="settings-title">Card Details</h6>
                <Form>
                  <Form.Group as={Row} style={{ marginTop: "18px" }}>
                    <Form.Label column sm="2">
                      Customer Name
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <Form>
                  <Form.Group as={Row} style={{ marginTop: "18px" }}>
                    <Form.Label column sm="2">
                      Card Number
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <Form>
                  <Form.Group as={Row} style={{ marginTop: "18px" }}>
                    <Form.Label column sm="2">
                      Card Expiry
                    </Form.Label>
                    <Col sm="2">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                    <Form.Label column sm="1">
                      Card CVC
                    </Form.Label>
                    <Col sm="2">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>             
                <h6 className="settings-title">Billing Address</h6>
                <Form>
                  <Form.Group as={Row} style={{ marginTop: "18px" }}>
                    <Form.Label column sm="2">
                      Address Line 1
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <Form>
                  <Form.Group as={Row} style={{ marginTop: "18px" }}>
                    <Form.Label column sm="2">
                      Address Line 2
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <Form>
                  <Form.Group as={Row} style={{ marginTop: "18px" }}>
                    <Form.Label column sm="2">
                      City
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <Form>
                  <Form.Group as={Row} style={{ marginTop: "18px" }}>
                    <Form.Label column sm="2">
                      Postcode
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <Row className="">
                    <Form.Label column sm="2">
                    </Form.Label>
                    <Col sm="1">
                      <button
                        type="submit"
                        className="btn-submit mt-3 save-btn cancel-btn"
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                          >
                              <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {loading ? "" : "Cancel"}
                      </button>
                    </Col>
                    <Col sm="2">
                      <button
                        type="submit"
                        className="btn-submit mt-3 save-btn"
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                          >
                              <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {loading ? "" : "Save"}
                      </button>
                    </Col>
                </Row>
              </Form>
        </Row>
      </Container>
      <ConfirmCampaignModal
        title="Create Campaign"
        message="Are you sure you want to create this campaign ?"
        confirmBtnName="Create"
        confirmModalType="Update"
        callback={handleConfirmation}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
        content={state.content}
        count={count}
      />
    </>
  );
}

export default withRouter(NewCampaignComp);
