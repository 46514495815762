import axios from "axios";

import { getCSRFToken, getTeamId } from "../auth/auth";

export const addGroup = (data, onSuccess, onError) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/groups
    `,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getGroups = (onSuccess, onError) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/web/groups
    `,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const updateGroup = (groupId, data, onSuccess, onError) => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/v1/web/groups/${groupId}`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const deleteGroup = (groupId, onSuccess, onError) => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/v1/web/groups/${groupId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};
