import React, { useState } from "react";
import BillingMenu from "./BillingMenu";
import BillingOverviewComp from "./BillingOverviewComp";
import BillingUsageComp from "./BillingUsageComp";
import BillingHistoryComp from "./BillingHistoryComp";
import PaymentMethodComp from "./PaymentMethodComp";
import "../../styles/billing.css";

function Billing(props) {
  const [selectedComponent, setSelectedComponent] = useState('overview');

  const changeViewComponent = (name) => {
    setSelectedComponent(name);
  }

  const renderSwitch = (param) => {
    switch(param) {
      case 'overview':
        return <BillingOverviewComp />;
      case 'usage':
        return <BillingUsageComp />;
      case 'history':
        return <BillingHistoryComp />;
      case 'method':
        return <PaymentMethodComp />;
      default:
        return 'foo';
    }
  }

  return (
    <div className="billing-main-div">
      <BillingMenu data={changeViewComponent} active={selectedComponent}/>
      {renderSwitch(selectedComponent)}
    </div>
      
  );
}

export default Billing;