import React, { useState, useEffect } from 'react';
import ProjectSettings from './ProjectSettings';
import UsageLimitComp from './UsageLimitComp';
import GeoPermissionComp from './GeoPermissionComp';
import SettingsMenu from './SettingsMenu';
import { getProjectDetailsAPI } from '../../api/ProjectAPI';
import { getRegions } from '../../api/genericAPI';
import { getTimeZone } from '../../api/genericAPI';
import { useParams } from "react-router-dom";

const SettingsComp = () => {
  const [selectedComponent, setSelectedComponent] = useState("overview");
  const [projectData, setProjectData] = useState(null);
  const [regions, setRegions] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const { id: paramId } = useParams();

  useEffect(() => {
    getProjectDetails();
  }, [paramId])

  useEffect(() => {
    getRegionData()
    getTimeZones();
  }, [])

  const getProjectDetails = () => {
    getProjectDetailsAPI(
      paramId,
      (data) => {
        setProjectData(data)
      },
      (error) => {
        console.log(error);
      }
    )
  }

  const getRegionData = () => {
    getRegions(
      (data) => {
        setRegions(data.data)
      },
      (error) => {
        console.log(error);
      }
    )
  }

  const getTimeZones = () => {
    getTimeZone(
      (res) => {
        setTimeZones(res.data);
      },
      (err) => {
        console.log(err);
      }
    )
  }


    const changeViewComponent = (name) => {
        setSelectedComponent(name);
      }
    
      const renderSwitch = (param) => {
        switch(param) {
          case 'overview':
            return <ProjectSettings timeZones={timeZones} />;
          case 'usageLimits':
            return <UsageLimitComp projectId={projectData.id} />;
          case 'geoPermissions':
            return <GeoPermissionComp projectId={projectData.id} blockedCodes={projectData.blocked_codes} regionList={regions} getProjectDetails={getProjectDetails} />;
          default:
            return 'foo';
        }
      }

  return (
    <div className="billing-main-div">
        <SettingsMenu data={changeViewComponent} active={selectedComponent}/>
        {renderSwitch(selectedComponent)}
    </div>
  )
}

export default SettingsComp;