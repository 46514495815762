import React from "react";

import LoginComp from "../components/login/LoginComp";

import "../styles/login-register.css";

function Login() {
  return <LoginComp />;
}

export default Login;
