import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Row, Col, InputGroup } from "react-bootstrap";
import { addANewProjectAPI } from "../../api/ProjectAPI";
import qs from "qs";
import classnames from "classnames";
import { toast } from "react-toastify";
import { SelectedProjectContext } from "../context/ContextProvider";
import { MdInfoOutline } from "react-icons/md";
import Close from "../../images/close.png";

const CreateProjectModal = (props) => {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { setSelectedProject } = useContext(SelectedProjectContext);

  useEffect(() => {
    setName("");
    setErrors("");
  }, [props.showCreateProject]);

  const validate = () => {
    let status = {
      nameError: false,
      nameErrorMsg: "",
    };

    if (name === "") {
      status.nameError = true;
      status.nameErrorMsg = "Please enter the Project Name";
    }

    if (status.nameError) {
      setErrors(status);
      return false;
    } else {
      setErrors(status);
      return true;
    }
  };

  const handleOnChange = (event) => {
    if (errors.nameError) {
      setErrors({});
    }
    setName(event.target.value);
  };

  const hanldeCreateNewProject = () => {
    if (validate()) {
      setIsLoading(true);
      const pojectName = qs.stringify({
        name: name,
      });

      addANewProjectAPI(
        pojectName,
        (data) => {
          setSelectedProject(data.id);
          props.handleGetAllProjects();
          toast.success("New project has been created successfully");
          setTimeout(() => {
            setIsLoading(false);
            props.handleCloseCreateProjectModal();
          }, 100);
        },
        (error) => {
          console.log(error);
          toast.error("Something went wrong. Please try again");
        }
      );
    }
  };

  return (
    <Modal show={props.showCreateProject} size="md" backdrop="astatic" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="modal-header">
         <Modal.Title className="modal-title">{props.newProjectInfo ? props.newProjectInfo.title : "New Project"}</Modal.Title>
          <img
            src={Close}
            alt="close"
            onClick={props.handleCloseCreateProjectModal}
            style={{ cursor: "pointer"}}
            className="modal-close-btn"
          />
      </Modal.Header>
      <Modal.Body style={{ background: "white", paddingTop: "0" }}>
        <hr className="live-hr modal-hr" />
        {props.newProjectInfo?.message ? (
          <div className="new-project-message">
            <MdInfoOutline />
            <p>{props.newProjectInfo.message}</p>
          </div>
        ) : null}

        <div className="dialog">
          <Form style={{ paddingLeft: "0", paddingRight: "0" }}>
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Project Name
                </Form.Label>
                <Col sm="7">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      className={classnames("custom-form-control", {
                        "is-invalid": errors.nameError,
                      })}
                      name="name"
                      value={name}
                      autoComplete="off"
                      onChange={(event) => handleOnChange(event)}
                    />
                    <Form.Control.Feedback type="invalid">{errors.nameErrorMsg}</Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Form>
          </Form>
          <Row className="">
            <Form.Label column sm="4">
            </Form.Label>
            <Col sm="7" style={{display:"flex", justifyContent: "right"}}>
              <button type="submit" className="btn btn-outline-secondary btn-sm mt-3" onClick={props.handleCloseCreateProjectModal} style={{ width: "150px", color: "#333333" }}>
                Cancel
              </button>
              <button type="submit" className="btn-submit mt-3" onClick={!isLoading ? hanldeCreateNewProject : () => {}} style={{width: "73%", marginLeft: "5%"}}>
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {isLoading ? "" : props.newProjectInfo ? "Create Project" : "Create"}
              </button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProjectModal;
