import React, { useState, createContext } from "react";

export const ProjectContext = createContext();
export const ProfileContext = createContext();
export const SelectedProjectContext = createContext();

export const ContextProvider = (props) => {
  const [projects, setProjects] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  return (
    <ProjectContext.Provider value={{ projects, setProjects }}>
      <ProfileContext.Provider value={{ userProfile, setUserProfile }}>
        <SelectedProjectContext.Provider value={{ selectedProject, setSelectedProject }}>
          {props.children}
        </SelectedProjectContext.Provider>
      </ProfileContext.Provider>
    </ProjectContext.Provider>
  );
};
