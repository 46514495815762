import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import classnames from "classnames";
import qs from "qs";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { MdCheckCircle, MdHelp } from "react-icons/md";

// import { getCSRFToken, storeAuth } from "../../../auth/auth";
import { refreshCSRF, loginSession, resetPassword } from "../../api/authAPI";

import Logo from "../../images/logo.png";

function ActivateComp(props) {
  const [status, setStatus] = useState(qs.parse(props.location.search, { ignoreQueryPrefix: true }).status);

  return (
    <Container>
      <Row className="m-0 cus-content-div">
        <Col className="p-0">
              <Col>
                <h5 className="sec-heading" style={{marginBottom:'6px'}}>User Activation</h5>
              </Col>
              <hr style={{ marginLeft:'auto', marginRight:'auto', marginTop:'0'}}/>
              <div className="" style={{textAlign:'center'}}>
                {status=='true' ?
                  <Col xl="7" style={{margin:'auto', paddingTop:'10%'}}>
                    <MdCheckCircle style={{color:'#3bb54a', height: '100%', width: '15%'}}/>
                    <p>Your account has been successfully activated</p>
                    <div className="">
                        <button onClick={() => window.location.href='/'} style={{color: '#2979FF', background:'transparent', border:'none', textDecoration:'underline'}}>
                          Continue to Login
                        </button>
                      </div>
                  </Col>
                  :
                  <Col xl="7" style={{margin:'auto', paddingTop:'10%'}}>
                    <MdHelp style={{color:'#f54e42', height: '100%', width: '15%'}}/>
                    <p>Your account activation has been failed</p>
                    <div className="">
                        <button onClick={() => window.location.href='/sign-up'} style={{color: '#2979FF', background:'transparent', border:'none', textDecoration:'underline'}}>
                          Continue to Signup
                        </button>
                      </div>
                  </Col>
                }
              </div>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(ActivateComp);
