import React from "react";
import DashboardLayout from "../components/layouts/DashboardLayout";
import BillingComp from "../components/billing/BillingComp";
import PageTitle from "../components/common/PageTitle";

function Billing() {
  return (
    <DashboardLayout>
      <PageTitle page="Billing" />
      <BillingComp />
    </DashboardLayout>
  );
}

export default Billing;