import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Modal, Container, Row, Col, Form, ProgressBar, Spinner, InputGroup, FormControl } from "react-bootstrap";
import classnames from "classnames";
import { uploadFileAPI } from "../../api/genericAPI";
import { getTeamAPI } from "../../api/teamAPI";
import { makeBankTransferAPI, makePaymentAPI } from "../../api/paymentAPI";
import qs from "qs";
import Close from "../../images/close.png";

const AddPaymentModal = (props) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [bankSlipName, setBankSlipName] = useState("No File Uploaded");
  const [uploadedSlip, setUploadedSlip] = useState("");
  const [amountErr, setAmountErr] = useState("");
  const [bankErr, setBankErr] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("sri-lankan");
  const [amount, setAmount] = useState(0);
  const [isBankSlip, setIsBankSlip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency")
      ? localStorage.getItem("currency")
      : ""
  );
  const [region, setRegion] = useState(
    localStorage.getItem("region")
      ? localStorage.getItem("region")
      : ""
  );

  const [merchantId, setMerchantId] = useState("");
  const [returnUrl, setReturnUrl] = useState("");
  const [cancelUrl, setCancelUrl] = useState("");
  const [notifyUrl, setNotifyUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [hash, setHash] = useState("");
  const [orderId, setOrderId] = useState("");
  const [items, setItems] = useState("");
  const [currencyPay, setCurrencyPay] = useState("");
  const [dueAmount, setDueAmount] = useState("");

  useEffect(() => {
    loadScripts()
    if (!currency || !region) {
      getTeamAPI(
        (data) => {
          setCurrency(data[0].region.currency_symbol);
          localStorage.setItem("currency", data[0].region.currency_symbol);
          localStorage.setItem("display_balance", data[0].display_balance);
          localStorage.setItem("region", data[0].region.id);
          setRegion(data[0].region.id);
        },
        (error) => {}
      );
    }

    if (region !== "LK") {
      setPaymentMethod("non-sri-lankan");
    }
  }, []);

  const onChangeRadio = (val) => {
    setPaymentMethod(val);
    if (val == "bank-transfer") {
      document.getElementById("bank-tranfer-div").style.display = "block";
    } else {
      document.getElementById("bank-tranfer-div").style.display = "none";
    }
  };

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleRemoveBankSlip = (e) => {
    setBankSlipName("Upload Bank Slip");
    setUploadedSlip("");
    setIsBankSlip(false);
  }

  const resetModal = (e) => {
    setBankSlipName("No File Uploaded");
    setUploadedSlip("");
    setPaymentMethod("sri-lankan");
    setAmount(0);
    setIsBankSlip(false);
    setAmountErr("");
    setBankErr("");
    props.onHide(true);
  }

  const validate = () => {
    let amountErr = "";
    let bankErr = "";
    if (!parseFloat(amount)) {
      amountErr = "Amount is invalid";
    }

    if ((paymentMethod == "bank-transfer") && (!isBankSlip)) {
      bankErr = "Bank slip is required"
    }

    if (amountErr || bankErr) {
      setAmountErr(amountErr)
      setBankErr(bankErr);
      return false;
    }
    return true;
  };

  const handleMakePayment = (e) => {

    if (validate()) {
      setLoading(true);
      e.preventDefault();
      if (paymentMethod == "bank-transfer") {
        const data = qs.stringify({
          amount: parseFloat(amount),
          file: uploadedSlip
        });
        makeBankTransferAPI(
          data,
          (data) => {
            setLoading(false);
            toast.success("Payment has been recorded");
            resetModal();
          },
          (err) => {
            setLoading(false);
            toast.error("Something went wrong. Please try again");
            resetModal();
          }
        );
      } else {
        const data = qs.stringify({
          amount: parseFloat(amount),
          ipg: (paymentMethod == "sri-lankan") ? "PAYHERE" : "STRIPE"
        });

        makePaymentAPI(
          data,
          (data) => {
            setLoading(false);
            loadScripts();
            if (data.ipg === "STRIPE") {
              console.log("stripe")
              let paymentObj = {
                sessionId : data.stripe_params.session_id,
                ipg: data.ipg
              }
              redirectStripe(paymentObj);
            }
            if (data.ipg === "PAYHERE") {
              setMerchantId(data.payhere_params.merchant_id);
              setReturnUrl(data.payhere_params.return_url);
              setCancelUrl(data.payhere_params.cancel_url);
              setNotifyUrl(data.payhere_params.notify_url);
              setFirstName(data.payhere_params.first_name);
              setHash(data.payhere_params.hash);
              setOrderId(data.payhere_params.order_id);
              setItems(data.payhere_params.items);
              setCurrencyPay(data.payhere_params.currency);
              setDueAmount(data.payhere_params.amount);
              document.getElementById("pay-by-pay-here").click();
            }
          },
          (err) => {
            console.log(err)
            setLoading(false);
            toast.error("Something went wrong. Please try again");
            resetModal();
          }
        )
      }
    }
  }

  const loadScripts = () => {
    // stripe
    const stripeScript = document.createElement("script");
    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;
    document.body.appendChild(stripeScript);
  };

  const redirectStripe = (object) => {
    console.log(object);
    const stripeId = process.env.REACT_APP_STRIPE_KEY;
    var stripe = window.Stripe(stripeId);
    stripe
      .redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: object.sessionId,
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
      });
  };

  const handleSubmitBankTransfer = (e) => {
    let imageFile = new FormData();
    imageFile.append("file", e.target.files[0]);
    uploadFileAPI(
      imageFile,
      (uploadProgress) => {
        setUploadPercentage(uploadProgress);
      },
      (data) => {
        setUploadPercentage(100);
        setLoading(false);
        setBankSlipName(e.target.files[0].name);
        setUploadedSlip(data.data.id);
        setIsBankSlip(true);
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again");
      }
    );
  };

  return (
    <Modal
      {...props}
      size="md"
      backdrop="astatic"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // className="confirm-action-dialog"
      onHide={() => resetModal()}
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">Make a Payment</Modal.Title>
        <img
          src={Close}
          alt="close"
          onClick={resetModal}
          style={{ cursor: "pointer"}}
          className="modal-close-btn"
        />
      </Modal.Header>
      <Modal.Body style={{background: "white", paddingTop: "0"}}>
        <hr className="live-hr modal-hr" />
        <div className="dialog">
          <Form style={{paddingLeft: '0', paddingRight: '0'}}>
            <Form style={{ marginBottom:"35px"}}>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Amount
                </Form.Label>
                <Col sm="7">
                  <InputGroup>
                    <InputGroup.Text style={{background:"#e0e0e0", border:"none", marginTop:"1px", marginBottom:"1px"}}>
                      {currency}
                    </InputGroup.Text>
                    <Form.Control
                      type="string"
                      className={classnames("custom-form-control", {
                        "is-invalid": amountErr,
                      })}
                      name="name"
                      value={amount}
                      autoComplete="off"
                      onChange={handleChangeAmount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {amountErr}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Form>
            <Form style={{ marginBottom:"30px"}}>
              <Form.Group as={Row}>
                <Form.Label column sm="4" style={{paddingTop: "0px"}}>
                  Payment Method
                </Form.Label>
                <Col sm="7">
                  {region == "LK" ? (
                    <div>
                      <Form.Check
                        label="Sri Lankan Credit/Debit Card"
                        name="group1"
                        type="radio"
                        defaultChecked={true}
                        onChange={event => onChangeRadio("sri-lankan")}
                      />
                      <Form.Check
                        label="Non Sri Lankan Credit/Debit Card"
                        name="group1"
                        type="radio"
                        onChange={event => onChangeRadio("non-sri-lankan")}
                      />
                    </div>
                  ) : (
                    <div>
                      <Form.Check
                        label="Credit / Debit Card"
                        name="group1"
                        type="radio"
                        defaultChecked={true}
                        onChange={event => onChangeRadio("non-sri-lankan")}
                      />
                    </div>
                  )}
                  <Form.Check
                    label="Bank Transfer"
                    name="group1"
                    type="radio"
                    onChange={event => onChangeRadio("bank-transfer")}
                  />
                </Col>
              </Form.Group>
            </Form>
            <Form id="bank-tranfer-div" style={{ marginBottom:"35px", display:"none"}}>
              <Row className="mb-4">
                <Col sm={2}></Col>
                <Col sm={10}>
                  <span style={{textAlign:"justify", fontSize:"0.9rem"}}>Please deposit the amount you wish to reload to the bank account below and upload a copy of the deposit slip.</span>
                  <Row style={{fontSize:"0.9rem", marginTop:"1rem",color:"#333333"}}>
                    <Col sm={4}>Account number</Col>
                    <Col sm={8}>: 017510008154</Col>
                  </Row>
                  <Row style={{fontSize:"0.9rem", color:"#333333"}}>
                    <Col sm={4}>Account name</Col>
                    <Col sm={8}>: Serendib Systems Lanka Pvt Ltd</Col>
                  </Row>
                  <Row style={{fontSize:"0.9rem", color:"#333333"}}>
                    <Col sm={4}>Bank</Col>
                    <Col sm={8}>: Sampath Bank PLC (Code: 7278)</Col>
                  </Row>
                  <Row style={{fontSize:"0.9rem", color:"#333333"}}>
                    <Col sm={4}>Branch</Col>
                    <Col sm={8}>: Colombo Super (Code: 175)</Col>
                  </Row>
                </Col>
              </Row>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Attach bank deposit slip or transfer receipt
                </Form.Label>
                <Col sm="8">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      className={classnames("custom-form-control", {
                        "is-invalid": bankErr,
                      })}
                      name="name"
                      value={bankSlipName}
                      autoComplete="off"
                      readOnly="readOnly"
                    />
                    <InputGroup.Text style={{background:"#e0e0e0", border:"none", marginTop:"1px", marginBottom:"1px"}}>
                      <i className="fas fa-folder file-icon"></i>
                    </InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {bankErr}
                    </Form.Control.Feedback>
                  </InputGroup>
                  {uploadPercentage > 0 && (
                    <div className="uploading-image-progressbar">
                      { uploadPercentage > 0 && uploadPercentage < 100
                          ? <ProgressBar now={uploadPercentage} />
                          : ""}
                    </div>
                  )}
                  <div style={{display:"flex"}}>
                    {!isBankSlip && (
                        <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex"}}>
                          <input type="file" name="file" id="icon-button-file-reg" style={{ display: 'none' }} onChange={handleSubmitBankTransfer}/>
                          <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-reg">Upload</label>
                        </div>
                      )}
                      {isBankSlip && (
                        <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex"}}>
                          <input type="button" name="file-remove" id="icon-button-file-remove-reg" style={{ display: 'none'}} onClick={handleRemoveBankSlip} />
                          <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-remove-reg">Remove</label>
                        </div>
                      )}
                  </div>
                </Col>
              </Form.Group>
            </Form>
          </Form>
          <Row className="">
            <Form.Label column sm="4">
            </Form.Label>
            <Col sm="7" style={{display:"flex", justifyContent: "right"}}>
              <button
                type="submit"
                className="btn btn-outline-secondary btn-sm mt-3"
                onClick={resetModal}
                style={{width:"150px"}}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-submit mt-3"
                onClick={handleMakePayment}
                style={{width:"150px", marginLeft: '5%'}}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {loading ? "" : "Continue"}
              </button>
            </Col>
          </Row>
        </div>
        <form
          id="ext-merchant-frm"
          action={
            process.env.REACT_APP_PAYHERE_URL
          }
          method="post"
          acceptCharset="UTF-8"
          encType="application/x-www-form-urlencoded"
          >
          <input
            type="hidden"
            name="merchant_id"
            value={merchantId}
          />
          <input type="hidden" name="return_url" value={returnUrl} />
          <input type="hidden" name="cancel_url" value={cancelUrl} />
          <input type="hidden" name="notify_url" value={notifyUrl} />
          <input type="hidden" name="first_name" value={firstName} />
          <input type="hidden" name="last_name" value="" />
          <input type="hidden" name="email" value="" />
          <input type="hidden" name="phone" value="" />
          <input type="hidden" name="address" value="" />
          <input type="hidden" name="city" value="" />
          <input type="hidden" name="country" value="" />
          <input type="hidden" name="hash" value={hash} />
          <input type="hidden" name="order_id" value={orderId} />
          <input type="hidden" name="items" value={items} />
          <input type="hidden" name="currency" value={currencyPay} />
          <input type="hidden" name="amount" value={dueAmount} />
          <button id="pay-by-pay-here" hidden>
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddPaymentModal;
