import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Table, Breadcrumb } from "react-bootstrap";

// import { useTable, useSortBy, usePagination } from "react-table";
// import { useExportData } from "react-table-plugins";
// import Papa from "papaparse";

import jsPDF from "jspdf";
import "jspdf-autotable";

function ConversationComp(props) {
  const data = React.useMemo(
    () => [
      {
        DATE: "2020-10-10 10:30:00",
        API: "Outgoing",
        FROM: 7788,
        TO: "+94777123254",
        MESSAGE_ID: "sms_asxbajkshbxjk",
        LENGTH: 148,
        STATUS: "Sent",
      },
      {
        DATE: "2020-10-10 10:30:00",
        API: "Outgoing",
        FROM: 5000,
        TO: "+94777123254",
        MESSAGE_ID: "sms_asxbajkshbxjk",
        LENGTH: 148,
        STATUS: "Fail",
      },
      {
        DATE: "2020-10-10 10:30:00",
        API: "Outgoing",
        FROM: 7788,
        TO: "+94777123254",
        MESSAGE_ID: "sms_asxbajkshbxjk",
        LENGTH: 148,
        STATUS: "Delivered",
      },
      {
        DATE: "2020-10-10 10:30:00",
        API: "Outgoing",
        FROM: 7788,
        TO: "+94777123254",
        MESSAGE_ID: "sms_asxbajkshbxjk",
        LENGTH: 148,
        STATUS: "Sent",
      },
      {
        DATE: "2020-10-10 10:30:00",
        API: "Outgoing",
        FROM: 7788,
        TO: "+94777123254",
        MESSAGE_ID: "sms_asxbajkshbxjk",
        LENGTH: 148,
        STATUS: "Sent",
      },
      {
        DATE: "2020-10-10 10:30:00",
        API: "Outgoing",
        FROM: 7788,
        TO: "+94777123254",
        MESSAGE_ID: "sms_asxbajkshbxjk",
        LENGTH: 148,
        STATUS: "Sent",
      },
      {
        DATE: "2020-10-10 10:30:00",
        API: "Outgoing",
        FROM: 7788,
        TO: "+94777123254",
        MESSAGE_ID: "sms_asxbajkshbxjk",
        LENGTH: 148,
        STATUS: "Sent",
      },
      {
        DATE: "2020-10-10 10:30:00",
        API: "Outgoing",
        FROM: 7788,
        TO: "+94777123254",
        MESSAGE_ID: "sms_asxbajkshbxjk",
        LENGTH: 148,
        STATUS: "Sent",
      },
    ],
    []
  );

  return (
    <Container>
      <Link to="/logs" className="dash-back-btn">
        <i className="fas fa-angle-left"></i> Back
      </Link>

      <Breadcrumb>
        <Breadcrumb.Item href="/home/logs">Logs</Breadcrumb.Item>
        <Breadcrumb.Item active>Conversation</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="m-0 cus-content-div">
        <Col className="p-0">
          <h5 className="sec-heading">Conversation Details</h5>

          <div className="sms-detail-sec">
            <Row className="m-0">
              <Col xs="6" md="2" className="first-col">
                Subscriber Phone :
              </Col>
              <Col xs="6" md="4">
                +94777123456
              </Col>
            </Row>
            <Row className="m-0">
              <Col xs="6" md="2" className="first-col">
                Started At :
              </Col>
              <Col xs="6" md="4">
                Tue, 27 Oct 2020 at 12:30:45 AEST
              </Col>
            </Row>
            <Row className="m-0">
              <Col xs="6" md="2" className="first-col">
                Message Count :
              </Col>
              <Col xs="6" md="4">
                4
              </Col>
            </Row>
          </div>

          <h5 className="sec-heading mt-4">Conversation</h5>

          <div style={{ position: "relative" }}>
            <Table className="custom-table" responsive>
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Platform</th>
                  <th>Timestamps (AEST)</th>
                  <th>Elapsed</th>
                </tr>
              </thead>
              <tbody>
                {data.map((el, i) => (
                  <tr key={i}>
                    <td>{el.DATE}</td>
                    <td>{el.API}</td>
                    <td>{el.FROM}</td>
                    <td>{el.TO}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(ConversationComp);
