import React from "react";

import DashboardLayout from "../components/layouts/DashboardLayout";
import LogsDetailsComp from "../components/logs/LogsDetailsComp";

function LogsDetails() {
  return (
    <DashboardLayout>
      <LogsDetailsComp />
    </DashboardLayout>
  );
}

export default LogsDetails;
