import React from "react";

import DashboardLayout from "../components/layouts/DashboardLayout";
import ContactsComp from "../components/contacts/ContactsComp";
import PageTitle from "../components/common/PageTitle";

function Contacts() {
  return (
    <DashboardLayout>
      <PageTitle page="Contacts"/>
      <ContactsComp />
    </DashboardLayout>
  );
}

export default Contacts;
