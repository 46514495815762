import React from "react";
import { Modal, Row, Form, Col } from "react-bootstrap";

import Close from "../../images/close.png";

function DeleteContactModal({
  deleteModal,
  setDeleteModal,
  handleMultiDelete,
  multiDelLoading,
}) {
  return (
    <Modal show={deleteModal} onHide={() => handleMultiDelete(false)} centered>
      <Modal.Header>
        <Modal.Title className="modal-title">Delete Contact</Modal.Title>
        <img
          src={Close}
          alt="close"
          onClick={() => setDeleteModal(false)}
          style={{ cursor: "pointer" }}
          className="modal-close-btn"
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <hr className="live-hr modal-hr" />
        <p style={{fontSize:'14px'}}>Do you want to delete this contact?</p>
        <Row className="">
          <Form.Label column sm="4">
          </Form.Label>
          <Col sm="8" style={{display:"flex", justifyContent: "right"}}>
            <button type="submit" className="btn btn-outline-secondary btn-sm mt-3" onClick={() => setDeleteModal(false)} style={{ width: "80px", color: "#333333" }}>
              Cancel
            </button>
            <button
              type="submit"
              className="btn-delete mt-3"
              onClick={handleMultiDelete}
              style={{width: "33%", marginLeft: "5%"}}
            >
              {multiDelLoading ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : "Delete"}
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteContactModal;
