import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Table, Spinner, Dropdown } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getAccountUsageForMonth } from "../../api/billingAPI";
import "../../styles/billing.css";

function BillingUsageComp() {
  const [pageLoading, setPageLoading] = useState(false);
  const [outGoingQuantity, setOutGoingQuantity] = useState(0);
  const [outGoingCost, setOutGoingCost] = useState(0.00);
  const [campaignOutGoingQuantity, setCampaignOutGoingQuantity] = useState(0);
  const [campaignOutGoingCost, setCampaignOutGoingCost] = useState(0.00);
  const [incommingQuantity, setIncommingQuantity] = useState(0);
  const [incommingCost, setIncommingCost] = useState(0.00);
  const [phoneNumbersQuantity, setPhoneNumbersQuantity] = useState(0);
  const [phoneNumbersCost, setPhoneNumbersCost] = useState(0.00);
  const [verificationsQuantity, setVerificationsQuantity] = useState(0);
  const [verificationsCost, setVerificationsCost] = useState(0.00);
  const [subTotal, setSubTotal] = useState(0.00);
  const [taxes, setTaxes] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency")
      ? localStorage.getItem("currency")
      : ""
  );
  const [mode, setMode] = useState('LIVE');
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth()+1);
  const [projects, setProjects] = useState({});
  const [campaignProjects, setCampaignProjects] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  var d = new Date();
  let monthsArr = [];
  // d.setMonth(d.getMonth() - 0);
  // monthsArr.push(d.toLocaleString('default', { month: 'long' })+" "+ d.getUTCFullYear());

  for (let i = 0; i < 12; i++) {
    let val = i - (i-1);
    d.setMonth(d.getMonth() - val);
    let myDate = (d.toLocaleString('default', { month: 'long' })+" "+ d.getUTCFullYear());
    monthsArr.push(myDate);
  }

  useEffect(() => {
    fetchData();
  }, [mode, month, year]);

  const fetchData = () => {
    setDataLoading(true);
    getAccountUsageForMonth(
      mode,
      month,
      year,
      (data) => {
        let outGoingObject = (data.api_usage).filter(data => data.api_direction == "OUTGOING" && data.api_type == "MESSAGING")[0];
        setProjects(outGoingObject.projects);
        let quantitySMS = 0;
        let costSMS = 0;
        if (data.region.tax_included) {
          setSubTotal(data.total-data.tax);
          setTaxes(data.tax);
          setTotal(data.total);
        } else {
          setSubTotal(data.total);
          setTaxes(data.total*data.region.tax_percentage);
          setTotal(data.total*data.region.tax_percentage + data.total);
        }
        if(outGoingObject.projects) {
          (outGoingObject.projects).map((el, i) => (
            quantitySMS = (quantitySMS + el.qty)
          ));
          (outGoingObject.projects).map((el, i) => (
            costSMS = (costSMS + el.cost)
          ));
        }
        setOutGoingQuantity(quantitySMS);
        setOutGoingCost(costSMS);

        let campaignObject = (data.api_usage).filter(data => data.api_direction == "OUTGOING" && data.api_type == "CAMPAIGN")[0];
        setCampaignProjects(campaignObject.projects);
        let campaignQuantitySMS = 0;
        let campaignCostSMS = 0;
        if(campaignObject.projects) {
          (campaignObject.projects).map((el, i) => (
            campaignQuantitySMS = (campaignQuantitySMS + el.qty)
          ));
          (campaignObject.projects).map((el, i) => (
            campaignCostSMS = (campaignCostSMS + el.cost)
          ));
        }
        setCampaignOutGoingQuantity(campaignQuantitySMS);
        setCampaignOutGoingCost(campaignCostSMS);
        setDataLoading(false);
      },
      (err) => {
        console.log(err);
        setDataLoading(false);
      }
    );
  };
  
  useEffect(() => {
    let myDate = (new Date().toLocaleString('default', { month: 'long' })+" "+ new Date().getUTCFullYear());
    monthsArr.push(myDate);
    setSelectedMonth(myDate);
  }, []);
  
  const handleFilter = (month) => {
    var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const chars = month.split(' ');
    setYear(chars[1]);
    setMonth(months.indexOf(chars[0])+1);
    setSelectedMonth(month);
  };

  return (
    <>
      <Container className="cus-container main-container billing-cus-container" style={{marginLeft: '2%'}}>
        <Row className="m-0 cus-content-div">
          <Col className="p-0">
            {pageLoading ? (
              <div className="loading-container">
                <Spinner animation="border" size="md" />
              </div>
            ) : (
              <>
                <div className="main-header-div">
                  <div className="header-div">
                    <h5 className="sec-heading2">Usage</h5>
                  </div>
                  <hr className="live-hr" />
                </div>
                <div style={{ margin: "auto" }}>
                  <div className="page-secondary-header">
                    <div className="page-header-drop-downs">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          id="dropdown-basic"
                          size="sm"
                        >
                          {selectedMonth}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        
                         {
                          monthsArr.map(function(item, i) {                            
                            return <Dropdown.Item
                              as="button"
                              onClick={() => handleFilter(item)}
                            >
                              {item}
                            </Dropdown.Item>
                          })
                         }
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div
                  style={{ position: "relative", margin: "auto" }}
                >
                  <Table
                    className="custom-table"
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    <thead>
                      <tr>
                        <th align="center">Product</th>
                        <th align="center">Quantity</th>
                        <th align="center">Cost</th>
                      </tr>
                    </thead>
                    {dataLoading ? (
                      <div className="loading-container">
                        <Spinner animation="border" size="md" />
                      </div>
                    ) : (
                      <tbody>
                        <tr>
                          <td>Outgoing SMS</td>
                          <td>{outGoingQuantity}</td>
                          <td align="right">{currency} {outGoingCost.toFixed(2)}</td>
                        </tr>
                        {projects.length > 0 ? (
                          projects.map((el, i) => (
                            <tr key={i} style={{borderColor: 'transparent'}}>
                              <td style={{paddingLeft: '40px'}}>{el.project.name}</td>
                              <td>{el.qty}</td>
                              <td align="right">{currency} {el.cost.toFixed(2)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" align="center">
                              No records
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>Campaign SMS</td>
                          <td>{campaignOutGoingQuantity}</td>
                          <td align="right">{currency} {campaignOutGoingCost.toFixed(2)}</td>
                        </tr>
                        {campaignProjects.length > 0 ? (
                          campaignProjects.map((el, i) => (
                            <tr key={i} style={{borderColor: 'transparent'}}>
                              <td style={{paddingLeft: '40px'}}>{el.project.name}</td>
                              <td>{el.qty}</td>
                              <td align="right">{currency} {el.cost.toFixed(2)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" align="center">
                              No records
                            </td>
                          </tr>
                        )}
                        {/* <tr>
                          <td>Incomming SMS</td>
                          <td>{incommingQuantity}</td>
                          <td align="right">{currency} {incommingCost.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>Phone Numbers</td>
                          <td>{phoneNumbersQuantity}</td>
                          <td align="right">{currency} {phoneNumbersCost.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>Verifications</td>
                          <td>{verificationsQuantity}</td>
                          <td align="right">{currency} {verificationsCost.toFixed(2)}</td>
                        </tr> */}
                      </tbody>
                    )}
                  </Table>
                  <Table
                    className="custom-table"
                    style={{ width: "100%", marginTop: "-15px" }}
                  >
                    <thead style={{ background: "transparent" }}>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>                     
                      <tr className="customized-row">
                        <td>Subtotal</td>
                        <td></td>
                        <td align="right">{currency} {subTotal.toFixed(2)}</td>
                      </tr>
                      <tr className="customized-row">
                        <td>Government Taxes and Levies</td>
                        <td></td>
                        <td align="right">{currency} {taxes.toFixed(2)}</td>
                      </tr>
                      <tr className="customized-row">
                        <td><b>Total</b></td>
                        <td></td>
                        <td align="right"><b>{currency} {total.toFixed(2)}</b></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withRouter(BillingUsageComp);