import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { getTeamsAPI } from "../api/teamAPI";

export const getCSRFToken = () => {
  if (typeof window !== "undefined") {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      if (localStorage.getItem("csrf_t")) {
        return localStorage.getItem("csrf_t");
      } else {
        return false;
      }
    } else {
      if (process.env.REACT_APP_API_URL === "https://api-dev.sms160.io") {
        if (document.cookie.includes("csrf_dev")) {
          return document.cookie.replace(
            /(?:(?:^|.*;\s*)csrf_dev\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          );
        } else {
          return false;
        }
      } else {
        if (document.cookie.includes("csrf")) {
          return document.cookie.replace(
            /(?:(?:^|.*;\s*)csrf\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          );
        } else {
          return false;
        }
      }
    }
  } else {
    return false;
  }
};

export const getTeamId = () => {
  if (localStorage.getItem("team")) {
    return JSON.parse(localStorage.getItem("team")).id;
  } else {
    return false;
  }
}

// export const storeAuth = () => {
//   const ciphertext = CryptoAES.encrypt(
//     "sms user is logged in",
//     "sms key for auth"
//   );

//   let now = new Date();
//   let time = now.getTime();
//   let expireTime = time + 60 * 60 * 24 * 1000;
//   now.setTime(expireTime);
//   document.cookie =
//     "auth_t=" + ciphertext + ";expires=" + now.toUTCString() + ";path=/";
// };

// export const isAuthenticated = () => {
//   const name = "auth_t";
//   const cookie =
//     document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

//   if (typeof cookie === "string") {
//     const ciphertext = cookie;
//     const bytes = CryptoAES.decrypt(ciphertext.toString(), "sms key for auth");
//     const plaintext = bytes.toString(CryptoENC);

//     if (plaintext === "sms user is logged in") {
//       return true;
//     } else {
//       return false;
//     }
//   } else return false;
// };
