import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  MdOpenInNew,
  MdVisibility,
  MdCheck,
  MdClose,
  MdVisibilityOff,
  MdContentPaste,
  MdCheckCircle,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import "../../styles/settings.css";
import {
  getProjectDetailsAPI,
  updateProjectAPI,
  updateProjectApiKeysAPI,
} from "../../api/ProjectAPI";
import QueryString from "qs";
import { toast } from "react-toastify";
import ConfirmDialog from "../common/ConfirmDialog";
import { ProjectContext } from "../context/ContextProvider";
import PropTypes from 'prop-types';

const ProjectSettings = ({ timeZones }) => {
  const [project, setProject] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [passwordShown, setPasswordShown] = useState("");
  const [isChangeName, setIsChangeName] = useState(false);
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [projectKeyType, setProjectKeyType] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timeZone, setTimeZone] = useState("");

  ProjectSettings.propTypes = {
    timeZones: PropTypes.array.isRequired
  }

  const { setProjects } = useContext(ProjectContext);

  let history = useHistory();
  const { id: paramId } = useParams();

  useEffect(() => {
    handleGetProjectDetails();
  }, [paramId]);

  useEffect(() => {
    if (project.name === projectName) {
      setIsChangeName(false);
    } else {
      setIsChangeName(true);
    }
  }, [projectName]);

  const handleGetProjectDetails = () => {
    setIsLoading(true);
    getProjectDetailsAPI(
      paramId,
      (data) => {
        setProject(data);
        setProjectName(data.name);
        setProjectKeyType("");
        setTimeZone(data.timezone);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  };

  const togglePasswordVisiblity = (key) => {
    if (passwordShown === "") {
      setPasswordShown(key);
    } else {
      setPasswordShown("");
    }
  };

  const handleChangeProjectName = (event) => {
    setProjectName(event.target.value);
  };

  const handleChangeProjectNameConfirm = () => {
    let name = QueryString.stringify({
      name: projectName,
    });

    updateProjectAPI(
      project.id,
      name,
      (data) => {
        setProjects([]);
        handleGetProjectDetails();
        setIsChangeName(false);
        toast.success("Project name has been updated successfully");
      },
      (error) => {
        toast.error("Something went wrong. Please try again");
      }
    );
  };

  const handleCancelChangeProjectName = () => {
    setIsChangeName(false);
    setProjectName(project.name);
  };

  const handleConfirmation = (yesOrNo) => {
    if (yesOrNo === "yes") {
      let projectType = QueryString.stringify({
        mode: projectKeyType,
      });
      updateProjectApiKeysAPI(
        project.id,
        projectType,
        (data) => {
          handleGetProjectDetails();
          toast.success("Api key has been updated successfully");
        },
        (error) => {
          toast.error("Something went wrong. Please try again");
        }
      );
    }
    setConfirmDialogShow(!confirmDialogShow);
  };

  const handleRevokeApiKey = (keyType) => {
    setProjectKeyType(keyType);
    handleConfirmation("Yes or No");
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopySuccess(text);
  };

  const handleTimeZoneChange = () => {
    let timezone = QueryString.stringify({
      timezone: timeZone
    })

    updateProjectAPI(
      project.id,
      timezone,
      (data) => {
        handleGetProjectDetails();
        toast.success("Project name has been updated successfully");
      },
      (error) => {
        toast.error("Something went wrong. Please try again");
      }
    );
  }

  useEffect(() => {
    if (copySuccess !== "") {
      setTimeout(() => {
        setCopySuccess("");
      }, 6000);
    }
  }, [copySuccess]);

  return (
    <>
      <Container>
        {/* <Link to="#" onClick={() => history.goBack()} className="dash-back-btn">
          <i className="fas fa-angle-left"></i> Back
        </Link> */}

        <Row className="m-0 cus-content-div">
          <Col className="p-0">
            <Col>
              <h5 className="sec-heading2">Overview</h5>
              <hr className="live-hr" />
            </Col>
            {isLoading ? (
              <div className="loading-container">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <>               
                <div className="form-wrapper">
                  <Col xl="7">
                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Project Name
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            className="custom-form-control"
                            name={project.name}
                            value={projectName}
                            onChange={handleChangeProjectName}
                          />
                          {isChangeName ? (
                            <div className="input-action-buttons">
                              <Button
                                variant="primary"
                                onClick={() => handleChangeProjectNameConfirm()}
                              >
                                <MdCheck />
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => handleCancelChangeProjectName()}
                              >
                                <MdClose />
                              </Button>
                            </div>
                          ) : null}
                        </Col>
                      </Form.Group>
                    </Form>

                    <div className="settings-credentials-info">
                      <h6 style={{ fontSize: '1rem', color: "#333333", marginBottom: "12px", marginTop: "35px", fontWeight: "500"}} >Credentials</h6>
                      <h6>
                        Keep your API Keys safe and secure. You can always
                        revoke them and create new keys if they are compromised.
                      </h6>
                      <a href="/api" target="_blank" rel="noopener noreferrer">
                        See the API Reference to learn more about API
                        capabiities
                        <MdOpenInNew />
                      </a>
                    </div>

                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Live API Key
                        </Form.Label>
                        <Col sm="9" className="password-visibility">
                          <Form.Control
                            readOnly
                            type={
                              passwordShown === project.api_key_live
                                ? "text"
                                : "password"
                            }
                            className="custom-form-control"
                            name={project.api_key_live}
                            value={project.api_key_live}
                          />
                          <div
                            className="show-hide-password-button"
                            onClick={() =>
                              togglePasswordVisiblity(project.api_key_live)
                            }
                          >
                            {passwordShown === project.api_key_live ? (
                              <MdVisibilityOff />
                            ) : (
                              <MdVisibility />
                            )}
                          </div>
                          <div className="api-key-copy-button">
                            <Button
                              variant="dark"
                              onClick={() =>
                                copyToClipboard(project.api_key_live)
                              }
                            >
                              {copySuccess === project.api_key_live ? (
                                <>
                                  <MdCheckCircle className="api-copied" />{" "}
                                  Copied
                                </>
                              ) : (
                                <>
                                  <MdContentPaste /> Click to Copy Live API Key
                                </>
                              )}
                            </Button>
                          </div>
                          <Form.Text className="text-muted">
                            <div
                              className="input-action-btn"
                              onClick={() => handleRevokeApiKey("LIVE")}
                            >
                              Revoke this API Key
                            </div>
                          </Form.Text>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Test API Key
                        </Form.Label>
                        <Col sm="9" className="password-visibility">
                          <Form.Control
                            readOnly
                            type={
                              passwordShown === project.api_key_test
                                ? "text"
                                : "password"
                            }
                            className="custom-form-control"
                            name={project.api_key_test}
                            value={project.api_key_test}
                          />
                          <div
                            className="show-hide-password-button"
                            onClick={() =>
                              togglePasswordVisiblity(project.api_key_test)
                            }
                          >
                            {passwordShown === project.api_key_test ? (
                              <MdVisibilityOff />
                            ) : (
                              <MdVisibility />
                            )}
                          </div>
                          <div className="api-key-copy-button">
                            <Button
                              variant="dark"
                              onClick={() =>
                                copyToClipboard(project.api_key_test)
                              }
                            >
                              {copySuccess === project.api_key_test ? (
                                <>
                                  <MdCheckCircle className="api-copied" />{" "}
                                  Copied
                                </>
                              ) : (
                                <>
                                  <MdContentPaste /> Click to Copy Test API Key
                                </>
                              )}
                            </Button>
                          </div>
                          <Form.Text className="text-muted">
                            <div
                              className="input-action-btn"
                              onClick={() => handleRevokeApiKey("TEST")}
                            >
                              Revoke this API Key
                            </div>
                          </Form.Text>
                        </Col>
                      </Form.Group>
                    </Form>

                    <div className="settings-credentials-info">
                      <h6 style={{ fontSize: '1rem', color: "#333333", marginBottom: "12px", marginTop: "35px", fontWeight: "500"}} >Sender Ids</h6>
                    </div>
                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Sender IDs
                        </Form.Label>
                        <Col sm="9">
                          {project &&
                            project.senders &&
                            project.senders.map((sender) => (
                              <Form.Control
                                readOnly
                                key={sender.created_at}
                                type="text"
                                className="custom-form-control"
                                value={sender.id}
                              />
                            ))}
                          <div className="input-action-btn" onClick={()=> history.replace("/sender-id")}>
                            Request a Sender ID
                          </div>
                        </Col>
                      </Form.Group>
                    </Form>

                    <div className="settings-credentials-info">
                      <h6 style={{ fontSize: '1rem', color: "#333333", marginBottom: "12px", marginTop: "35px", fontWeight: "500"}} >Region</h6>
                    </div>
                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Timezone
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control 
                              as="select"
                              size="sm"
                              value={timeZone}
                              onChange={(e) => setTimeZone(e.target.value)}
                              bsPrefix="input"
                              className="custom-form-control-dropdown"
                            >
                              {timeZones.map((item, index) => (
                                <option key={index} value={item.id}>{item.id}</option>
                              ))}
                            </Form.Control>
                            {project.timezone !== timeZone &&
                              <div className="input-action-buttons">
                                <Button
                                  variant="primary"
                                  onClick={() => handleTimeZoneChange()}
                                >
                                  <MdCheck />
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={() => setTimeZone(project.timezone)}
                                >
                                  <MdClose />
                                </Button>
                              </div>
                            }
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <ConfirmDialog
        title="Update Project API Keys"
        message="Are you sure you want to update project api keys?"
        confirmBtnName="Update"
        confirmModalType="Update"
        callback={handleConfirmation}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
      />
    </>
  );
};

export default ProjectSettings;
