import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import {useHistory} from "react-router-dom";
import qs from "qs";
import { Container, Row, Col, Table, Spinner, Dropdown, Badge } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getCampaignsForProject } from "../../api/campaignAPI";
import "../../styles/campaigns.css";
import { SelectedProjectContext } from "../context/ContextProvider";
import Pagination from "@material-ui/lab/Pagination";
import {
  Switch,
  FormControlLabel
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import VerifyAccountModal from "../modals/VerifyAccountModal";
import { ProfileContext } from "../context/ContextProvider";

function CampaignsComp({location}) {
  const history = useHistory()
  const [campaigns, setCampaigns] = useState([]);
  const [preLoading, setPreLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const { selectedProject, setSelectedProject } = useContext(SelectedProjectContext);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [campaignsCount, setCampaignsCount] = useState(0);
  const [mode, setMode] = useState(
    localStorage.getItem("mode")
      ? localStorage.getItem("mode")
      : "LIVE"
  );
  const [verifyModal, setVerifyModal] = useState(false);
  const { userProfile, setUserProfile } = useContext(ProfileContext);
  const [isIdVerified, setIsIdVerified] = useState(true);

  useEffect(() => {
    if (userProfile && userProfile.length !== 0) {
      setIsIdVerified(userProfile.id_verified);
    }
  });

  useEffect(() => {
    if(selectedProject) {
      fetchCampaigns();
    }
  }, [selectedProject, mode]);

  useEffect(()=>{
      if(location && location.state && location.state.page && location.state.itemsPerPage && location.state.noOfPages) {
      setTimeout(function () {
        setPage(location.state.page);
        setNoOfPages(location.state.noOfPages);
        setItemsPerPage(location.state.itemsPerPage);
      }, 500);
    }
  },[location])

  const fetchCampaigns = () => {
    setPageLoading(true);
    getCampaignsForProject(
      qs.stringify({
        project_id: selectedProject,
        mode: mode
      }),
      (data) => {
        setPreLoading(false);
        setCampaigns(data);
        setPageLoading(false);
        setNoOfPages(Math.ceil(data.length/itemsPerPage));
        setCampaignsCount(data.length);
      },
      (err) => {
        setPreLoading(false);
        setPageLoading(false);
        console.log(err);
      }
    );
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  const handlePageSize = (size) => {
    setItemsPerPage(size);
    setNoOfPages(Math.ceil(campaigns.length/size));
    setPage(1);
  }

  const handleMode = (type) => {
    if (isIdVerified) {
      type = (type === "TEST") ? "LIVE" : "TEST";
      setMode(type);
      localStorage.setItem("mode", type);
    } else {
      setVerifyModal(true);
    }
  };

  const handleCloseVerifyModal = () => {
    setVerifyModal(false);
  };

  const CustomSwitch = withStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: 'white',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#EF6C00',
          borderColor: 'white',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid white`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#BDBDBD',
    },
    checked: {},
  })(Switch);

  return (
    <>
      <Container className="main-container">
        <Row className="m-0 cus-content-div">
          <Col className="p-0">
            <div className="main-header-div">
              <div className="header-div">
                <h5 className="sec-heading2">Campaigns</h5>
                <FormControlLabel
                  label="Test Mode"
                  labelPlacement="start"
                  className={(mode === "TEST") ? "switch-label-test" : "switch-label-live"}
                  control={
                    <CustomSwitch
                      checked= {(mode === "TEST")}
                      onChange={() => handleMode(mode)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                />
              </div>
              {(mode == 'TEST') ? (
                <div>
                  <hr className="test-hr"/>
                  <Badge bg="warning" text="dark" className="test-label">
                  TEST MODE
                  </Badge>
                </div>
              ) : (<hr className="live-hr" />)}
            </div>
            {preLoading ? (
              <div className="loading-container">
                <Spinner animation="border" size="md" />
              </div>
            ) : (
              <>                
                <div
                  style={{ position: "relative", margin: "auto" }}
                >
                  <div className="page-secondory-header" style={{ marginTop: "12px"}}>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          id="dropdown-basic"
                          size="sm"
                          className="page-drop-down"
                        >
                          {itemsPerPage} Per Page
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handlePageSize(25)}
                          >
                            25 Per Page
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handlePageSize(50)}
                          >
                            50 Per Page
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handlePageSize(75)}
                          >
                            75 Per Page
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handlePageSize(100)}
                          >
                            100 Per Page
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="download-report-div create-btn-div">
                      <button onClick={() => history.replace('/send-sms')} style={{color: '#2979FF'}}>
                        Send SMS
                      </button>
                      <button onClick={() => history.replace('/new-campaign')} style={{color: '#2979FF'}}>
                        New Campaign
                      </button>
                    </div>
                  </div>
                  <div className="data-details">
                    {(noOfPages > 0) ? (
                      <p className="page-numbering">
                        Page <b>{page} of {noOfPages}</b>
                      </p>
                    ) : (
                      <p className="page-numbering">
                        Page <b>{0} of {0}</b>
                      </p>
                    )}
                    <p className="page-numbering">
                      <b>{campaignsCount}</b> Results
                    </p>
                  </div>
                  <Table
                    className="custom-table"
                    responsive
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th align="center">Campaign ID</th>
                        <th align="center">Name</th>
                        <th align="center">Send Time</th>
                        <th align="center">From</th>
                        <th align="center">No. of Groups</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageLoading ? (
                        <tr>
                          <td colSpan="6" align="center">
                            <Spinner animation="border" size="sm" />
                          </td>
                        </tr>
                      ) : campaigns.length > 0 ? (
                        page==1 ? (
                          campaigns.slice(0, (page*itemsPerPage)+itemsPerPage-itemsPerPage).map((el, i) => (
                            <tr
                              key={i}
                              onClick={() => history.replace(`/campaign/${el.id}`, {page, itemsPerPage, noOfPages})}
                            >
                              <td>{i}</td>
                              <td>{el.name}</td>
                              <td>{new Date(el.send_at).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" })}</td>
                              <td>{el.from}</td>
                              <td>{el.include_groups_detail.length }</td>
                            </tr>
                          ))
                        ) : (
                          campaigns.slice((page*itemsPerPage)-itemsPerPage, (page*itemsPerPage)+itemsPerPage-itemsPerPage).map((el, i) => (
                            <tr key={i} onClick={() => history.replace(`/campaign/${el.id}`, {page, itemsPerPage, noOfPages})}>
                              <td>{i}</td>
                              <td>{el.name}</td>
                              <td>{new Date(el.send_at).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" })}</td>
                              <td>{el.from}</td>
                              <td>{el.include_groups_detail.length }</td>
                            </tr>
                          ))
                        )
                      ) : (
                        <tr>
                          <td colSpan="6" align="center">
                            No records
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="pagination-div">
                {noOfPages > 1 && (
                  <Pagination
                    count={noOfPages}
                    page={page}
                    onChange={handlePagination}
                    className="pagi"
                  />
                )}
              </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <VerifyAccountModal
        showModal={verifyModal}
        closeModal={handleCloseVerifyModal}
      />
    </>
  );
}

export default withRouter(CampaignsComp);
