import React, { useState } from "react";
import { Container } from "react-bootstrap";

import Navbar from "../common/Navigation";

function SettingsLayout(props) {
  return (
    <Container fluid className="p-0">
      <Navbar renderComponent={props.renderComponent} />
      <Container fluid className="px-0 py-5" style={{height: "85vh", overflowY: "scroll"}}>
        {props.children}
      </Container>
    </Container>
  );
}

export default SettingsLayout;
