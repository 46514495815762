import React from "react";
import { Container } from "react-bootstrap";

import LoginNavbar from "../common/LoginNavigation";

function LoginLayout(props) {
  return (
    <Container fluid className="p-0">
      <LoginNavbar />
      <Container fluid className="px-0 py-5">
        {props.children}
      </Container>
    </Container>
  );
}

export default LoginLayout;
