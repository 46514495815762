import React from "react";
import "../../styles/billing.css";

function BillingMenu(props) {
  console.log(props.active)
  return (
    <>
      <div className="billing-menu-div">
        <div className="billing-menu-wrapper">
          <h6 style={{marginLeft: "10px"}}>{`Billing`}</h6>
          <hr />
          <button
            type="button"
            className= {props.active === 'overview' ? "selected-nav-biling-btn" : 'nav-account-btn'}
            onClick={() =>
              props.data('overview')
            }
          >
            Overview
          </button>
          <button
            type="button"
            className= {props.active === 'usage' ? "selected-nav-biling-btn" : 'nav-account-btn'}
            onClick={() =>
              props.data('usage')
            }
          >
            Usage
          </button>
          <button
            type="button"
            className= {props.active === 'history' ? "selected-nav-biling-btn" : 'nav-account-btn'}
            onClick={() =>
              props.data('history')
            }
          >
            Payment History
          </button>
          {/* <button
            type="button"
            className= {props.active === 'method' ? "selected-nav-biling-btn" : 'nav-account-btn'}
            onClick={() =>
              props.data('method')
            }
          >
            Payment Method
          </button> */}
        </div>
      </div>
    </>
  );
}

export default BillingMenu;