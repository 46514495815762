import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Dropdown, Badge, Spinner } from "react-bootstrap";
import PerfCard from "./PerfCard";
import { withStyles } from '@material-ui/core/styles';
import {
  Switch,
  FormControlLabel
} from "@material-ui/core";
import moment from "moment";
import { getProjectStatsAPI, getAllProjectsAPI, getGraphStatsAPI } from "../../api/ProjectAPI";
import { getUserProfileAPI } from "../../api/authAPI";
import { getTeamAPI } from "../../api/teamAPI";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import VerifyAccountModal from "../modals/VerifyAccountModal";
import { ProfileContext } from "../context/ContextProvider";
import GraphComponent from "./Graph";

function DashboardComp() {
  const [showPicker, setShowPicker] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [customStartDateString, setCustomStartDateString] = useState("");
  const [customEndDateString, setCustomEndDateString] = useState("");  
  const [isCustomDate, setIsCustomDate] = useState(false);  
  const [mode, setMode] = useState(
    localStorage.getItem("mode")
      ? localStorage.getItem("mode")
      : "TEST"
  );
  const [proId, setProId] = useState(
    localStorage.getItem("selected_project")
      ? localStorage.getItem("selected_project")
      : ""
  );
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("Today");
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [outgoing, setOutgoing] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [undelivered, setUndelivered] = useState(0);
  const [spend, setSpend] = useState(0);
  const [outgoingPrev, setOutgoingPrev] = useState(0);
  const [deliveredPrev, setDeliveredPrev] = useState(0);
  const [undeliveredPrev, setUndeliveredPrev] = useState(0);
  const [spendPrev, setSpendPrev] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(
    localStorage.getItem("display_balance")
      ? localStorage.getItem("display_balance")
      : 0.0000
  );
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency")
      ? localStorage.getItem("currency")
      : ""
  );
  const [currentRange, setCurrentRange] = useState("current range");
  const [prevRange, setPrevRange] = useState("prev range");
  const [verifyModal, setVerifyModal] = useState(false);
  const { userProfile, setUserProfile } = useContext(ProfileContext);
  const [isIdVerified, setIsIdVerified] = useState(true);
  const [totalOutgoingData1, setTotalOutgoingData1] = useState([]);
  const [totalOutgoingData2, setTotalOutgoingData2] = useState([]);
  const [outgoingLabels, setOutgoingLabels] = useState([]);
  const [outgoingPrevLabels, setOutgoingPrevLabels] = useState([]);
  const [totalDeliveredData1, setTotalDeliveredData1] = useState([]);
  const [totalDeliveredData2, setTotalDeliveredData2] = useState([]);
  const [deliveredLabels, setDeliveredLabels] = useState([]);
  const [deliveredPrevLabels, setDeliveredPrevLabels] = useState([]);
  const [totalUndeliveredData1, setTotalUndeliveredData1] = useState([]);
  const [totalUndeliveredData2, setTotalUndeliveredData2] = useState([]);
  const [undeliveredLabels, setUndeliveredLabels] = useState([]);
  const [undeliveredPrevLabels, setUndeliveredPrevLabels] = useState([]);
  const [totalSpendData1, setTotalSpendData1] = useState([]);
  const [totalSpendData2, setTotalSpendData2] = useState([]);
  const [spendLabels, setSpendLabels] = useState([]);
  const [spendPrevLabels, setSpendPrevLabels] = useState([]);
  //stat geaphs
  const [showStatPicker, setShowStatPicker] = useState(false);
  const [statCustomStartDate, setStatCustomStartDate] = useState(new Date());
  const [statCustomEndDate, setStatCustomEndDate] = useState(new Date());
  const [statCustomStartDateString, setStatCustomStartDateString] = useState("");
  const [statCustomEndDateString, setStatCustomEndDateString] = useState("");
  const [statIsCustomDate, setStatIsCustomDate] = useState(false);
  const [statFilterBy, setStatFilterBy] = useState("Last 7 days");
  const [statFrom, setStatFrom] = useState(moment().format("YYYY-MM-DD"));
  const [statTo, setStatTo] = useState(moment().format("YYYY-MM-DD"));
  const [statCurrentRange, setStatCurrentRange] = useState("current range");
  const [statPrevRange, setStatPrevRange] = useState("prev range");

  useEffect(() => {
    if (userProfile && userProfile.length !== 0) {
      setIsIdVerified(userProfile.id_verified);
    }
  });

  const handleMode = (type) => {
    if (isIdVerified) {
      type = (type === "TEST") ? "LIVE" : "TEST";
      setMode(type);
      localStorage.setItem("mode", type);
    } else {
      setVerifyModal(true);
    }
  };

  const handleCloseVerifyModal = () => {
    setVerifyModal(false);
  };

  const onChangeDateRange = (dates) => {
    const [start, end] = dates;
    setCustomStartDate(start);
    setCustomEndDate(end);
    if (end && start && proId !== "") {
      fetchCustomStats(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"));
    }
  };

  const onChangeStatDateRange = (dates) => {
    const [start, end] = dates;
    setStatCustomStartDate(start);
    setStatCustomEndDate(end);
    if (end && start && proId !== "") {
      fetchCustomGraphStats(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"));
    }
  };

  const handleFilter = (type) => {
    setFilterBy(type);
     if (type==="Custom") {
      setCustomStartDate(new Date(from));
      setCustomEndDate(new Date(to));
      setIsCustomDate(true);
    } else {
      let from = getFromToDate(type).from;
      let to = getFromToDate(type).to;
      setFrom(from);
      setTo(to);
    }
  };

  const handleStatsFilter = (type) => {
    setStatFilterBy(type);
     if (type==="Custom") {
      setStatCustomStartDate(new Date(from));
      setStatCustomEndDate(new Date(to));
      setStatIsCustomDate(true);
    } else {
      let from = getStatFromToDate(type).statFrom;
      let to = getStatFromToDate(type).statTo;
      setStatFrom(from);
      setStatTo(to);
    }
  };

  const activateDatePicker = () => {
    setIsCustomDate(true);
    handleFilter("Custom");
    setTimeout(() => { (document.getElementsByClassName('dashboard-date-picker')[0]).click(); }, 100);
  }

  const activateStatDatePicker = () => {
    setStatIsCustomDate(true);
    handleStatsFilter("Custom");
    setTimeout(() => { (document.getElementsByClassName('dashboard-stat-date-picker')[0]).click(); }, 100);
  }

  const fetchCustomStats = (start, end) => {
    setLoading(true);
    getProjectStatsAPI(
      proId,
      mode,
      start,
      end,
      filterBy,
      (data) => {
        setCurrentRange(moment(data.current_from).format("Do MMM")+ " - "+ moment(data.current_to).format("Do MMM"));
        setPrevRange(moment(data.prev_from).format("Do MMM")+ " - "+ moment(data.prev_to).format("Do MMM"));
        setOutgoing(0);
        setDelivered(0);
        setUndelivered(0);
        setSpend(0);
        setOutgoingPrev(0);
        setDeliveredPrev(0);
        setUndeliveredPrev(0);
        setSpendPrev(0);
        setSMSData(data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }

  const fetchCustomGraphStats = (start, end) => {
    getGraphStatsAPI(
      proId,
      mode,
      start,
      end,
      statFilterBy,
      (data) => {
        setGraphData(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  const getFromToDate = (type) => {
    setIsCustomDate(false);
    let from = null;
    let to = moment().format("YYYY-MM-DD");

    switch (type) {
      case "Today":
        from = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        from = moment(moment().subtract(7, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 4 weeks":
        from = moment(moment().subtract(30, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 3 months":
        from = moment(moment().subtract(90, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 12 months":
        from = moment(moment().subtract(365, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Custom":
        setIsCustomDate(true);
        break;
      default:
        from = null;
        break;
    }
    return { from: from, to: to };
  };

  const getStatFromToDate = (type) => {
    setStatIsCustomDate(false);
    let from = null;
    let to = moment().format("YYYY-MM-DD");

    switch (type) {
      case "Last 7 days":
        from = moment(moment().subtract(7, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 4 weeks":
        from = moment(moment().subtract(30, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 3 months":
        from = moment(moment().subtract(90, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Last 12 months":
        from = moment(moment().subtract(365, "days").calendar()).format(
          "YYYY-MM-DD"
        );
        break;
      case "Custom":
        setStatIsCustomDate(true);
        break;
      default:
        from = null;
        break;
    }

    return { statFrom: from, statTo: to };
  };

  useEffect(() => {
    getTeamAPI(
      (data) => {
        setCurrency(data[0].region.currency_symbol);
        setCurrentBalance(data[0].display_balance);
        localStorage.setItem("currency", data[0].region.currency_symbol);
        localStorage.setItem("display_balance", data[0].display_balance);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    if (!proId) {
      getAllProjectsAPI(
        (data) => {
          if (data.length > 0) {
            setProId(data[0].id);
          }
        },
        (error) => {}
      );
    }
    if (proId !== "" && !isCustomDate) {
      fetchStats();
    }
  }, [from, to, mode, proId, isCustomDate]);

  useEffect(() => {
    if (!proId) {
      getAllProjectsAPI(
        (data) => {
          if (data.length > 0) {
            setProId(data[0].id);
          }
        },
        (error) => {}
      );
    }
    if (proId !== "" && !statIsCustomDate) {
      fetchGraphStats();
    }
  }, [mode, proId, statIsCustomDate, statFrom, statTo]);

  const setSMSData = (data) => {
    if (data.outgoing_sms_current.length > 0) {
      let deliveredCost = (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'DELIVERED') && (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'DELIVERED').length > 0 ? (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'DELIVERED')[0].cost : 0;
      let deliveredTotal = (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'DELIVERED') && (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'DELIVERED').length > 0 ? (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'DELIVERED')[0].total : 0;
      let undeliveredCost = (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'UNDELIVERED') && (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'UNDELIVERED').length > 0 ? (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'UNDELIVERED')[0].cost : 0;
      let undeliveredTotal = (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'UNDELIVERED') && (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'UNDELIVERED').length > 0 ? (data.outgoing_sms_current).filter(smsblock => smsblock.status == 'UNDELIVERED')[0].total : 0;
      let totalCost = deliveredCost + undeliveredCost;
      let sentTotal = deliveredTotal + undeliveredTotal;
      setOutgoing(sentTotal);
      setDelivered(deliveredTotal);
      setUndelivered(undeliveredTotal);
      setSpend(totalCost);
    }

    if (data.outgoing_sms_previous.length > 0) {
      let deliveredCost1 = (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'DELIVERED') && (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'DELIVERED').length > 0 ? (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'DELIVERED')[0].cost : 0;
      let deliveredTotal1 = (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'DELIVERED') && (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'DELIVERED').length > 0 ? (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'DELIVERED')[0].total : 0;
      let undeliveredCost1 = (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'UNDELIVERED') && (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'UNDELIVERED').length > 0 ? (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'UNDELIVERED')[0].cost : 0;
      let undeliveredTotal1 = (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'UNDELIVERED') && (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'UNDELIVERED').length > 0 ? (data.outgoing_sms_previous).filter(smsblock => smsblock.status == 'UNDELIVERED')[0].total : 0;
      let totalCost1 = deliveredCost1 + undeliveredCost1;
      let sentTotal1 = deliveredTotal1 + undeliveredTotal1;
      setOutgoingPrev(sentTotal1);
      setDeliveredPrev(deliveredTotal1);
      setUndeliveredPrev(undeliveredTotal1);
      setSpendPrev(totalCost1);
    }
    setLoading(false);
  }

  const fetchStats = () => {
    setLoading(true);
    getProjectStatsAPI(
      proId,
      mode,
      from,
      to,
      filterBy,
      (data) => {
        setCurrentRange(moment(data.current_from).format("Do MMM")+ " - "+ moment(data.current_to).format("Do MMM"));
        setPrevRange(moment(data.prev_from).format("Do MMM")+ " - "+ moment(data.prev_to).format("Do MMM"));
        setOutgoing(0);
        setDelivered(0);
        setUndelivered(0);
        setSpend(0);
        setOutgoingPrev(0);
        setDeliveredPrev(0);
        setUndeliveredPrev(0);
        setSpendPrev(0);    
        setSMSData(data);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };

  const fetchGraphStats = () => {
    getGraphStatsAPI(
      proId,
      mode,
      statFrom,
      statTo,
      statFilterBy,
      (data) => {
        setGraphData(data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const setGraphData = (data) => {
    setTotalOutgoingData1((data.graphs.outgoing_sms_total.data_points_current).map(x => x.value))
    setTotalOutgoingData2((data.graphs.outgoing_sms_total.data_points_previous).map(x => x.value))
    setTotalDeliveredData1((data.graphs.outgoing_sms_delivered.data_points_current).map(x => x.value))
    setTotalDeliveredData2((data.graphs.outgoing_sms_delivered.data_points_previous).map(x => x.value))
    setTotalUndeliveredData1((data.graphs.outgoing_sms_undelivered.data_points_current).map(x => x.value))
    setTotalUndeliveredData2((data.graphs.outgoing_sms_undelivered.data_points_previous).map(x => x.value))
    setTotalSpendData1((data.graphs.outgoing_sms_cost.data_points_current).map(x => x.value))
    setTotalSpendData2((data.graphs.outgoing_sms_cost.data_points_previous).map(x => x.value))
    setStatCurrentRange(moment(data.current_from).format("Do MMM")+ " - "+ moment(data.current_to).format("Do MMM"));
    setStatPrevRange(moment(data.prev_from).format("Do MMM")+ " - "+ moment(data.prev_to).format("Do MMM"));
    if (data.time_unit === "DAY") {
      setOutgoingLabels((data.graphs.outgoing_sms_total.data_points_current).map(x => moment(x.date).format('DD MMM')));
      setDeliveredLabels((data.graphs.outgoing_sms_delivered.data_points_current).map(x => moment(x.date).format('DD MMM')));
      setUndeliveredLabels((data.graphs.outgoing_sms_undelivered.data_points_current).map(x => moment(x.date).format('DD MMM')));
      setSpendLabels((data.graphs.outgoing_sms_cost.data_points_current).map(x => moment(x.date).format('DD MMM')));
      setOutgoingPrevLabels((data.graphs.outgoing_sms_total.data_points_previous).map(x => moment(x.date).format('DD MMM')));
      localStorage.setItem("OutgoingPrevLabels", JSON.stringify((data.graphs.outgoing_sms_total.data_points_previous).map(x => moment(x.date).format('DD MMM'))));
      setDeliveredPrevLabels((data.graphs.outgoing_sms_delivered.data_points_previous).map(x => moment(x.date).format('DD MMM')));
      localStorage.setItem("DeliveredPrevLabels", JSON.stringify((data.graphs.outgoing_sms_delivered.data_points_previous).map(x => moment(x.date).format('DD MMM'))));
      setUndeliveredPrevLabels((data.graphs.outgoing_sms_undelivered.data_points_previous).map(x => moment(x.date).format('DD MMM')));
      localStorage.setItem("UndeliveredPrevLabels", JSON.stringify((data.graphs.outgoing_sms_undelivered.data_points_previous).map(x => moment(x.date).format('DD MMM'))));
      setSpendPrevLabels((data.graphs.outgoing_sms_cost.data_points_previous).map(x => moment(x.date).format('DD MMM')));
      localStorage.setItem("SpendPrevLabels", JSON.stringify((data.graphs.outgoing_sms_cost.data_points_previous).map(x => moment(x.date).format('DD MMM'))));
    } else if (data.time_unit === "MONTH") {
      setOutgoingLabels((data.graphs.outgoing_sms_total.data_points_current).map(x => moment(x.date).format('yyyy MMM')));
      setDeliveredLabels((data.graphs.outgoing_sms_delivered.data_points_current).map(x => moment(x.date).format('yyyy MMM')));
      setUndeliveredLabels((data.graphs.outgoing_sms_undelivered.data_points_current).map(x => moment(x.date).format('yyyy MMM')));
      setSpendLabels((data.graphs.outgoing_sms_cost.data_points_current).map(x => moment(x.date).format('yyyy MMM')));
      setOutgoingPrevLabels((data.graphs.outgoing_sms_total.data_points_previous).map(x => moment(x.date).format('yyyy MMM')));
      localStorage.setItem("OutgoingPrevLabels", JSON.stringify((data.graphs.outgoing_sms_total.data_points_previous).map(x => moment(x.date).format('yyyy MMM'))));
      setDeliveredPrevLabels((data.graphs.outgoing_sms_delivered.data_points_previous).map(x => moment(x.date).format('yyyy MMM')));
      localStorage.setItem("DeliveredPrevLabels", JSON.stringify((data.graphs.outgoing_sms_delivered.data_points_previous).map(x => moment(x.date).format('yyyy MMM'))));
      setUndeliveredPrevLabels((data.graphs.outgoing_sms_undelivered.data_points_previous).map(x => moment(x.date).format('yyyy MMM')));
      localStorage.setItem("UndeliveredPrevLabels", JSON.stringify((data.graphs.outgoing_sms_undelivered.data_points_previous).map(x => moment(x.date).format('yyyy MMM'))));
      setSpendPrevLabels((data.graphs.outgoing_sms_cost.data_points_previous).map(x => moment(x.date).format('yyyy MMM')));
      localStorage.setItem("SpendPrevLabels", JSON.stringify((data.graphs.outgoing_sms_cost.data_points_previous).map(x => moment(x.date).format('yyyy MMM'))));
    }
  }

  const CustomSwitch = withStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: 'white',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#EF6C00',
          borderColor: 'white',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid white`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#BDBDBD',
    },
    checked: {},
  })(Switch);

  return (
    <Container className="main-container">
      <Row className="m-0 cus-content-div">
        <Col className="p-0">
          <div className="main-header-div" style={{ margin: "auto" }}>
            <div className="header-div">
              <h5 className="sec-heading2">Home</h5>
              <FormControlLabel
                label="Test Mode"
                labelPlacement="start"
                className={(mode === "TEST") ? "switch-label-test" : "switch-label-live"}
                control={
                  <CustomSwitch
                    checked= {(mode === "TEST")}
                    onChange={() => handleMode(mode)}
                    name="checkedB"
                    color="primary"
                  />
                }
              />
            </div>
            <hr className={mode=="LIVE" ? 'live-hr' : 'test-hr'}/>
            {mode == 'TEST' && (
              <Badge bg="warning" text="dark" className="test-label">
                TEST MODE
              </Badge>
            )}
          </div>
          <div style={{ margin: "auto", marginTop: "12px" }}>
            <div style={{display: "flex"}}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="dropdown-basic"
                  size="sm"
                  className="page-drop-down"
                  style={{borderTopRightRadius: '0', borderBottomRightRadius: '0'}}
                >
                  {filterBy}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleFilter("Today")}
                  >
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleFilter("Last 7 days")}
                  >
                    Last 7 days
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleFilter("Last 4 weeks")}
                  >
                    Last 4 weeks
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleFilter("Last 3 months")}
                  >
                    Last 3 months
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleFilter("Last 12 months")}
                  >
                    Last 12 months
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    // onClick={() => handleFilter("Custom")}
                    style={{color:'#4f4f4f', pointerEvents: 'none'}}
                  >
                    Custom
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {isCustomDate ?
                <p
                  style={{
                    fontSize: '14px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '4px',
                    textAlign: 'center',
                    borderColor: '#4f4f4f',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    height: '31px',
                    borderTopRightRadius: '0.2rem',
                    borderBottomRightRadius: '0.2rem',
                    borderLeft: 'none',
                    fontWeight: '500',
                    cursor: 'pointer'
                  }}>
                    <DatePicker
                      selected={customStartDate}
                      onChange={onChangeDateRange}
                      startDate={customStartDate}
                      endDate={customEndDate}
                      selectsRange
                      dateFormat="d MMM"
                      className="dashboard-date-picker"
                    />
                </p>
                :
                <p
                  onClick={activateDatePicker}
                  style={{
                    fontSize: '14px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '4px',
                    textAlign: 'center',
                    borderColor: '#4f4f4f',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    height: '31px',
                    borderTopRightRadius: '0.2rem',
                    borderBottomRightRadius: '0.2rem',
                    borderLeft: 'none',
                    fontWeight: '500',
                    cursor: 'pointer'
                  }}>
                    {currentRange}
                </p>
              }
              <p
                style={{
                  fontSize: '14px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  paddingTop: '4px',
                  textAlign: 'center',
                  height: '31px',
                }}>
                  compared to
              </p>
              <p
                style={{
                  fontSize: '14px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingTop: '4px',
                  textAlign: 'center',
                  borderColor: '#4f4f4f',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  height: '31px',
                  borderRadius: '0.2rem',
                  background: '#f0f0f0',
                  fontWeight: '500'
                }}>
                  {prevRange}
              </p>
            </div>
            <div className="overview-det-div">
              <p>
                <div className="billing-small-label">Current Balance</div>
                <div className="current-balance">{currency} {currentBalance}</div>
              </p>
            </div>
            {loading ? (
              <div className="loading-container">
                <Spinner animation="border" size="md" />
              </div>
            ) : (
              <div>
                <PerfCard
                  title="Total Outgoing SMS"
                  count={outgoing}
                  prevCount={outgoingPrev}
                  isCurrency={false}                  
                />
                <PerfCard
                  title="Total Delivered"
                  count={delivered}
                  isCurrency={false}
                  prevCount={deliveredPrev}
                />
                <PerfCard
                  title="Total Undelivered"
                  count={undelivered}
                  isCurrency={false}
                  prevCount={undeliveredPrev}
                />
                <PerfCard
                  title="Total Spend"
                  count={spend.toFixed(2)}
                  isCurrency={true}
                  prevCount={spendPrev}
                />
              </div>
            )}
            <div style={{marginTop: '40px'}}>
              <div style={{display: "flex"}}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="dropdown-basic"
                    size="sm"
                    className="page-drop-down"
                    style={{borderTopRightRadius: '0', borderBottomRightRadius: '0'}}
                  >
                    {statFilterBy}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleStatsFilter("Last 7 days")}
                    >
                      Last 7 days
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleStatsFilter("Last 4 weeks")}
                    >
                      Last 4 weeks
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleStatsFilter("Last 3 months")}
                    >
                      Last 3 months
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleStatsFilter("Last 12 months")}
                    >
                      Last 12 months
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      // onClick={() => handleFilter("Custom")}
                      style={{color:'#4f4f4f', pointerEvents: 'none'}}
                    >
                      Custom
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {statIsCustomDate ?
                  <p
                    style={{
                      fontSize: '14px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      paddingTop: '4px',
                      textAlign: 'center',
                      borderColor: '#4f4f4f',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      height: '31px',
                      borderTopRightRadius: '0.2rem',
                      borderBottomRightRadius: '0.2rem',
                      borderLeft: 'none',
                      fontWeight: '500',
                      cursor: 'pointer'
                    }}>
                      <DatePicker
                        selected={statCustomStartDate}
                        onChange={onChangeStatDateRange}
                        startDate={statCustomStartDate}
                        endDate={statCustomEndDate}
                        selectsRange
                        dateFormat="d MMM"
                        className="dashboard-stat-date-picker"
                      />
                  </p>
                  :
                  <p
                    onClick={activateStatDatePicker}
                    style={{
                      fontSize: '14px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      paddingTop: '4px',
                      textAlign: 'center',
                      borderColor: '#4f4f4f',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      height: '31px',
                      borderTopRightRadius: '0.2rem',
                      borderBottomRightRadius: '0.2rem',
                      borderLeft: 'none',
                      fontWeight: '500',
                      cursor: 'pointer'
                    }}>
                      {statCurrentRange}
                  </p>
                }
                <p
                  style={{
                    fontSize: '14px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingTop: '4px',
                    textAlign: 'center',
                    height: '31px',
                  }}>
                    compared to
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '4px',
                    textAlign: 'center',
                    borderColor: '#4f4f4f',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    height: '31px',
                    borderRadius: '0.2rem',
                    background: '#f0f0f0',
                    fontWeight: '500'
                  }}>
                    {statPrevRange}
                </p>
              </div>
            </div>
            {loading ? (
              <div className="loading-container">
                <Spinner animation="border" size="md" />
              </div>
            ) : (
              <div className="dashboard-graphs-main-div" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px'}}>
                <div className="stat-graph-div" style={{background: '#F2F2F2', marginTop: '20px'}}><GraphComponent title="Total Outgoing SMS" data1={totalOutgoingData1} data2={totalOutgoingData2} labels={outgoingLabels} prevLabels={outgoingPrevLabels} isCurrency={false} /></div>
                <div className="stat-graph-div" style={{background: '#F2F2F2', marginTop: '20px'}}><GraphComponent title="Total Delivered" data1={totalDeliveredData1} data2={totalDeliveredData2} labels={deliveredLabels} prevLabels={deliveredPrevLabels} isCurrency={false} /></div>
                <div className="stat-graph-div" style={{background: '#F2F2F2', marginTop: '20px'}}><GraphComponent title="Total Undelivered" data1={totalUndeliveredData1} data2={totalUndeliveredData2} labels={undeliveredLabels} prevLabels={undeliveredPrevLabels} isCurrency={false} /></div>
                <div className="stat-graph-div" style={{background: '#F2F2F2', marginTop: '20px'}}><GraphComponent title="Total Spend" data1={totalSpendData1} data2={totalSpendData2} labels={spendLabels} prevLabels={spendPrevLabels} isCurrency={true} /></div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <VerifyAccountModal
        showModal={verifyModal}
        closeModal={handleCloseVerifyModal}
      />
    </Container>
  ); 
}

export default DashboardComp;
