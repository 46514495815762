import React, { useState, useEffect } from "react";
import { Modal, Table, Spinner, Form, Row, Col } from "react-bootstrap";
import classnames from "classnames";
import qs from "qs";
import DeleteGroupModal from "./DeleteGroupModal";

import Close from "../../images/close.png";

import {
  addGroup,
  getGroups,
  updateGroup,
  deleteGroup,
} from "../../api/groupAPI";

const initialState = {
  name: "",
  nameErr: "",
};

function ManageGroupsModal({ showGroupModal, setShowGroupModal }) {
  const [state, setState] = useState(initialState);
  const [form, setForm] = useState(initialState);
  const [groups, setGroups] = useState([]);
  const [id, setId] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [deleteGroupModal, setDeleteGroupModal] = useState(false);
  const [deletingId, setDeletingId] = useState("");

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    setPageLoading(true);
    getGroups(
      (data) => {
        const sort = data.sort((a, b) =>
          b.created_at.localeCompare(a.created_at)
        );
        setGroups(sort);
        setPageLoading(false);
      },
      (err) => {
        setPageLoading(false);
        console.log(err);
      }
    );
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  const handleChangeUpdate = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  // validate
  const validate = () => {
    let nameErr = "";

    if (!state.name) {
      nameErr = "Group name is required";
    }

    if (nameErr) {
      setState({
        ...state,
        nameErr,
      });

      return false;
    }

    return true;
  };

  const addToGroup = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        name: state.name,
      });

      addGroup(
        data,
        (data) => {
          setState(initialState);
          setLoading(false);
          fetchGroups();
        },
        (err) => {
          setLoading(false);
        }
      );
    }
  };

  const handleUpdate = (id, name) => {
    setId(id);
    setForm({ ...form, name });
  };

  const saveUpdate = () => {
    setLoadingSave(true);

    const data = qs.stringify({
      name: form.name,
    });

    updateGroup(
      id,
      data,
      (data) => {
        setId();
        setForm(initialState);
        fetchGroups();
        setLoadingSave(false);
      },
      (err) => {
        setLoadingSave(false);
        console.log(err);
      }
    );
  };

  const handleDelete = (id) => {
    setDeleteGroupModal(true);
    document.getElementById('manage-group-header').style.filter = 'blur(5px)';
    document.getElementById('manage-group-body').style.filter = 'blur(5px)';
    setDeletingId(id);
    
  };

  const handleCancel = () => {
    setId("");
    setState(initialState);
  };

  const handleClose = () => {
    setState(initialState);
    setShowGroupModal(false);
  };

  const handleGroupDelete = () => {
    deleteGroup(
      deletingId,
      (data) => {
        fetchGroups();
        setLoadingDel(false);
        setDeleteGroupModal(false);
        document.getElementById('manage-group-header').style.filter = 'none';
        document.getElementById('manage-group-body').style.filter = 'none';
      },
      (err) => {
        setLoadingDel(false);
        setDeleteGroupModal(false);
        document.getElementById('manage-group-header').style.filter = 'none';
        document.getElementById('manage-group-body').style.filter = 'none';
        console.log(err);
      }
    );
  };

  return (
    <Modal
      show={showGroupModal}
      onHide={() => setShowGroupModal(false)}
      centered
    >
      <Modal.Header id="manage-group-header">
        <Modal.Title className="modal-title">Manage Groups</Modal.Title>
        <img
          src={Close}
          alt="close"
          onClick={handleClose}
          style={{ cursor: "pointer"}}
          className="modal-close-btn"
        />
      </Modal.Header>
      <Modal.Body className="pt-0" id="manage-group-body">
        <hr className="live-hr modal-hr" />
        <Table
          className="custom-table mt-4 without-hover-table"
          responsive
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th width="90px" style={{ textAlign: "center" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form>
                  <Form.Group className="p-0">
                    <Form.Control
                      type="text"
                      className={classnames("custom-form-control form-control", {
                        "is-invalid": state.nameErr,
                      })}
                      name="name"
                      value={state.name}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {state.nameErr}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </td>
              <td align="center">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={addToGroup}
                  style={{ marginRight: 10, border: '#2979ff 1px solid', width: '65px' }}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}
                  {loading ? (
                    ""
                  ) : (
                    <span style={{color: '#2979ff'}}>
                      <i className="fas fa-check"></i> Add
                    </span>
                  )}
                </button>
              </td>
            </tr>
            {pageLoading ? (
              <tr>
                <td colSpan="2" align="center">
                  <Spinner animation="border" size="sm" />
                </td>
              </tr>
            ) : groups.length > 0 ? (
              groups.map((el, i) => (
                <tr key={i}>
                  <td>
                    {el.id === id ? (
                      <Form>
                        <Form.Group className="p-0">
                          {/* <Form.Label>First name</Form.Label> */}
                          <Form.Control
                            type="text"
                            className={classnames("custom-form-control form-control", {
                              "is-invalid": form.nameErr,
                            })}
                            name="name"
                            value={form.name}
                            onChange={handleChangeUpdate}
                            autoComplete="off"
                          />
                          <Form.Control.Feedback type="invalid">
                            {form.nameErr}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form>
                    ) : (
                      el.name
                    )}
                  </td>
                  <td>
                    {el.id === id ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={saveUpdate}
                          style={{ marginRight: 10 }}
                        >
                          {loadingSave ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          {loadingSave ? "" : <i className="fas fa-check"></i>}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={handleCancel}
                          style={{ width: 30 }}
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => handleUpdate(el.id, el.name)}
                          style={{ marginRight: 10 }}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => handleDelete(el.id)}
                        >
                          {loadingDel ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          {loadingDel ? (
                            ""
                          ) : (
                            <i className="far fa-trash-alt"></i>
                          )}
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" align="center">
                  No records
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* <Form>
          <Form.Group className="p-0 mb-3">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              className={classnames("state-control", {
                "is-invalid": state.first_nameErr,
              })}
              name="first_name"
              value={state.first_name}
              onChange={handleChange}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {state.first_nameErr}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="p-0 mb-3">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              className={classnames("state-control", {
                "is-invalid": state.last_nameErr,
              })}
              name="last_name"
              value={state.last_name}
              onChange={handleChange}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {state.last_nameErr}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="p-0 mb-4">
            <Form.Label>Contact Number</Form.Label>
            <div
              className={
                state.numberErr
                  ? "input-group cus-react-tel-input-div"
                  : "input-group"
              }
            >
              <PhoneInput
                country="lk"
                // disableDropdown={true}
                value={state.number}
                enableSearch={true}
                disableSearchIcon={true}
                countryCodeEditable={false}
                prefix="+"
                onChange={(phone) => handlePhone(phone)}
              />
            </div>
            <p className="cus-error">{state.numberErr}</p>
          </Form.Group>

          <button
            type="submit"
            className="btn-submit mt-3"
            onClick={handleSubmit}
          >
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            {loading ? "" : "Save"}
          </button>
        </Form>
     
      */}
        <Row className="">
          <Form.Label column sm="4">
          </Form.Label>
          <Col sm="8" style={{display:"flex", justifyContent: "right"}}>
            <button type="submit" className="btn btn-outline-secondary btn-sm mt-3" onClick={handleClose} style={{ width: "80px", color: "#333333" }}>
              Cancel
            </button>
          </Col>
        </Row>
      </Modal.Body>
      {deleteGroupModal && (
      <DeleteGroupModal
        deleteModal={deleteGroupModal}
        setDeleteModal={setDeleteGroupModal}
        // multiDelLoading={multiDelLoading}
        handleMultiDelete={handleGroupDelete}
      />
    )}
    </Modal>    
  );
}

export default ManageGroupsModal;
