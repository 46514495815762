import React from "react";
import DashboardLayout from "../components/layouts/DashboardLayout";
import RequestSenderIdComp from "../components/settings/RequestSenderIdComp";
import PageTitle from "../components/common/PageTitle";

function NewCampaign() {
  return (
    <DashboardLayout>
      <PageTitle page="Sender ID" />
      <RequestSenderIdComp />
    </DashboardLayout>
  );
}

export default NewCampaign;