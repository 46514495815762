import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Table, Spinner, Dropdown } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getPayments } from "../../api/paymentAPI";
import "../../styles/billing.css";
import { getTeamAPI } from "../../api/teamAPI";

function BillingUsageComp() {
  const [pageLoading, setPageLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency")
      ? localStorage.getItem("currency")
      : ""
  );

  useEffect(() => {
    fetchPayments();
  }, []);

  useEffect(() => {
    if (!currency) {
      getTeamAPI(
        (data) => {
          setCurrency(data[0].region.currency_symbol);
          localStorage.setItem("currency", data[0].region.currency_symbol);
          localStorage.setItem("display_balance", data[0].display_balance);
        },
        (error) => {}
      );
    }
  }, []);

  const fetchPayments = () => {
    setPageLoading(true);
    getPayments(
      (data) => {
        setPayments(data);
        setPageLoading(false);
      },
      (err) => {
        console.log(err);
        setPageLoading(false);
      }
    );
  };

  return (
    <>
      <Container className="cus-container main-container billing-cus-container" style={{marginLeft: '2%'}}>
        <Row className="m-0 cus-content-div">
          <Col className="p-0">
            {pageLoading ? (
              <div className="loading-container">
                <Spinner animation="border" size="md" />
              </div>
            ) : (
              <>
                <div className="main-header-div">
                  <div className="header-div">
                    <h5 className="sec-heading2">Payment History</h5>
                  </div>
                  <hr className="live-hr" />
                </div>
                <div
                  style={{ position: "relative", margin: "auto" }}
                >
                  <Table
                    className="custom-table"
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    <thead>
                      <tr>
                        <th align="center">Date</th>
                        <th align="center">Payment Method</th>
                        <th align="center">Status</th>
                        {/* <th align="center">Initiator</th> */}
                        <th align="center">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageLoading ? (
                        <tr>
                          <td colSpan="6" align="center">
                            <Spinner animation="border" size="sm" />
                          </td>
                        </tr>
                      ) : payments.length > 0 ? (
                        payments.map((el, i) => (
                          <tr key={i}>
                            <td>{new Date(el.created_at).toLocaleString('en-US')}</td>
                            {el.type == "BANK_TX" ? <td>Bank Transfer</td> : <td className="card-no">{el.card_last_4}</td>}
                            <td>{el.status == "SUCCEEDED" ? "Success" : "Failed"}</td>
                            <td>{currency} {el.amount.toFixed(2)}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" align="center">
                            No records
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withRouter(BillingUsageComp);