import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import classnames from "classnames";
import qs from "qs";

// import { getCSRFToken, storeAuth } from "../../../auth/auth";
import { refreshCSRF, loginSession } from "../../api/authAPI";

import LogoColComp from "./LogoColComp";

function LoginComp(props) {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [authErr, setAuthErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    setEmailErr("");
  };

  useEffect(() => {
    refreshCSRF(
      (data) => {},
      (error) => {
        console.log(error);
      }
    );
  }, []);

  // validate
  const validate = () => {
    let emailErr = "";

    if (!email) {
      emailErr = "Email is required";
    }

    if (emailErr) {
      setEmailErr(emailErr);

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        user_id: email,
      });

      // loginSession(
      //   data,
      //   (data) => {
      //     // props.history.push("/dashboard");
      //   },
      //   (err) => {
      //     if (err.response.data.code === 4003) {
      //       setAuthErr("Incorrect username or/and password!");
      //     } else if (err.response.data.code === 4004) {
      //       setAuthErr(
      //         "Please verify your email address before login. Please check your mail inbox."
      //       );
      //     }
      //     setLoading(false);
      //   }
      // );
    }
  };

  return (
    <Container fluid>
      <Row>
        <LogoColComp />
        <Col xs={12} md={6} className="login-reg-col">
          <div className="login-reg-container">
            <Link to="/" className="back-btn mb-4">
              <i className="fas fa-angle-left"></i> Back
            </Link>
            <p className="heading">Forgot Password</p>
            <p style={{ fontSize: 14, color: "#dc3545" }}>{authErr}</p>
            <Form>
              <Form.Group className="p-0">
                <Form.Control
                  type="email"
                  className={classnames("form-control", {
                    "is-invalid": emailErr,
                  })}
                  id="email"
                  name="email"
                  placeholder="Email you provided in registration"
                  onChange={handleChange}
                  value={email}
                />
                <Form.Control.Feedback type="invalid">
                  {emailErr}
                </Form.Control.Feedback>
              </Form.Group>

              <button
                type="submit"
                className="btn-submit"
                onClick={handleSubmit}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {loading ? "" : "Send"}
              </button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(LoginComp);
