import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";

import Logo from "../../images/logo.png";
import VerifyEmail from "../../images/email-verify-icon.png";
import { requestVerificationEmailAPI } from "../../api/authAPI";
import { toast } from "react-toastify";
import qs from "qs";

function VerificationRequiredComp(props) {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleResendEmail = (e) => {
    e.preventDefault();
    if (props && props.email) {
      const data = qs.stringify({
        email: props.email,
      });

      requestVerificationEmailAPI(
        data,
        (data) => {
          toast.success("Verification email sent successfully");
        },
        (err) => {
          toast.error(err.response.data.description);
        }
      );
    }
  };

  return (
    <Container fluid>
      <Row>
        {/* <LogoColComp /> */}
        <Col xs={12} className="login-reg-col">
          <div className="login-reg-container">
            <img
              src={Logo}
              className="logo d-block m-auto"
              alt="sms160"
              width="175"
            />
            <p className="heading mt-4">Verification Required</p>
            <p className="text-center text-muted" style={{ fontSize: 14 }}>
              Check your inbox and follow the link in the email to verify your
              account.
            </p>
            <img
              src={VerifyEmail}
              alt="verify email"
              className="d-block m-auto"
              width="100"
            />
            <p className="mt-5 form-bellow-link">
              <span style={{ color: "#033d5e" }}>Didn't get an email?</span>{" "}
              <span
                onClick={handleResendEmail}
                to="#"
                className="login-now-link"
                style={{ cursor: "pointer" }}
              >
                Resend
              </span>
            </p>
            <p className="mt-3 form-bellow-link">
              <span style={{ color: "#033d5e" }}>Already verified?</span>{" "}
              <Link to="/" className="login-now-link">
                Login
              </Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(VerificationRequiredComp);
