import React from "react";
import DashboardLayout from "../components/layouts/DashboardLayout";
import SendSMSComp from "../components/campaigns/SendSMSComp";
import PageTitle from "../components/common/PageTitle";

function SendSMS() {
  return (
    <DashboardLayout>
      <PageTitle page="Send SMS"/>
      <SendSMSComp />
    </DashboardLayout>
  );
}

export default SendSMS;