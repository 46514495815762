import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import classnames from "classnames";
import qs from "qs";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';

// import { getCSRFToken, storeAuth } from "../../../auth/auth";
import { refreshCSRF, loginSession, resetPassword } from "../../api/authAPI";

import Logo from "../../images/logo.png";

function ResetPasswordComp() {
  const [form, setForm] = useState({
    password: "",
    confPassword: "",
    passwordErr: "",
    confPasswordErr: "",
  });
  const [authErr, setAuthErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  useEffect(() => {
    refreshCSRF(
      (data) => {},
      (error) => {
        console.log(error);
      }
    );
  }, []);

  let history = useHistory();
  // validate
  const validate = () => {
    let passwordErr = "";
    let confPasswordErr = "";

    if (!form.password) {
      passwordErr = "Password is required";
    } else if (
      !form.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      passwordErr =
        "Minimum eight characters, at least one uppercase letter, one lowercase letter and one special character";
    }

    if (!form.confPassword) {
      confPasswordErr = "Confirm password is required";
    }
    if (form.confPassword !== form.password) {
      confPasswordErr = "Password and Confirm Password Must be same";
    }

    if (passwordErr || confPasswordErr) {
      setForm({
        ...form,
        passwordErr,
        confPasswordErr,
      });
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('t');

      const data = qs.stringify({
        password: form.password,
        token: token
      });
      resetPassword(
        data,
        (data) => {
          setLoading(false);
          window.location.href='/home'
        },
        (err) => {
          toast.error("Something went wrong. Please try again");
          setLoading(false);
        }
      );
    }
  };

  return (
    <Container>
      <Row className="m-0 cus-content-div">
        <Col className="p-0">
              <Col>
                <h5 className="sec-heading" style={{marginBottom:'6px'}}>Reset Password</h5>
              </Col>
              <hr style={{ marginLeft:'auto', marginRight:'auto', marginTop:'0'}}/>
              <div className="">
                <Col xl="7">
                  <Form>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" style={{margin:'auto'}}>
                        Password
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="password"
                          className={classnames("form-control custom-form-control mt-3", {
                            "is-invalid": form.passwordErr,
                          })}
                          name="password"
                          placeholder="New password"
                          onChange={handleChange}
                          value={form.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {form.passwordErr}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" style={{margin:'auto'}}>
                        Confirm Password
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="password"
                          className={classnames("form-control custom-form-control mt-3", {
                            "is-invalid": form.confPasswordErr,
                          })}
                          name="confPassword"
                          placeholder="Confirm password"
                          onChange={handleChange}
                          value={form.confPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {form.confPasswordErr}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col sm="3"></Col>
                      <Col sm="3">
                        <button
                          type="submit"
                          className="btn-cancel"
                          onClick={() => history.goBack()}
                        >
                          {loading ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          {loading ? "" : "Cancel"}
                        </button>
                      </Col>
                      <Col sm="3">
                        <button
                          type="submit"
                          className="btn-submit"
                          onClick={handleSubmit}
                        >
                          {loading ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          {loading ? "" : "Save"}
                        </button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </div>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(ResetPasswordComp);
