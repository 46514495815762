import React from "react";
import { withRouter } from "react-router-dom";
import { Col } from "react-bootstrap";

import Logo from "../../images/logo_white.png";

function RegisterComp(props) {
  return (
    <Col xs={12} md="6" className="logo-col">
      <div className="logo-back-div">
        <img src={Logo} className="logo" alt="sms160" />

        <h3 className="heading">Follow below steps to reset your password</h3>

        <table className="feature-table">
          <tbody>
            <tr>
              <td width="40">
                <span className="forget-password-steps">1.</span>
              </td>
              <td>Type your email and press "Send"</td>
            </tr>
            <tr>
              <td>
                <span className="forget-password-steps">2.</span>
              </td>
              <td>Check your mail inbox</td>
            </tr>
            <tr>
              <td>
                <span className="forget-password-steps">3.</span>
              </td>
              <td>Click the link which received</td>
            </tr>
            <tr>
              <td>
                <span className="forget-password-steps">4.</span>
              </td>
              <td>Going to reset password webpage</td>
            </tr>
            <tr>
              <td>
                <span className="forget-password-steps">5.</span>
              </td>
              <td>Type your new password and save</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Col>
  );
}

export default withRouter(RegisterComp);
