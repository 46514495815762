import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import { Container, Row, Col, Form, ProgressBar, Spinner } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/campaigns.css";
import { useHistory } from "react-router-dom";
import { uploadFileAPI } from "../../api/genericAPI";
import { requestUserVerificationAPI, getPendingUserVerificationRequests } from "../../api/authAPI";
import { toast } from "react-toastify";

const initial = {
  name: "",
  nameErr: "",
  letterOneErr: "",
  name2: "",
  name2Err: "",
  letterTwoErr: "",
  name3: "",
  name3Err: "",
  letterThreeErr: "",
};

function RequestSendeIdComp() {
  let history = useHistory();
  const [state, setState] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [docOne, setDocOne] = useState("");
  const [isRequestDocOne, setIsRequestDocOne] = useState(false);
  const [docOnePercentage, setDocOnePercentage] = useState(0);
  const [docTwo, setDocTwo] = useState("");
  const [isRequestDocTwo, setIsRequestDocTwo] = useState(false);
  const [docTwoPercentage, setDocTwoPercentage] = useState(0);
  const [docThree, setDocThree] = useState("");
  const [isRequestDocThree, setIsRequestDocThree] = useState(false);
  const [docThreePercentage, setDocThreePercentage] = useState(0);
  const [docOneName, setDocOneName] = useState("No file selected");
  const [docTwoName, setDocTwoName] = useState("No file selected");
  const [docThreeName, setDocThreeName] = useState("No file selected");
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    getPendingRequests();
  }, []);

  const getPendingRequests = () => {
    getPendingUserVerificationRequests(
      (data) => {
        if (data && data.length > 0) {
          setIsPending(true)
        } else {
          setIsPending(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleChangeName = (e) => {
    if (e.target.name == "name") {
      setName(e.target.value);
      let nameErr = "";
      setState({
        ...state,
        nameErr
      });
    } else if (e.target.name == "name2") {
      setName2(e.target.value);
      let name2Err = "";
      setState({
        ...state,
        name2Err
      });
    } else if (e.target.name == "name3") {
      setName3(e.target.value);
      let name3Err = "";
      setState({
        ...state,
        name3Err
      });
    } 
  };

  // validate
  const validate = () => {
    let nameErr = "";
    let letterOneErr = "";
    let name2Err = "";
    let letterTwoErr = "";
    let name3Err = "";
    let letterThreeErr = "";

    if (!name) {
      nameErr = "Name is Required ";
    }
    if (!docOne) {
      letterOneErr = "Id is Required";
    }

    if (docTwo || name2) {
      if (!docTwo) {
        letterTwoErr = "Id is Required";
      }
      if (!name2) {
        name2Err = "Name is Required ";
      }
    }

    if (docThree || name3) {
      if (!docThree) {
        letterThreeErr = "Id is Required";
      }
      if (!name3) {
        name3Err = "Name is Required ";
      }
    }

    if (nameErr || letterOneErr || name2Err || letterTwoErr || name3Err || letterThreeErr) {
      setState({
        ...state,
        nameErr,
        letterOneErr,
        name2Err,
        letterTwoErr,
        name3Err,
        letterThreeErr
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const data = {
        name: name,
        id: docOne
      };
      let dataArray = [data];

      if (name2) {
        const data2 = {
          name: name2,
          id: docTwo
        };
        dataArray.push(data2);
      }

      if (name3) {
        const data3 = {
          name: name3,
          id: docThree
        };
        dataArray.push(data3);
      }

      requestUserVerificationAPI(
        dataArray,
        (data) => {
          setLoading(false);
          toast.success("Verification request sent successfully");
          history.replace("/campaigns");
        },
        (err) => {
          setLoading(false);
          toast.error(err.response.data.description);
        }
      );
    }
  };

  const handleRemoveSubmitDocOne = (e) => {
    setDocOne("");
    setDocOneName("No file selected");
    setIsRequestDocOne(false);
  }

  const handleRemoveSubmitDocTwo = (e) => {
    setDocTwo("");
    setDocTwoName("No file selected");
    setIsRequestDocTwo(false);
  }

  const handleRemoveSubmitDocThree = (e) => {
    setDocThree("");
    setDocThreeName("No file selected");
    setIsRequestDocThree(false);
  }

  const handleSubmitDocOne = (e) => {
    let imageFile = new FormData();
    imageFile.append("file", e.target.files[0]);
    uploadFileAPI(
      imageFile,
      (uploadProgress) => {
        setDocOnePercentage(uploadProgress);
      },
      (data) => {
        setDocOneName(e.target.files[0].name);
        setDocOnePercentage(100);
        setDocOne(data.data.id);
        setIsRequestDocOne(true);
        let letterOneErr = "";
        setState({
          ...state,
          letterOneErr
        });
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again");
      }
    );
  };

  const handleSubmitDocTwo = (e) => {
    let imageFile = new FormData();
    imageFile.append("file", e.target.files[0]);
    uploadFileAPI(
      imageFile,
      (uploadProgress) => {
        setDocTwoPercentage(uploadProgress);
      },
      (data) => {
        setDocTwoName(e.target.files[0].name);
        setDocTwoPercentage(100);
        setDocTwo(data.data.id);
        setIsRequestDocTwo(true);
        let letterTwoErr = "";
        setState({
          ...state,
          letterTwoErr
        });
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again");
      }
    );
  };

  const handleSubmitDocThree = (e) => {
    let imageFile3 = new FormData();
    imageFile3.append("file", e.target.files[0]);
    uploadFileAPI(
      imageFile3,
      (uploadProgress) => {
        setDocThreePercentage(uploadProgress);
      },
      (data) => {
        setDocThreeName(e.target.files[0].name);
        setDocThreePercentage(100);
        setDocThree(data.data.id);
        setIsRequestDocThree(true);
        let letterThreeErr = "";
        setState({
          ...state,
          letterThreeErr
        });
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again");
      }
    );
  };

  return (
    <>
      <Container>
      <Link to={"/new-campaign"} className="dash-back-btn">
        <i className="fas fa-angle-left"></i> Back
      </Link>
      {!isPending ?
        <Row className="m-0 cus-content-div">
          <Form style={{paddingLeft: '0', paddingRight: '0'}}>
            <div className="main-header-div">
              <div className="header-div">
                <h5 className="sec-heading2">Add new user verification request</h5>
              </div>
              <hr className="live-hr" />
              <div style={{background:"#e3f2fd", fontSize:"14px", padding:"10px", width:"65%", marginTop:"20px", marginBottom:"20px", textAlign:"left"}}>Please veify your account before sending SMS. Upload relevant document which can be proven your identity.</div>
            </div>
            <h6 className="settings-subtitle">Upload Supporting Documents</h6>
            <hr className="live-hr" />
            <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Document 1 Name
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    type="text"
                    className={classnames("custom-form-control", {
                        "is-invalid": state.nameErr,
                    })}
                    name="name"
                    value={name}
                    onChange={handleChangeName}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {state.nameErr}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
            <Form>
              <Form.Group as={Row} style={{ marginTop: "18px" }}>
                <Form.Label column sm="2">
                  Document 1
                </Form.Label>
                <Col sm="5" style={{marginTop:"auto", marginBottom:"auto"}}>
                  <div>{docOneName}</div>
                  {docOnePercentage > 0 && (
                    <div className="uploading-image-progressbar">
                      { docOnePercentage > 0 && docOnePercentage < 100
                          ? <ProgressBar now={docOnePercentage} />
                          : ""}
                    </div>
                  )}
                  <div style={{display:"flex"}}>
                    {!isRequestDocOne && (
                      <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex", marginBottom:"10px"}}>
                        <input type="file" name="file" id="icon-button-file-reg" style={{ display: 'none' }} onChange={handleSubmitDocOne}/>
                        <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-reg">Upload</label>
                      </div>
                    )}
                    {isRequestDocOne && (
                      <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex", marginBottom:"10px"}}>
                        <input type="button" name="file-remove" id="icon-button-file-remove-reg" style={{ display: 'none' }} onClick={handleRemoveSubmitDocOne}/>
                        <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-remove-reg">Remove</label>
                      </div>
                    )}
                  </div>
                  <div id="letter-one-error" style={{color:"#e04d5b", fontSize:".875em"}}>{state.letterOneErr}</div>
                </Col>
              </Form.Group>
            </Form>
            <hr className="live-hr" />
            <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Document 2 Name
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    type="text"
                    className={classnames("custom-form-control", {
                        "is-invalid": state.name2Err,
                    })}
                    name="name2"
                    value={name2}
                    onChange={handleChangeName}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {state.name2Err}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
            <Form>
              <Form.Group as={Row} style={{ marginTop: "18px" }}>
                <Form.Label column sm="2">
                  Document 2
                </Form.Label>
                <Col sm="5" style={{marginTop:"auto", marginBottom:"auto"}}>
                  <div>{docTwoName}</div>
                  {docTwoPercentage > 0 && (
                    <div className="uploading-image-progressbar">
                      { docTwoPercentage > 0 && docTwoPercentage < 100
                          ? <ProgressBar now={docTwoPercentage} />
                          : ""}
                    </div>
                  )}
                  <div style={{display:"flex"}}>
                    {!isRequestDocTwo && (
                      <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex", marginBottom:"10px"}}>
                        <input type="file" name="file-2" id="icon-button-file-reg-2" style={{ display: 'none' }} onChange={handleSubmitDocTwo}/>
                        <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-reg-2">Upload</label>
                      </div>
                    )}
                    {isRequestDocTwo && (
                      <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex", marginBottom:"10px"}}>
                        <input type="button" name="file-remove-2" id="icon-button-file-remove-reg-2" style={{ display: 'none' }} onClick={handleRemoveSubmitDocTwo}/>
                        <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-remove-reg-2">Remove</label>
                      </div>
                    )}
                  </div>
                  <div id="letter-two-error" style={{color:"#e04d5b", fontSize:".875em"}}>{state.letterTwoErr}</div>
                </Col>
              </Form.Group>
            </Form>
            <hr className="live-hr" />
            <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Document 3 Name
                </Form.Label>
                <Col sm="5" style={{marginTop:"auto", marginBottom:"auto"}}>
                  <Form.Control
                    type="text"
                    className={classnames("custom-form-control", {
                        "is-invalid": state.name3Err,
                    })}
                    name="name3"
                    value={name3}
                    onChange={handleChangeName}
                    autoComplete="off"
                  />
                    <Form.Control.Feedback type="invalid">
                      {state.name3Err}
                    </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
            <Form>
              <Form.Group as={Row} style={{ marginTop: "18px" }}>
                <Form.Label column sm="2">
                  Document 3
                </Form.Label>
                <Col sm="5" style={{marginTop:"auto", marginBottom:"auto"}}>
                  <div>{docThreeName}</div>
                  {docThreePercentage > 0 && (
                    <div className="uploading-image-progressbar">
                      { docThreePercentage > 0 && docThreePercentage < 100
                          ? <ProgressBar now={docThreePercentage} />
                          : ""}
                    </div>
                  )}
                  <div style={{display:"flex"}}>
                    {!isRequestDocThree && (
                        <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex", marginBottom:"10px"}}>
                          <input type="file" name="file-3" id="icon-button-file-reg-3" style={{ display: 'none' }} onChange={handleSubmitDocThree}/>
                          <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-reg-3">Upload</label>
                        </div>
                      )}
                      {isRequestDocThree && (
                        <div className="download-report-div create-btn-div" column sm="2" style={{textAlign:"left", display:"flex", marginBottom:"10px"}}>
                          <input type="button" name="file-remove-3" id="icon-button-file-remove-reg-3" style={{ display: 'none' }} onClick={handleRemoveSubmitDocThree}/>
                          <label style={{cursor:"pointer", color:"#4389ff", textDecoration:"underline", margin:"auto", lineHeight:"16px"}} htmlFor="icon-button-file-remove-reg-3">Remove</label>
                        </div>
                      )}
                  </div>
                  <div id="letter-three-error" style={{color:"#e04d5b", fontSize:".875em"}}>{state.letterThreeErr}</div>
                </Col>
              </Form.Group>
            </Form>
            <Row className="">
                <Form.Label column sm="2">
                </Form.Label>
                <Col sm="5">
                  <button
                    type="submit"
                    className="btn-submit mt-3 save-btn"
                    onClick={handleSubmit}
                    style={{width: "200px"}}
                  >
                    {loading ? (
                      <div
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                      >
                          <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {loading ? "" : "Request Verification"}
                  </button>
                </Col>
            </Row>
          </Form>
        </Row>
        :
        <Row className="m-0 cus-content-div">
          <Form style={{paddingLeft: '0', paddingRight: '0'}}>
            <div className="main-header-div">
              <div className="header-div">
                <h5 className="sec-heading2">Add new user verification request</h5>
              </div>
              <hr className="live-hr" />
              <div style={{background:"#e3f2fd", fontSize:"14px", padding:"10px", width:"65%", marginTop:"20px", marginBottom:"20px", textAlign:"left"}}>You have a pending user verification request.</div>
            </div>
            <Row className="">
              <Form.Label column sm="2">
              </Form.Label>
              <Col sm="5">
                <button
                  type="submit"
                  className="btn-submit mt-3 save-btn"
                  onClick={()=> history.replace("/new-campaign")}
                  style={{width: "200px"}}
                >
                  Go back
                </button>
              </Col>
            </Row>
          </Form>
        </Row>
      }
      </Container>
    </>      
  );
}

export default withRouter(RequestSendeIdComp);
