import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { MdDoneAll } from "react-icons/md";
import Close from "../../images/close.png";

const ConfirmCampaignModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // className="confirm-action-dialog"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">Send SMS</Modal.Title>
        <img
          src={Close}
          alt="close"
          onClick={() => props.callback(false)}
          style={{ cursor: "pointer"}}
          className="modal-close-btn"
        />
      </Modal.Header>
      <Modal.Body style={{ background: "white", paddingTop: "0" }}>
        <hr className="live-hr modal-hr" />
        <div className="dialog">
          <div style={{textAlign: "left", fontSize: "14px", marginTop: "8px", marginBottom: "8px"}} className="confirm-dialog-meg">{props.message}</div>
          <div>
            <Table
              className="custom-table"
              responsive
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th align="center">Number</th>
                  <th align="center">From</th>
                  <th align="center">Send Time</th>
                  <th align="center">Message</th>
                  <th align="center">Status</th>
                </tr>
              </thead>
              <tbody>
                {props.numbers.length > 0 &&
                  (props.numbers).map((el, i) => (
                    <tr>
                      <td>{el.id}</td>
                      <td>{props.sender}</td>
                      <td>{props.sendAt}</td>
                      <td>{props.content}</td>
                      <td>
                        {el.state == "Sent" && <i title="Sent" className="far fa-check-circle feature-icon" style={{color: "green"}}></i>}
                        {el.state == "Failed" && <i title="Failed" className="far fa-times-circle feature-icon" style={{color: "red"}}></i>}
                        {el.state == "Pending" && <i title="Pending" className="far fa-question-circle feature-icon" style={{color: "black"}}></i>}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
          {(props.done) ?
            <div className="action-buttons-center" style={{textAlign: "right", marginTop: "20px"}}>
              <Button
                variant="outline-secondary"
                onClick={props.onHide}
                style={{color: "#333333"}}
              >
                Close
              </Button>
            </div>
            :
            <div className="action-buttons-center" style={{textAlign: "right", marginTop: "20px"}}>
              <Button
                variant="outline-secondary"
                onClick={() => props.callback(false)}
                style={{color: "#333333"}}
              >
                Make Changes
              </Button>
              <Button variant="primary" onClick={() => props.callback(true)}>
                {props.confirmBtnName}
              </Button>
            </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmCampaignModal;
