import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import classnames from "classnames";
import qs from "qs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Close from "../../images/close.png";

import { addContact } from "../../api/contactAPI";

const initialState = {
  first_name: "",
  last_name: "",
  number: "",
  first_nameErr: "",
  numberErr: "",
};

function AddNewContactModal({ showAddContactModal, setAddContactModal, fetchContacts }) {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  const handlePhone = (phone) => {
    setState({
      ...state,
      number: phone,
      numberErr: "",
    });
  };

  // validate
  const validate = () => {
    let first_nameErr = "";
    let numberErr = "";

    if (!state.first_name) {
      first_nameErr = "First name is required";
    } else if (state.first_name.length < 2) {
      first_nameErr = "First name is too short";
    } else if (!state.first_name.match(/^\D+$/)) {
      first_nameErr = "First name is Invalid";
    }

    if (!state.number) {
      numberErr = "Contact number is required";
    }

    if (first_nameErr || numberErr) {
      setState({
        ...state,
        first_nameErr,
        numberErr,
      });

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        first_name: state.first_name,
        last_name: state.last_name,
        number: state.number,
      });

        addContact(
          data,
          (data) => {
            setLoading(false);
            fetchContacts();
            setState(initialState);
            setAddContactModal(false);
          },
          (err) => {
            setLoading(false);
          }
        );
    }
  };

  const handleClose = () => {
    setState(initialState);
    setAddContactModal(false);
  };

  return (
    <Modal
      show={showAddContactModal}
      onHide={() => setAddContactModal(false)}
      centered
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">Add New Contact</Modal.Title>
        <img
          src={Close}
          alt="close"
          onClick={handleClose}
          style={{ cursor: "pointer"}}
          className="modal-close-btn"
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <hr className="live-hr modal-hr" />
        <Form>
          <Form.Group className="p-0 mb-3" as={Row}>
            <Form.Label column sm="4">First name</Form.Label>
            <Col sm="7">
              <Form.Control
                type="text"
                className={classnames("modal-input", "form-control", {
                  "is-invalid": state.first_nameErr,
                })}
                name="first_name"
                value={state.first_name}
                onChange={handleChange}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {state.first_nameErr}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group className="p-0 mb-3" as={Row}>
            <Form.Label column sm="4">Last name</Form.Label>
            <Col sm="7">
              <Form.Control
                type="text"
                className={classnames("modal-input", "form-control")}
                name="last_name"
                value={state.last_name}
                onChange={handleChange}
                autoComplete="off"
              />
            </Col>
          </Form.Group>
          <Form.Group className="p-0 mb-3" as={Row}>
            <Form.Label column sm="4">Contact Number</Form.Label>
            <Col sm="7">
              <div
                className={
                  state.numberErr
                    ? "input-group cus-react-tel-input-div"
                    : "input-group"
                }
              >
                <PhoneInput
                  country="lk"
                  // disableDropdown={true}
                  value={state.number}
                  enableSearch={true}
                  disableSearchIcon={true}
                  countryCodeEditable={false}
                  prefix="+"
                  onChange={(phone) => handlePhone(phone)}
                  inputStyle={{ background: '#f0f0f0'}}
                />
              </div>
              <p className="cus-error">{state.numberErr}</p>
            </Col>
          </Form.Group>
          <Row className="">
            <Form.Label column sm="4">
            </Form.Label>
            <Col sm="7" style={{display:"flex", justifyContent: "right"}}>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mt-3"
                onClick={handleClose}
                style={{ width: 90, color: "#333333" }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-submit mt-3"
                onClick={handleSubmit}
                style={{width: "33%", marginLeft: "5%"}}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {loading ? "" : "Save"}
              </button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddNewContactModal;
