import React from 'react'

const SettingsMenu = (props) => {
  return (
    <>
    <div className="billing-menu-div">
      <div className="billing-menu-wrapper">
        <h6 style={{marginLeft: "10px"}}>{`Project Settings`}</h6>
        <hr />
        <button
          type="button"
          className= {props.active === 'overview' ? "selected-nav-biling-btn" : 'nav-account-btn'}
          onClick={() =>
            props.data('overview')
          }
        >
          Overview
        </button>
        <button
          type="button"
          className= {props.active === 'usageLimits' ? "selected-nav-biling-btn" : 'nav-account-btn'}
          onClick={() =>
            props.data('usageLimits')
          }
        >
          Usage Limits
        </button>
          <button
          type="button"
          className= {props.active === 'geoPermissions' ? "selected-nav-biling-btn" : 'nav-account-btn'}
          onClick={() =>
            props.data('geoPermissions')
          }
        >
          Geo Permissions
          </button>
      </div>
    </div>
  </>
  )
}

export default SettingsMenu;