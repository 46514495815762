import React, { useState, useContext, useEffect, useCallback } from "react";
import LineChart from './LineChart';

function Graph(props) {
  return (
    <div className="main-graph">
      <LineChart title={props.title} data1={props.data1} data2={props.data2} labels={props.labels} prevLabels={props.prevLabels} isCurrency={props.isCurrency} />
    </div>
  );
}

export default Graph;