import React from "react";
import DashboardLayout from "../components/layouts/DashboardLayout";
import NewCampaignComp from "../components/campaigns/NewCampaignComp";
import PageTitle from "../components/common/PageTitle";

function NewCampaign() {
  return (
    <DashboardLayout>
      <PageTitle page="New Campaign"/>
      <NewCampaignComp />
    </DashboardLayout>
  );
}

export default NewCampaign;