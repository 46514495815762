import React from "react";
import { Container } from "react-bootstrap";

import Navbar from "../common/Navigation";

function ProfileLayout(props) {
  return (
    <Container fluid className="p-0">
      <Navbar />
      <Container fluid className="px-0 py-5" style={{height: "85vh", overflowY: "scroll"}}>
        {props.children}
      </Container>
    </Container>
  );
}

export default ProfileLayout;
