import axios from "axios";
import qs from "qs";
import { getCSRFToken, getTeamId } from "../auth/auth";

export const registerAccount = (data, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/register`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const loginSession = (data, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/login`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken()
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const logoutSession = () => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/logout`,
      {},
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      document.cookie =
        "auth_t=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("csrf_t");
      localStorage.removeItem("selected_project");
      localStorage.removeItem("region");
      window.location.href = "/";
    })
    .catch((err) => {
      console.log(err);
    });
};


export const refreshCSRF = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/generic/refresh_csrf`, {
      withCredentials: true,
    })
    .then((res) => {
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
      ) {
        localStorage.setItem("csrf_t", res.data.id);
      }

      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getUserProfileAPI = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/profile`, {
      withCredentials: true,
      headers: {
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getUserSessionsAPI = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/sessions`, {
      withCredentials: true,
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const resetPassword = (data, onSuccess, onError) => {  
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/reset_password`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const requestResetPassword = (data, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/request_reset_password`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const deleteOtherSessionsAPI = (onSuccess, onError) => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/v1/web/sessions/clear_inactive`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const requestUserVerificationAPI = (data, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/verification_requests`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/JSON",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getPendingUserVerificationRequests = ( onSuccess, onError ) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/verification_requests`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const requestVerificationEmailAPI = (data, onSuccess, onError) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/request_activation`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};