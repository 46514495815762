import React from "react";
import DashboardLayout from "../components/layouts/DashboardLayout";
import CampaignsComp from "../components/campaigns/CampaignsComp";
import PageTitle from "../components/common/PageTitle";

function Campaigns() {
  return (
    <DashboardLayout>
      <PageTitle page="Campaigns" />
      <CampaignsComp />
    </DashboardLayout>
  );
}

export default Campaigns;