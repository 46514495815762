import React, { useState, useEffect, useRef, useContext } from "react";
import { Navbar, Nav, Container, Button, Spinner, Dropdown, Alert } from "react-bootstrap";
// import Avatar from "react-avatar";
import { Avatar } from "@material-ui/core";
import { Select } from "react-select-tile";
import "../../styles/main.css";
import Logo from "../../images/logo_white.png";
import { NavLink, useParams, useRouteMatch, useHistory } from "react-router-dom";
import { getAllProjectsAPI } from "../../api/ProjectAPI";
import { MdAdd } from "react-icons/md";
import CreateProjectModal from "./CreateProjectModal";
import { logoutSession, getUserProfileAPI } from "../../api/authAPI";
import { getTeamsAPI } from "../../api/teamAPI";
import { ProfileContext, ProjectContext, SelectedProjectContext } from "../context/ContextProvider";
import { HiOutlinePlusSm } from "react-icons/hi";

const containerStyle = {
  background: "transparent",
  border: "none",
};

const inputStyle = {
  background: "transparent",
  color: "#ffffff",
  fontSize: "1.5rem",
  fontWeight: "600",
  paddingRight: ".5rem",
  paddingLeft: "1.3rem",
};

const menuStyle = {
  background: "#1d2125",
  border: "none",
  overflowY: "auto",
  maxHeight: "400px",
  marginTop: "8px",
  gridTemplateColumns: "repeat( 2, 1fr )",
};

const menuItemStyle = {
  borderRadius: "3px",
  textAlign: "center",
  fontWeight: "600",
};

const avatarStyles = {
  cursor: "pointer",
  "&:hover": {
    border: "2px solid rgba(60,64,67,0.08)",
  },
};

const Navigation = () => {
  const [value, setValue] = useState("");
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [createNewProjectInfo, setCreateNewProjectInfo] = useState("");
  const [isIdVerified, setIsIdVerified] = useState(true);

  const { projects, setProjects } = useContext(ProjectContext);
  const { userProfile, setUserProfile } = useContext(ProfileContext);
  const { selectedProject, setSelectedProject } = useContext(SelectedProjectContext);

  const { id: paramId } = useParams();
  let matchUrl = useRouteMatch(`/settings/${paramId}`);
  const history = useHistory();
  const popupRef = useRef();

  useEffect(() => {
    let bodyDiv = document.getElementsByClassName("main-body");
    bodyDiv[0].style.backgroundColor = "#d9effb";
    if (userProfile && userProfile.length !== 0) {
      setIsIdVerified(userProfile.id_verified);
      if (!userProfile.id_verified) {
        localStorage.setItem("mode", "TEST");
      }
    }
  });

  useEffect(() => {
    if (!localStorage.getItem("team")) {
      getTeamsAPI(
        (data) => {
          localStorage.setItem("team", JSON.stringify(data[0]));
        },
        (err) => {
          console.log(err);
        }
      );
    }
  })

  useEffect(() => {
    if (value === "" && selectedProject !== "") {
      setValue(selectedProject);
    }
  }, []);

  useEffect(() => {
    const found = projects.find((element) => element.id == selectedProject);
    if (found) {
      setSelectedProjectName(found.name);
    }
  }, [selectedProject, projects]);

  useEffect(() => {
    if (!projects.length) {
      handleGetAllProjects()
    }
  }, []);

  useEffect(() => {
    if (!userProfile.email)
      getUserProfileAPI(
        (data) => {
          setIsIdVerified(data.id_verified);
          setUserProfile(data);
          // localStorage.setItem("currency", data.region.currency_symbol);
          // localStorage.setItem("display_balance", data.display_balance);
          // localStorage.setItem("region", data.region.id);
        },
        (error) => {}
      );
  }, [userProfile.email]);

  const handleGetAllProjects = () => {
    getAllProjectsAPI(
      (data) => {
        let tempData = data;
        tempData.forEach((project) => {
          project["label"] = project.name;
          project["value"] = project.id;
        });
        setProjects(tempData);
        setIsProjectsLoading(false);
        if (value === "") {
          if (localStorage.getItem("selected_project") !== null && localStorage.getItem("selected_project") !== "") {
            const project = localStorage.getItem("selected_project");
            setValue(project);
            setSelectedProject(project);
            if (matchUrl !== null) {
              history.push({
                pathname: "/settings/" + project,
              });
            }
          } else {
            const firstProject = data[0].id;
            setValue(firstProject);
            setSelectedProject(firstProject);
            localStorage.setItem("selected_project", firstProject);
            if (matchUrl !== null) {
              history.push({
                pathname: "/settings/" + firstProject,
              });
            }
          }
        } else if (value !== selectedProject) {
          setValue(selectedProject);
          localStorage.setItem("selected_project", selectedProject);
          if (matchUrl !== null) {
            history.push({
              pathname: "/settings/" + selectedProject,
            });
          }
        }
      },
      (error) => {
        console.log(error);
        setIsProjectsLoading(false);
      }
    );
  };

  const handleSelectProject = (value) => {
    if (value == "add-new") {
      setCreateNewProjectInfo({ title: "Create a Project" });
      setShowCreateProjectModal(true);
    } else {
      setCreateNewProjectInfo("");
      setValue(value);
      setSelectedProject(value);
      localStorage.setItem("selected_project", value);
      if (matchUrl !== null) {
        history.push({
          pathname: "/settings/" + value,
        });
      } else {
        window.location.reload();
      }
    }
  };

  const handleCreateNewProject = () => {
    setCreateNewProjectInfo({
      title: "Create a Project",
      message: "Create a new project to start sending SMSs. You can create multiple projects under this billing account and they can be managed separately from the dashboard.",
    });
    setShowCreateProjectModal(true);
  };

  const handleCloseCreateProjectModal = () => {
    setShowCreateProjectModal(false);
  };

  const handleUserAccountPopup = () => {
    setShowAccountInfo(true);
  };

  const handleClickOutside = (e) => {
    if (!popupRef.current?.contains(e.target)) {
      setShowAccountInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div className="nav-bar-main-div">
      <Navbar bg="dark" variant="dark" className="main-nav" expand="md">
        <Container style={{ paddingLeft: "0px" }} className="main-nav-container">
          <div className="nav-icon-project-select-wrapper">
            <div className="nav-logo">
              <img src={Logo} alt="sms160 logo" onClick={() => history.replace("/home")} style={{ cursor: "pointer" }} />
            </div>
            <div className="nav-icon-project-select-box">
              {projects.length ? (
                <Dropdown>
                  <Dropdown.Toggle variant="outline" id="dropdown-basic" size="sm">
                    {selectedProjectName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {projects &&
                      projects.map((el, i) => (
                        <Dropdown.Item as="button" onClick={() => handleSelectProject(el.id)} className={el.id == selectedProject ? "selected-item" : "un-selected-item"}>
                          {el.name}
                        </Dropdown.Item>
                      ))}
                    <Dropdown.Item as="button" onClick={() => handleSelectProject("add-new")} className={"un-selected-item"}>
                      Create New Project
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div className="no-projects">
                  {isProjectsLoading ? (
                    <div className="projects-loading-spinner">
                      <Spinner animation="border" variant="secondary" size="sm" />
                    </div>
                  ) : (
                    <Button variant="primary" className="new-project-button" onClick={() => handleSelectProject("add-new")}>
                      <HiOutlinePlusSm />
                      Create New Project
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="main-nav-links-wrapper">
            <div className="main-nav-links">
              <Nav className="mr-auto">
                <NavLink to="/api" className="nav-link" activeClassName="active-nav-link">
                  API
                </NavLink>
                {/* <NavLink
                  to="/about"
                  className="nav-link"
                  activeClassName="active-nav-link"
                >
                  Support
                </NavLink> */}
              </Nav>
            </div>
            <div className="main-nav-avatar" ref={popupRef}>
              {/* <Avatar
                name={userProfile.first_name}
                round={true}
                size="40"
                textSizeRatio={1.6}
                style={avatarStyles}
                onClick={handleUserAccountPopup}
              /> */}
              {userProfile.first_name ? (
                <Avatar style={{ background: "#0163ae", cursor: "pointer" }} onClick={handleUserAccountPopup}>
                  {userProfile.first_name[0]}
                </Avatar>
              ) : (
                <Avatar style={{ bgcolor: "red" }}></Avatar>
              )}
              {showAccountInfo ? (
                <div className="user-account-info-popup">
                  <div className="user-info-wrapper">
                    <h6>{localStorage.getItem("team") ? JSON.parse(localStorage.getItem("team")).name : `${userProfile.first_name} ${userProfile.last_name}`}</h6>
                    <p>{userProfile.email}</p>
                    {userProfile.id_verified ? <span className="user-active-state">(Verified)</span> : <span className="user-active-state state-not-verified">(Not Verified)</span>}
                    {!userProfile.id_verified && (
                      <Button variant="link" onClick={() => history.push("/verify")}>
                        Verify now
                      </Button>
                    )}
                    <hr />                    
                  </div>
                  <div style={{marginLeft: "-16px", marginRight: "-16px"}}>
                    <button
                      type="button"
                      className="nav-account-btn"
                      onClick={() =>
                        history.push({
                          pathname: "/profile",
                        })
                      }
                    >
                      Manage Account
                    </button>
                    <button
                      type="button"
                      className="nav-account-btn"
                      onClick={() =>
                        history.push({
                          pathname: "/billing",
                        })
                      }
                    >
                      Billing
                    </button>
                    <button type="button" className="nav-account-btn" onClick={() => logoutSession()}>
                      Logout
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Container>
      </Navbar>
      <hr className="nav-hr" />
      <Navbar bg="dark" variant="dark" className="second-nav" expand="md">
        <Container style={{ paddingLeft: "var(--bs-gutter-x,.25rem)" }}>
          <Nav className="mr-auto">
            <NavLink to="/home" className="nav-link" activeClassName="active-nav-link" style={{ paddingLeft: "0px" }}>
              Home
            </NavLink>
            {projects.length > 0 ? (
              <NavLink to="/logs" className="nav-link" activeClassName="active-nav-link">
                Logs
              </NavLink>
            ) : (
              <a className="nav-link" onClick={handleCreateNewProject}>
                Logs
              </a>
            )}
            {projects.length > 0 ? (
              <NavLink to="/campaigns" className="nav-link" activeClassName="active-nav-link">
                Campaigns
              </NavLink>
            ) : (
              <a className="nav-link" onClick={handleCreateNewProject}>
                Campaigns
              </a>
            )}
            <NavLink to="/contacts" className="nav-link" activeClassName="active-nav-link">
              Contacts
            </NavLink>
            {projects.length ? (
              <NavLink to={`/settings/${value}`} className="nav-link" activeClassName="active-nav-link">
                Settings
              </NavLink>
            ) : null}
          </Nav>
        </Container>
      </Navbar>
      {!isIdVerified &&
        <div className="id-verify-alert">
          <div style={{fontSize: '16px', fontWeight: '500'}} className="id-verify-message">Account not Verified</div>
          <div style={{fontSize: '14px'}} className="id-verify-message">Your identity needs to be verified before you can start sending SMS. You can upload required documents and get verified <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => history.push("/verify")}>here</span>.</div>
        </div>
      }
      <CreateProjectModal
        newProjectInfo={createNewProjectInfo}
        showCreateProject={showCreateProjectModal}
        handleCloseCreateProjectModal={handleCloseCreateProjectModal}
        handleGetAllProjects={handleGetAllProjects}
      />
    </div>
  );
};

export default Navigation;
