import React from "react";
import { Modal, Button } from "react-bootstrap";

import Close from "../../images/close.png";

function DeleteModal({
  deleteModal,
  setDeleteModal,
  handleMultiDelete,
  multiDelLoading,
}) {
  return (
    <Modal show={deleteModal} onHide={() => handleMultiDelete(false)} centered>
      <Modal.Header>
        <Modal.Title>Delete Contacts</Modal.Title>

        <img
          src={Close}
          alt="close"
          width="25"
          onClick={() => setDeleteModal(false)}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p>Do you want to delete the selected contacts?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setDeleteModal(false)}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleMultiDelete}
          style={{ width: 100 }}
        >
          {multiDelLoading ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            "Delete All"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
