import React, { useState, useContext, useEffect } from "react";
import Increase from "../../images/indicators/increase.png";
import Decrease from "../../images/indicators/decrease.png";
import { getTeamAPI } from "../../api/teamAPI";

function PerfCard({ title, count, isCurrency, prevCount }) {
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency")
      ? localStorage.getItem("currency")
      : ""
  );
  const [diff, setDiff] = useState(0);
  const [color, setColor] = useState("");

  useEffect(() => {
    if (!currency) {
      getTeamAPI(
        (data) => {
          setCurrency(data[0].region.currency_symbol);
          localStorage.setItem("currency", data[0].region.currency_symbol);
          localStorage.setItem("display_balance", data[0].display_balance);
        },
        (error) => {}
      );
    }
  }, []);

  useEffect(() => {
    let roughDiff = (((count - prevCount)/prevCount)*100);
    console.log(roughDiff)
    let diff = 0;
    let color = '#4F4F4F';
    if (roughDiff === Infinity) {
      diff = 100;
      color = '#035C29';
    } else if (roughDiff === NaN) {
      diff = 0;
      color = '#4F4F4F';
    } else {
      if (parseInt(roughDiff) > 0) {
        diff = roughDiff;
        color = '#035C29';
      } else if (parseInt(roughDiff) < 0) {
        diff = roughDiff;
        color = '#810000';
      } else {
        diff = 0;
        color = '#4F4F4F';
      }
    }
    setDiff(diff);
    setColor(color);
  }, []);

  return (
    <div className="dash-perf-card">
      <p className="title">{title}</p>
      <p className="count">
      {isCurrency && currency} {count}
      </p>
      <p className="pecentage">
        {diff === 0 &&
            <span style={{color: '#4F4F4F', background: '#BDBDBD', borderRadius: '5px', padding: '3px', fontWeight: 'normal', fontSize: '14px'}}>{diff.toFixed(1)}%</span>
          }
          {diff > 0 &&
            (title == 'Total Undelivered' ?
            <span style={{color: '#810000', background: '#e69c9c', borderRadius: '5px', padding: '3px', fontWeight: 'normal', fontSize: '14px'}}>+{diff.toFixed(1)}%</span>
            :
            <span style={{color: '#035C29', background: '#a8d7bb', borderRadius: '5px', padding: '3px', fontWeight: 'normal', fontSize: '14px'}}>+{diff.toFixed(1)}%</span>
          )}
          {diff < 0 &&
          (title == "Total Undelivered" ?
            <span style={{color: '#035C29', background: '#a8d7bb', borderRadius: '5px', padding: '3px', fontWeight: 'normal', fontSize: '14px'}}>{diff.toFixed(1)}%</span>
            :
            <span style={{color: '#810000', background: '#e69c9c', borderRadius: '5px', padding: '3px', fontWeight: 'normal', fontSize: '14px'}}>{diff.toFixed(1)}%</span>
          )}
      </p>
    </div>
  );
}

export default PerfCard;