import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import { Container, Row, Col, Form, } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

function PendingSendeIdComp(props) {
  const [id, setId] = useState("");
  const [date, setDate] = useState("");
  const [documents, setDocuments] = useState([]);
  const [proId, setProId] = useState(
    localStorage.getItem("selected_project")
      ? localStorage.getItem("selected_project")
      : ""
  );

  useEffect(() => {
    if (props.pendingObj.sender_id || props.pendingObj.created_at) {
      setId(props.pendingObj.sender_id);
      setDate((props.pendingObj.created_at).split("T")[0]);
      setDocuments(props.pendingObj.documents);
    };
  }, [props.pendingObj]);

  return (
    <>
      <Container>
      <Link to={"/settings/"+proId} className="dash-back-btn">
        <i className="fas fa-angle-left"></i> Back
      </Link>
        <Row className="m-0 cus-content-div">
          <Form style={{paddingLeft: '0', paddingRight: '0'}}>
            <div className="main-header-div">
              <div className="header-div">
                <h5 className="sec-heading2">Pending Sender ID Request</h5>
              </div>
              <hr className="live-hr" />
              <div style={{background:"#e3f2fd", fontSize:"14px", padding:"10px", width:"65%", marginTop:"20px", marginBottom:"20px", textAlign:"left"}}>There is a pending sender id request for this project. Pending requests need to be approved before a new request can be made. Please contact support@sms160.io for further support.</div>
            </div>
            <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Sender ID
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    type="text"
                    className={classnames("custom-form-control")}
                    name="name"
                    value={id}
                    autoComplete="off"
                    readOnly="readOnly"
                  />
                </Col>
              </Form.Group>
            </Form>
            <Form style={{ marginTop: "35px", marginBottom:"35px"}}>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Requested On
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    type="text"
                    className={classnames("custom-form-control")}
                    name="name"
                    value={date}
                    autoComplete="off"
                    readOnly="readOnly"
                  />
                </Col>
              </Form.Group>
            </Form>
            <h6 className="settings-subtitle">Uploaded Documents</h6>
            <hr className="live-hr" />
            <Form>
              <Form.Group as={Row} style={{ marginTop: "18px" }}>
                <Form.Label column sm="2">
                </Form.Label>
                <Col sm="5">
                  {documents &&
                    documents.length > 0 &&
                      documents.map((document) => (
                        <div>{document.name}</div>
                      )
                    )
                  }
                </Col>
              </Form.Group>
            </Form>
          </Form>
        </Row>
      </Container>
    </>
  );
}

export default withRouter(PendingSendeIdComp);
