import React from "react";
import "../../styles/api.css";
import {useHistory} from "react-router-dom";


function ApiMenu(props) {
  const history = useHistory()

  const showContent = (type) => {
    // if (document.getElementById(type).style.display !== "none") {
    //   document.getElementById(type).style.display = "none";
    // } else {
    //   document.getElementById(type).style.display = "block";
    // }
  };

  const showApi = (e, api) => {
    props.data(api);
    var elements = document.getElementsByClassName("active-menu");
    while(elements.length > 0){
      elements[0].classList.remove('active-menu');
    }
    document.getElementById(e.target.id).classList.add("active-menu");
  }

  const goBack = () => {
    let bodyDiv = document.getElementsByClassName("main-body");
    bodyDiv[0].style.backgroundColor = "#d9effb";
    history.replace("/home")
  }

  return (
    <>
      <div className="api-menu-div" id="api-menu-div">
        <div className="api-menu-wrapper">
          <h6>{`API Reference`}</h6>
          <button
            type="button"
            className="nav-account-btn api-title"
            onClick={()=> goBack()}
          >
            <i className="fas fa-arrow-left"></i>&nbsp;Back to Dashboard 
          </button>
          <button
            type="button"
            id="introduction-btn"
            className="nav-account-btn api-title"
            onClick={(e) => showApi(e, 'introduction')}
          >
            Introduction
          </button>
          <button
            type="button"
            className="nav-account-btn api-title"
            id="authentication-btn"
            onClick={(e) => showApi(e, 'authentication')}
          >
            Authentication
          </button>
          <button
            type="button"
            className="nav-account-btn api-title"
            id="test-mode-btn"
            onClick={(e) => showApi(e, 'test-mode')}
          >
            Test Mode
          </button>
          <button
            id="error-codes-btn"
            type="button"
            className="nav-account-btn api-title"
            onClick={(e) => showApi(e, 'error-codes')}
          >
            Error Codes
          </button>


          <button
            type="button"
            id="send-sms-parent-btn"
            className="nav-account-btn api-title"
            onClick={(e) => showApi(e, 'send-sms')}
          >
            Messages
          </button>

          <button
            type="button"
            id="send-sms-btn"
            className="nav-account-btn api-title api-sub-title"
            onClick={(e) => showApi(e, 'send-sms')}
            style={{marginLeft: "15px"}}
          >
            Send an SMS
          </button>
          <button
            type="button"
            id="get-sms-details-btn"
            className="nav-account-btn api-sub-title api-title"
            onClick={(e) => showApi(e, 'get-sms-details')}
            style={{marginLeft: "15px"}}
          >
            Get SMS Details
          </button>
          <button
            type="button"
            id="get-sms-log-btn"
            className="nav-account-btn api-sub-title api-title"
            onClick={(e) => showApi(e, 'get-sms-log')}
            style={{marginLeft: "15px"}}
          >
            Get SMS Log
          </button>

          {/* <button
            type="button"
            className="nav-account-btn api-title"
            onClick={() => showContent('user')}
          >
            User APIs &nbsp;<i className="fas fa-angle-down"></i>
          </button>
         <div className="api-menu-sub-div user-list" id="user" style={{display: "none"}}>
         <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() => showApi('user-register')}
          >
            Register
          </button>
          <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() => showApi('login')}
          >
            Login
          </button>
          <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() => showApi('logout')}
          >
            Logout
          </button>
         </div> */}


         {/* <button
            type="button"
            className="nav-account-btn api-title"
            onClick={() => showContent('campaign')}
          >
            Campaigns APIs
          </button>
         <div className="api-menu-sub-div user-list" id="campaign" style={{display: "none"}}>
         <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() => showApi('create-campaign')}
          >
            Create Campaign
          </button>
          <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() => showApi('get-campaigns')}
          >
            Get Campaigns
          </button>
          <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() => showApi('get-campaign-details')}
          >
            Get Campaign Details
          </button>
         </div> */}


         {/* <button
            type="button"
            className="nav-account-btn api-title"
            onClick={() => showContent('generic')}
          >
            Generic APIs
          </button>
         <div className="api-menu-sub-div user-list" id="generic" style={{display: "none"}}>
         <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() =>
              props.data('refresh-token')
            }
          >
            Refresh Token
          </button>
          <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() =>
              props.data('get-time-zones')
            }
          >
            Get Time Zones
          </button>
         </div> */}


         {/* <button
            type="button"
            className="nav-account-btn api-title"
            onClick={() => showContent('messages')}
          >
            Messages
          </button>
         <div className="api-menu-sub-div user-list" id="messages" style={{display: "none"}}>
         <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() =>
              props.data('send-sms')
            }
          >
            Send an SMS
          </button>
          <button
            type="button"
            className="nav-account-btn sub-api-title"
            onClick={() =>
              props.data('get-sms-details')
            }
          >
            Get SMS Details
          </button>
         </div>         */}
        </div>
      </div>
    </>
  );
}

export default ApiMenu;