import React from "react";
import { withRouter } from "react-router-dom";
import { Col } from "react-bootstrap";

import Logo from "../../images/logo_white.png";

function RegisterComp(props) {
  return (
    <Col xs={12} md="6" className="logo-col">
      <div className="logo-back-div">
        <img src={Logo} className="logo" alt="sms160" style={{ width: 250 }} />

        {/* <h3 className="heading">
          Get started with <b>SMS160</b> for free. No credit card required.
        </h3>

        <table className="feature-table">
          <tbody>
            <tr>
              <td width="40">
                <i className="far fa-check-circle feature-icon"></i>
              </td>
              <td>No lock in contract - pay as you go</td>
            </tr>
            <tr>
              <td>
                <i className="far fa-check-circle feature-icon"></i>
              </td>
              <td>Send SMS</td>
            </tr>
            <tr>
              <td>
                <i className="far fa-check-circle feature-icon"></i>
              </td>
              <td>Manage contacts</td>
            </tr>
            <tr>
              <td>
                <i className="far fa-check-circle feature-icon"></i>
              </td>
              <td>Create marketing campaigns</td>
            </tr>
            <tr>
              <td>
                <i className="far fa-check-circle feature-icon"></i>
              </td>
              <td>Phone number verification</td>
            </tr>
          </tbody>
        </table>
       */}
      </div>
    </Col>
  );
}

export default withRouter(RegisterComp);
