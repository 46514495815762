import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Accordion, AccordionSummary, AccordionDetails, FormControlLabel, FormGroup, makeStyles, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';
import { updateBlockedCodes } from '../../api/ProjectAPI';
import QueryString from 'qs';
import PropTypes from 'prop-types';
import { flattenDeep, pull } from 'lodash'

const useStyle = makeStyles({
  accordianRoot: {
    height: '25px',
    backgroundColor: '#303030'
  },
  labelcolor: {
    color: 'white'
  },
  FormGroup: {
    marginLeft: '25px'
  },
});

const GeoPermissionComp = ({ projectId, blockedCodes, regionList, getProjectDetails }) => {
  const classes = useStyle();
  const [filterText, setFilterText] = useState("");
  const [regions, setRegions] = useState([]);
  const [filterResult, setFilterResult] = useState([]);
  const [countrySum, setCountrySum] = useState(0);
  const [blockedCountries, setBlockedCountries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showSelectAll, setShowSelectAll] = useState(true);

  GeoPermissionComp.propTypes = {
    projectId: PropTypes.string.isRequired,
    blockedCodes: PropTypes.array,
    regionList: PropTypes.array.isRequired,
    getProjectDetails: PropTypes.func.isRequired
  }

  useEffect(() => {  
    setBlockedCountries(Boolean(blockedCodes) ? blockedCodes : []);
    setSelectAll(blockedCodes?.length === 0);
  }, [blockedCodes])

  useEffect(() => {
    setRegions(Boolean(regionList) ? regionList : []);
    setFilterResult(Boolean(regionList) ? regionList : []);
    const countryCount = regionList.reduce((sum, region) => sum + region.countries.length, 0);
    setCountrySum(countryCount);
  }, [])

  useEffect(() => {
    if (!filterText) {
      setFilterResult(regions);
      setShowSelectAll(true)
    }
  }, [filterText, regions])

  const handleSave = (blockCountries) => {
    const data = QueryString.stringify({
      codes: blockCountries.join(','),
      clear_codes: false
    })

    updateBlockedCodes(
      projectId,
      data,
      (data) => {
        getProjectDetails();
      },
      (error) => {
        console.log(error);
        toast.error("Error occurred!");
      }
    )
  }

  const handleFilter = () => {
    if (filterText) {
      let resultRegion = [];
      resultRegion = regions.filter((region) => {
        return region.countries.some((country) => {
          return country.name.toLowerCase().includes(filterText.toLowerCase())
        })
      }
      )
      setShowSelectAll(false);
      setFilterResult(resultRegion);
    }
    else {
      setFilterResult(regions);
    }
  }

  const handleCheckedCountries = (e) => {
    const UpdatedList = [...blockedCountries];
    if (e.target.checked) {
      pull(UpdatedList, e.target.value);
    }
    else {
      UpdatedList.push(e.target.value)
    }
    setBlockedCountries(UpdatedList);
    setSelectAll(UpdatedList.length === 0)
    handleSave(UpdatedList);
  }

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked)
    const filterDialCodes = flattenDeep(filterResult?.map(item => item?.countries.map(item => item?.dialCode)))
    setBlockedCountries(!e.target.checked ? filterDialCodes : []);
    handleSave(e.target.checked ? [] : filterDialCodes);
  }

  return (
    <Container fluid>
      <Row className="m-0 cus-content-div">
        <Col className="p-0">
          <Row className='mb-3'>
            <Col>
              <h5 className="sec-heading2">Geo Permissions</h5>
              <hr className="live-hr" />
            </Col>
          </Row>
          <Row className='mb-5' style={{ padding: '0 20px', display: 'flex', justifyContent: 'space-between' }}>
            <Col md={8} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
              <Form style={{ width: '-webkit-fill-available' }}>
                <Form.Control
                  type="text"
                  placeholder='Filter by country name'
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="custom-form-control"
                />
              </Form>
              <Button variant="primary" size='sm' style={{ padding: '3px 4px', width: '25%' }} onClick={handleFilter}>Filter</Button>
            </Col>
            <Col md={3} style={{ fontSize: '0.8rem', display: 'flex', justifyContent: 'flex-end', alignItems: 'start' }}>Total Enabled: {countrySum - blockedCountries?.length}/{countrySum}</Col>
          </Row>
          <Row style={{ padding: '0 20px' }}>
            <FormGroup style={{ visibility: showSelectAll ? 'visible' : 'hidden' }}>
              <FormControlLabel control={<Checkbox color='primary' checked={selectAll} onChange={handleSelectAll} />} label="Select All" />
            </FormGroup>
          </Row>
          <Row className='m-0' style={{ padding: '0 20px' }}>
            <Col md={12} style={{ paddingLeft: 0 }}>
              {
                filterResult?.map((region, index) => (
                  <Accordion className='mb-1' key={index}>
                    <AccordionSummary
                      classes={{ root: classes.accordianRoot }}
                      expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header"
                    >
                      <Typography classes={{ root: classes.labelcolor }}>{region.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup className={classes.FormGroup}>
                        {
                          region?.countries?.map((country, index) => (
                            <FormControlLabel
                              key={index}
                              control={<Checkbox color='primary' checked={!blockedCountries.includes(country.dialCode)} value={country.dialCode} onChange={handleCheckedCountries} />}
                              label={country.name}
                            />
                          ))
                        }
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default GeoPermissionComp;