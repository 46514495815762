import React, { useState } from "react";
import ApiMenu from "./ApiMenu";
import ApiDocContentComp from "./ApiDocContentComp";
import "../../styles/api.css";

function ApiDoc(props) {
    const [selectedComponent, setSelectedComponent] = useState('introduction');
    const [valChanged, setValChanged] = useState(true);
    const changeViewApi = (name) => {
        setSelectedComponent(name);
        setValChanged(!valChanged);
    }

    return (
      <div className="api-main-div">
        <ApiMenu data={changeViewApi} />
        <ApiDocContentComp data={selectedComponent} valChanged={valChanged}/>
      </div>
    );
}

export default ApiDoc;