import React, { useState, useEffect } from "react";
import PaymentSuccessComp from "../components/billing/PaymentSuccessComp";
import PaymentFailedComp from "../components/billing/PaymentFailedComp";
import LoginLayout from "../components/layouts/SettingsLayout";
import {useHistory} from "react-router-dom";

function PaymentSuccess() {
  const history = useHistory();
  const [success, setSuccess] = useState("false");
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const status = urlParams.get('success');
    setSuccess(status);
  }, []);

  useEffect(() => {
    setTimeout(
      function() {
        history.replace("/billing");
      }, 3000);
  }, []);


  return (
    <LoginLayout>
      {(success == "true") ? <PaymentSuccessComp /> : <PaymentFailedComp />}
    </LoginLayout>
  );
}

export default PaymentSuccess;