import axios from "axios";
import qs from "qs";
import { getCSRFToken, getTeamId } from "../auth/auth";

export const getTeamsAPI = async (onSuccess, onError) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/teams`, {
      withCredentials: true,
    })
    .then((res) => {
      localStorage.setItem("team", JSON.stringify(res.data[0]));
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getTeamAPI = async (onSuccess, onError) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/teams`, {
      withCredentials: true,
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};