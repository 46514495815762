import React from "react";
import { Container } from "react-bootstrap";

import Navbar from "../common/ApiPageNavigation";

function DashboardLayout(props) {
  return (
    <Container fluid className="p-0">
      <Navbar />
      <Container fluid className="px-0 py-0">
        {props.children}
      </Container>
    </Container>
  );
}

export default DashboardLayout;