import axios from "axios";
import { getCSRFToken, getTeamId } from "../auth/auth";

export const uploadFileAPI = (uploadData, uploadProgress, onSuccess, onError) => {
  let config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);

      if (percent < 100) {
        uploadProgress(percent);
      }
    },
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-csrf-token": getCSRFToken(),
      "x-team-id": getTeamId(),
    },
  };
  axios
    .post(`${process.env.REACT_APP_API_URL}/v1/web/generic/file_upload`, uploadData, config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getRegions = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/generic/regions`)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
    });
};


export const getTimeZone = (onSuccess, onError) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/v1/web/generic/timezones`)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
    });
};
