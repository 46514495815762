import React from "react";
import ResetPasswordComp from "../components/forgot-password/ResetPasswordComp";
import LoginLayout from "../components/layouts/LoginLayout";

function ResetPassword() {
  return (
    <LoginLayout>
      <ResetPasswordComp />
    </LoginLayout>
  );
}

export default ResetPassword;
