import React from "react";
import ProfileLayout from "../components/layouts/ProfileLayout";
import ProfileComponent from "../components/profile/ProfileComponent";
import PageTitle from "../components/common/PageTitle";

function Profile() {
  return (
    <ProfileLayout>
      <PageTitle page="profile" />
      <ProfileComponent />
    </ProfileLayout>
  );
}

export default Profile;
