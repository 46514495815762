import React from "react";
import DashboardLayout from "../components/layouts/DashboardLayout";
import RequestVerificationComp from "../components/profile/RequestVerificationComp";

function RequestVerification() {
  return (
    <DashboardLayout>
      <RequestVerificationComp />
    </DashboardLayout>
  );
}

export default RequestVerification;