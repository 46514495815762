import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { Slider, withStyles } from '@material-ui/core';
import { UpdateProjectDailyLimit, getProjectDetailsAPI } from '../../api/ProjectAPI'; 
import QueryString from 'qs';
import { toast } from 'react-toastify';

const SliderMarks = [
  {
    value: 0,
    label: '0%'
  },
  {
    value: 100,
    label: '100%'
  }
]

const WarningSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 30,
    paddingTop: "35px",
    borderRadius: '3px',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#2979FF',
    marginTop: -10,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 7px)',
    top: -18,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 3,
  },
  rail: {
    height: 2,
    opacity: 0.8,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
  markLabel: {
    marginTop: "15px",
    marginLeft: "-15px"
  },
  markLabelActive: {
    marginLeft: "13px"
  },
  marked: {
    marginBottom: 0
  }
})(Slider);


const UsageLimitComp = (props) => {

  const [enbaleLimitChecked, setEnableLimitChecked] = useState(false);
  const [enbaleLimitCheckedOld, setEnableLimitCheckedOld] = useState(false);
  const [enableWarningChecked, setEnableWarningChecked] = useState(false);
  const [enableWarningCheckedOld, setEnableWarningCheckedOld] = useState(false);
  const [limit, setLimit] = useState(0);
  const [limitOld, setLimitOld] = useState(0);
  const [warningPercentage, setWarningPercentage] = useState(0);
  const [warningPercentageOld, setWarningPercentageOld] = useState(0);

  const getProjectDetail = () => {
    getProjectDetailsAPI(
      props.projectId,
      (data) => {
        if(data?.daily_limt>0){
          setLimit(data?.daily_limt ?? 0);
          setLimitOld(data?.daily_limt ?? 0);
          setEnableLimitChecked(true);
          setEnableLimitCheckedOld(true);
        }
        else {
          setLimit(0);
          setLimitOld(0);
          setEnableLimitChecked(false);
          setEnableLimitCheckedOld(false);

        }
        if(data?.daily_warn_pct>0){
          setWarningPercentage(parseInt(data?.daily_warn_pct*100) ?? 0);
          setWarningPercentageOld(parseInt(data?.daily_warn_pct*100) ?? 0);
          setEnableWarningChecked(true);
          setEnableWarningCheckedOld(true);
        }
        else {
          setWarningPercentage(0);
          setWarningPercentageOld(0);
          setEnableWarningChecked(false);
          setEnableWarningCheckedOld(false);
        }
      },
      (error) => {
        console.log(error);
      }
      )
  }

  useEffect(() => {
    getProjectDetail();
  }, [props.projectId])
 

  useEffect(()=> {
    if (!enbaleLimitChecked) {
      setLimit(0);
    }
  },[enbaleLimitChecked])

  useEffect(() => {
    if (!enableWarningChecked) {
      setWarningPercentage(0);
    }
  }, [enableWarningChecked])

  const isValid = () => {
    if(
      enableWarningChecked === enableWarningCheckedOld &&
      enbaleLimitChecked === enbaleLimitCheckedOld &&
      limit === limitOld &&
      warningPercentage === warningPercentageOld
    )
    {
      return false;
    }
    else {
      return true;
    }
  }

  const handleSave = () => {
    if(isValid()){
      const data = QueryString.stringify({
        daily_limit : parseInt(limit),
        daily_warn_pct: parseFloat(warningPercentage/100),
        clear_daily_limit: (!enbaleLimitChecked),
        clear_daily_warn: (!enableWarningChecked)
      })
  
      UpdateProjectDailyLimit(
        props.projectId, 
        data,
        (data) => {
          toast.success("Project usage limits have been updated successfully");
          setLimitOld(limit);
          setWarningPercentageOld(warningPercentage);
          setEnableLimitCheckedOld(enbaleLimitChecked);
          setEnableWarningCheckedOld(enableWarningChecked);
        },
        (error) => {
          toast.error("Something went wrong. Please try again");
        }
        )
    }
    else {
      toast.error("No changes made")
    }
   
  }  
  
  const SliderValuetext = (value) => {
    return `${value}%`;
  }

  const handleCancel = () => {
    setEnableLimitChecked(enbaleLimitCheckedOld);
    setEnableWarningChecked(enableWarningCheckedOld);
    setLimit(limitOld);
    setWarningPercentage(warningPercentageOld);
  }

  return (
    <Container>
      <Row className="m-0 cus-content-div">
        <Col className="p-0">
            <Row>
              <Col>
                <h5 className="sec-heading2">Usage Limits</h5>
                <hr className="live-hr" />
              </Col>
            </Row>
            <Row className='m-4 py-3 px-4' style={{backgroundColor:'#E3F2FD', borderRadius:'5px', fontWeight:'400', fontSize:'15px'}}>
              <div>
              Add a daily limit for your project to protect your account from unexpected costs. 
              This is a hard limit and will apply to all messages including API, Web interface and 
              campaigns. A warning email will be sent at the selected percentage and also at 95%.
              </div>
            </Row>
            <Row className='m-4'>
              <Col>
                <Row style={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'15px'}}>
                  <Col className='m-1' md={3} style={{textAlign:'end', height:'30px'}}>Daily Limit</Col>
                  <Col className='m-1' md={7} style={{ height:'30px'}}>
                    <Form>
                      <Form.Check 
                        type={'checkbox'}
                        label={`Enable Limit`}
                        checked={enbaleLimitChecked}
                        onChange={() => setEnableLimitChecked(!enbaleLimitChecked)}
                      />
                    </Form>
                  </Col>
                </Row>
                <Row style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <Col className='m-1' md={3}></Col>
                  <Col className='m-1' md={7}>
                    <Form>
                        <Form.Control
                            disabled={!enbaleLimitChecked}
                            type="number"
                            value={limit}
                            onChange={(e) => setLimit(e.target.value)}
                            className="custom-form-control"
                        />
                    </Form>
                  </Col>  
                </Row>
              </Col>
            </Row>
            <Row className='m-4'>
              <Col>
                <Row style={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'15px'}}>
                  <Col className='m-1' md={3} style={{ textAlign:'end',height:'30px'}}>Warning percentage</Col>
                  <Col className='m-1' md={7} style={{height:'30px'}}>
                    <Form>
                      <Form.Check 
                        type={'checkbox'}
                        label={`Enable warning`}
                        checked={enableWarningChecked}
                        onChange={() => setEnableWarningChecked(!enableWarningChecked)}
                      />
                    </Form>
                  </Col>
                </Row>
                <Row style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <Col className='m-1' md={3}></Col>
                <Col
                  className='m-1'
                  md={7}
                  style={{ backgroundColor: "#f0f0f0", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "5px" }}
                >
                    <WarningSlider 
                    disabled={!enableWarningChecked}
                    aria-label='small'
                    marks={SliderMarks} 
                    value={warningPercentage}
                    valueLabelDisplay="on"
                    valueLabelFormat={SliderValuetext}
                    onChange={(event,value) => setWarningPercentage(value)}
                    />
                  </Col>  
                </Row>
              </Col>
            </Row>
            <Row className='m-5'>
              <Col className='settingButtonSection' style={{gap:'20px'}}>
                <Button variant="outline-secondary" style={{width:'15%'}} onClick={handleCancel}>Cancel</Button>
                <Button variant="primary" style={{width:'15%'}} onClick={handleSave}>Save</Button>
              </Col>
            </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default UsageLimitComp
