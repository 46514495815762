import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import classnames from "classnames";
import qs from "qs";

// import { storeAuth } from "../../auth/auth";
import { refreshCSRF, loginSession, requestVerificationEmailAPI } from "../../api/authAPI";
import { getTeamsAPI } from "../../api/teamAPI";

import LogoColComp from "./LogoColComp";
import ForgetPasswordModal from "../modals/ForgetPasswordModal";
import { toast } from "react-toastify";

function LoginComp(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [authErr, setAuthErr] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResendEmail, setShowResendEmail] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      setEmailErr("");
    } else {
      setPassword(e.target.value);
      setPasswordErr("");
    }
  };

  useEffect(() => {
    refreshCSRF(
      (data) => {},
      (err) => {
        console.log(err);
      }
    );
  }, []);

  // validate
  const validate = () => {
    let emailErr = "";
    let passwordErr = "";

    if (!email) {
      emailErr = "Email is required";
    }

    if (!password) {
      passwordErr = "Password is required";
    }

    if (emailErr || passwordErr) {
      setEmailErr(emailErr);
      setPasswordErr(passwordErr);

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        user_id: email,
        password: password,
      });

      loginSession(
        data,
        (data) => {
          // storeAuth();
          getTeamsAPI(
            (data) => {
              props.history.push("/home");
              setLoading(false);
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (err) => {
          if (
            err.response.data.code === 4003 ||
            err.response.data.code === 4002
          ) {
            setAuthErr("Incorrect username or/and password!");
          } else if (err.response.data.code === 4004) {
            setAuthErr(
              "Please verify your email address to login. Check your inbox for the verification email."
            );
            setShowResendEmail(true);
          }
          setLoading(false);
        }
      );
    }
  };

  const handleShowPasswordModal = () => {
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleResendVerificationEmail = () => {
    if (email) {
      const data = qs.stringify({
        email: email,
      });

      requestVerificationEmailAPI(
        data,
        (data) => {
          setShowResendEmail(false);
          toast.success("Verification email sent successfully");
        },
        (err) => {
          toast.error(err.response.data.description);
        }
      );
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="login-reg-col">
            <div className="login-reg-container">
              <p className="heading">
                Welcome to <b>SMS160</b>
              </p>
              <p style={{ fontSize: 14, color: "#dc3545" }}>{authErr}</p>
              {showResendEmail &&
                <div>
                  <Link
                    to="#"
                    className="forget-password-link"
                    onClick={handleResendVerificationEmail}
                    style={{textAlign: "right"}}
                  >
                    Resend Verification Email
                  </Link>
                </div>
              }
              <Form>
                <Form.Group className="p-0">
                  <Form.Control
                    type="email"
                    className={classnames("login-field form-control mt-3", {
                      "is-invalid": emailErr,
                    })}
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailErr}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="p-0 mb-0">
                  <Form.Control
                    type="password"
                    className={classnames("login-field form-control mt-3", {
                      "is-invalid": passwordErr,
                    })}
                    id="pwd"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {passwordErr}
                  </Form.Control.Feedback>
                </Form.Group>

                <button
                  type="submit"
                  className="btn-submit"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}
                  {loading ? "" : "Login"}
                </button>
              </Form>

              <Link
                to="#"
                className="forget-password-link"
                onClick={handleShowPasswordModal}
              >
                Forgot your Password?
              </Link>
              <p className="mt-2 form-bellow-link">
                <span style={{ color: "#033d5e" }}>Don't have an account?</span>{" "}
                <Link to="/sign-up" className="login-now-link">
                  Create an account
                </Link>
              </p>
            </div>
          </Col>

          <LogoColComp />
        </Row>
      </Container>

      <ForgetPasswordModal
        showPasswordModal={showPasswordModal}
        handleClosePasswordModal={handleClosePasswordModal}
      />
    </>
  );
}

export default withRouter(LoginComp);
