import axios from "axios";

import { getCSRFToken, getTeamId } from "../auth/auth";

export const addContact = (data, onSuccess, onError) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/contacts
    `,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const getContacts = (groupId, onSuccess, onError) => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/v1/web/contacts
    `,
      {
        withCredentials: true,
        headers: {
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const updateContact = (conId, data, onSuccess, onError) => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/v1/web/contacts/${conId}`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;",
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const updateContactGroups = (conId, data, onSuccess, onError) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/contacts/${conId}/update_groups`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const deleteContact = (conId, onSuccess, onError) => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/v1/web/contacts/${conId}`, {
      withCredentials: true,
      headers: {
        "x-csrf-token": getCSRFToken(),
        "x-team-id": getTeamId(),
      },
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const deleteMultiContact = (dataArr, onSuccess, onError) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/contacts/delete_multiple`,
      dataArr,
      {
        withCredentials: true,
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded;",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

//Upload contacts from CSV
export const bulkContact = (data, onSuccess, onError) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/v1/web/contacts_csv
    `,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-csrf-token": getCSRFToken(),
          "x-team-id": getTeamId(),
        },
      }
    )
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      onError(err);
    });
};

