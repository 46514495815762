import React, { useState, useContext, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

function Graph(props) {
  const [dataSet1, setDataSet1] = useState([]);
  const [dataSet2, setDataSet2] = useState([]);
  const [labels, setLabels] = useState([]);
  const [prevLabels, setPrevLabels] = useState([]);
  const [title, setTitle] = useState("");
  const [current, setCurrent] = useState("");
  const [previous, setPrevious] = useState("");
  const [diff, setDiff] = useState(0);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  const options = {
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    layout: {
      padding: {
        top: 30,
        right: 30,
        left: 30,
        bottom: 1
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 0,
          color: 'transparent',
        }
      },
      customLine: {
        width: 5,
        color: 'pink'
      },
      title: {
        display: false,
        text: title,
        align: 'start'
      },      
      tooltip: {
        intersect : false,
        mode: 'index',
        bodyColor: 'transparent',
        backgroundColor: 'transparent',
        titleColor: 'transparent',
        borderColor: 'transparent',
        displayColors: false,
        callbacks: {
          footer: function(tooltipItems, data) {
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: 'value'
        },
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        title: {
          display: false,
          text: 'value'
        },
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 2,
          display: true,
          maxRotation: 0,
        },
      }
    }
  };
  
  const plugins = [
    {
      afterDraw:  function(chart, arg, options) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.tooltip._active[0];
          const { ctx } = chart;
          const { x } = activePoint.element;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;
          // draw vertical line
          ctx.save();
          ctx.beginPath();
          ctx.setLineDash([5, 5]);
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#333333';
          ctx.stroke();
          ctx.restore();
        }
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const { ctx } = chart;
          ctx.save();
          ctx.fillStyle = "#333333";
          ctx.font = 'bolder 18px Arial';
          ctx.marginBottom = '30px';
          if (props.isCurrency) {
            ctx.fillText('$'+chart.tooltip.dataPoints.filter(el => el.datasetIndex == 0)[0].formattedValue, 20, 20)
          } else {
            ctx.fillText(chart.tooltip.dataPoints.filter(el => el.datasetIndex == 0)[0].formattedValue, 20, 20)
          }
            ctx.restore();
        }
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const { ctx } = chart;
          ctx.save();
          ctx.font = 'bolder 18px Arial';
          ctx.textAlign = "right";
          ctx.fillStyle = "#828282";
          if (chart.tooltip.dataPoints.filter(el => el.datasetIndex == 1)[0]) {
            if (props.isCurrency) {
              ctx.fillText('$'+chart.tooltip.dataPoints.filter(el => el.datasetIndex == 1)[0].formattedValue, chart.scales.x.maxWidth+35, 20);
            } else {
              ctx.fillText(chart.tooltip.dataPoints.filter(el => el.datasetIndex == 1)[0].formattedValue, chart.scales.x.maxWidth+35, 20)
            }
          }
          ctx.restore();
        }
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const { ctx } = chart;
          ctx.save();
          ctx.fillStyle = "#333333";
          ctx.fillText(chart.tooltip.dataPoints.filter(el => el.datasetIndex == 0)[0].label, 20, 38)
          ctx.restore();
        }
        if (chart.tooltip._active && chart.tooltip._active.length && chart.tooltip.dataPoints.filter(el => el.datasetIndex == 0)[0] && chart.tooltip.dataPoints.filter(el => el.datasetIndex == 1)[0]) {
          const { ctx } = chart;
          ctx.save();
          ctx.fillStyle = "#828282";
          ctx.textAlign = "right";
          ctx.fillText(getPrevValText(chart.config._config.options.plugins.title.text, chart.tooltip.dataPoints.filter(el => el.datasetIndex == 0)[0].dataIndex), chart.scales.x.maxWidth+35, 38)
          ctx.restore();
        }
        if (chart.tooltip._active && chart.tooltip._active.length && chart.tooltip.dataPoints.filter(el => el.datasetIndex == 1)[0] && chart.tooltip.dataPoints.filter(el => el.datasetIndex == 0)[0]) {
          let prev = chart.tooltip.dataPoints.filter(el => el.datasetIndex == 1)[0].formattedValue;
          let cur = chart.tooltip.dataPoints.filter(el => el.datasetIndex == 0)[0].formattedValue;
          let roughDiff = (((parseInt(cur.replace(/,/g, "")) - parseInt(prev.replace(/,/g, "")))/parseInt(prev.replace(/,/g, "")))*100);
          let diff = '0%';
          let color = '#4F4F4F';
          if (roughDiff === Infinity) {
            diff = '+100%';
            color = '#035C29';
          } else if (roughDiff === NaN) {
            diff = '0%';
            color = '#4F4F4F';
          } else {
            if (parseInt(roughDiff) > 0) {
              diff = '+'+roughDiff.toFixed(1)+'%';
              color = '#035C29';
            } else if (parseInt(roughDiff) < 0) {
              diff = roughDiff.toFixed(1)+'%';
              color = '#810000';
            } else {
              diff = '0%';
              color = '#4F4F4F';
            }
          }
          const activePoint = chart.tooltip._active[0];
          const { ctx } = chart;
          const { x } = activePoint.element;
          const topY = chart.scales.y.top;
          ctx.save();
          ctx.fillStyle = color;
          ctx.textAlign = "center";
          ctx.font = 'bolder 13px Arial';
          ctx.fillText(diff, x, topY-10)
          ctx.restore();
        }
      },
    },
    
  ];

  useEffect(() => {
    setDataSet1(props.data1)
    setDataSet2(props.data2)
    setLabels(props.labels)
    setPrevLabels(props.prevLabels)
    setTitle(props.title)
    let prev = props.data2.reduce((a, b) => a + b, 0);
    let cur = props.data1.reduce((a, b) => a + b, 0);
    let difference = ((cur - prev)/prev)*100;
    if (difference === Infinity) {
      difference = 100;
    } else if (isNaN(difference)) {
      difference = 0;
    }
    setDiff(difference);
  }, [props]);

  const getPrevValText = (chartName, val) => {
    if (chartName == "Total Outgoing SMS") {
      return JSON.parse(localStorage.getItem("OutgoingPrevLabels"))[val]
    } else if (chartName == "Total Delivered") {
      return JSON.parse(localStorage.getItem("DeliveredPrevLabels"))[val]
    } else if (chartName == "Total Undelivered") {
      return JSON.parse(localStorage.getItem("UndeliveredPrevLabels"))[val]
    } else if (chartName == "Total Spend") {
      return JSON.parse(localStorage.getItem("SpendPrevLabels"))[val]
    }
  }

  return (
    <div>
      <div>
        <div style={{fontWeight: '500', color: '#333333', paddingTop: '10px', paddingLeft: '10px'}}>
          {props.title}
          {diff === 0 &&
            <span style={{color: '#4F4F4F', background: '#BDBDBD', borderRadius: '5px', padding: '3px', marginLeft: '10px', fontWeight: 'normal', fontSize: '14px'}}>{diff.toFixed(1)}%</span>
          }
          {diff > 0 &&
            (props.title === 'Total Undelivered' ?
              (<span style={{color: '#810000', background: '#e69c9c', borderRadius: '5px', padding: '3px', marginLeft: '10px', fontWeight: 'normal', fontSize: '14px'}}>+{diff.toFixed(1)}%</span>)
            :
              (<span style={{color: '#035C29', background: '#a8d7bb', borderRadius: '5px', padding: '3px', marginLeft: '10px', fontWeight: 'normal', fontSize: '14px'}}>+{diff.toFixed(1)}%</span>)
          )}
          {diff < 0 &&
            (props.title === 'Total Undelivered' ?
              <span style={{color: '#035C29', background: '#a8d7bb', borderRadius: '5px', padding: '3px', marginLeft: '10px', fontWeight: 'normal', fontSize: '14px'}}>{diff.toFixed(1)}%</span>
            :
              <span style={{color: '#810000', background: '#e69c9c', borderRadius: '5px', padding: '3px', marginLeft: '10px', fontWeight: 'normal', fontSize: '14px'}}>{diff.toFixed(1)}%</span>
          )}
        </div>
      </div>
      <div className="main-graph">
        <Line
          options={options}
          plugins={plugins}
          data={
            {
              labels,
              datasets: [
                {
                  label: current,
                  data: (dataSet1),
                  borderColor: '#2979FF',
                  backgroundColor: '#F2F2F2',
                  pointRadius: 2
                },
                {
                  label: previous,
                  data: (dataSet2),
                  borderColor: '#BDBDBD',
                  backgroundColor: '#F2F2F2',
                  pointRadius: 2
                },
              ],
            }
          }
        />
      </div>
    </div>
  );
}

export default Graph;