import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import qs from "qs";
import classnames from "classnames";
import { Container, Row, Col, Form, Badge, InputGroup, Dropdown } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/campaigns.css";
import { getGroups } from "../../api/groupAPI";
import { addCampaign } from "../../api/campaignAPI";
import { getProjectDetailsAPI } from "../../api/ProjectAPI";
import { toast } from "react-toastify";
import {
  Switch,
  FormControlLabel
} from "@material-ui/core";
import ConfirmCampaignModal from "../modals/ConfirmCampaignModal";
import { withStyles } from '@material-ui/core/styles';
import { getUserProfileAPI } from "../../api/authAPI";
import {useHistory} from "react-router-dom";

const initial = {
  name: "",
  nameErr: "",
  content: "",
  contentErr: "",
  selectedGroups: [],
  include_groupsErr: ""
};

function NewCampaignComp() {
  const [state, setState] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [isImmediate, setIsImmediate] = useState(true);
  const [mode, setMode] = useState(
    localStorage.getItem("mode")
      ? localStorage.getItem("mode")
      : "LIVE"
  );
  const [ selectedProject, setSelectedProject ] = useState(localStorage.getItem("selected_project"));
  const [project, setProject] = useState([]);
  const [senders, setSenders] = useState([]);
  const [senderId, setSenderId] = useState('');
  const [confirmDialogShow, setConfirmDialogShow] = useState(false);
  const [count, setCount] = useState(0);
  const [verifiedUser, setVerifiedUser] = useState(false);
  const [messagesPreview, setMessagesPreview] = useState([]);
  const history = useHistory();
  const [ characterLimit ] = useState(1600);

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    getUserProfileAPI(
      (data) => {
        setVerifiedUser(data.id_verified);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    handleGetProjectDetails();
  }, [selectedProject]);

  const fetchGroups = () => {
    getGroups(
      (data) => {
        setGroups(data);
      },
      (err) => {
        console.log(err);
      }
    );
  };
 
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  const handleGetProjectDetails = () => {
    getProjectDetailsAPI(
      selectedProject,
      (data) => {
        setProject(data);
        setSenders(data.senders);
        if ((data.senders).length > 0) {
          setSenderId(data.senders[0].id);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  
  // validate
  const validate = () => {
    if (!verifiedUser && mode === "LIVE") {
      toast.error("Please verify your account before sending SMS");
      history.replace('/request-verification')
      return false;
    }

    let nameErr = "";
    let contentErr = "";
    let include_groupsErr = "";

    if (!state.name) {
      nameErr = "Campaign name is required";
    }
    if (!state.content) {
      contentErr = "Content is required";
    }
    if (selectedGroups.length == 0) {
      include_groupsErr = "Groups are required";
    }

    if (nameErr || contentErr || include_groupsErr) {
      setState({
        ...state,
        nameErr,
        contentErr,
        include_groupsErr
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const selGroups = selectedGroups.map((el) => el.id);
      const data = qs.stringify({
        name: state.name,
        content: state.content,
        include_groups: selGroups.toString(),
        send_at: isImmediate ? null : startDate.toISOString().split('.')[0],
        mode: mode,
        project_id: selectedProject,
        from: senderId,
        is_preview: true
      });

      addCampaign(
        data,
        (data) => {
          setMessagesPreview(data.messages_preview);
          setCount(data.message_count);
          setLoading(false);
          setConfirmDialogShow(!confirmDialogShow);
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again");
        }
      );
    }
  };

  const handleDropdownChange = (e) => {
    if (e == "immediate") {
      setIsImmediate(true);
    } else {
      setIsImmediate(false);
    }
  }

  const handleSenderChange = (e) => {
    setSenderId(e);
  }

  const changeSelectErrText = (e) => {
    setSelectedGroups(e);
    setState({
      ...state,
      include_groupsErr : ""
    });
  }

  const handleMode = (type) => {
    type = (type === "TEST") ? "LIVE" : "TEST";
    setMode(type);
    localStorage.setItem("mode", type);
  };

  const handleConfirmation = (result) => {
    if (result) {
      setLoading(true);
      setConfirmDialogShow(!confirmDialogShow);
      const selGroups = selectedGroups.map((el) => el.id);
      const data = qs.stringify({
        name: state.name,
        content: state.content,
        include_groups: selGroups.toString(),
        send_at: isImmediate ? null : startDate.toISOString().split('.')[0],
        mode: mode,
        project_id: selectedProject,
        from: senderId,
        is_preview: false
      });

      addCampaign(
        data,
        (data) => {
          toast.success("New Campaign has been created successfully");
          setTimeout(() => {
            window.location.href='/campaigns'
          }, 3000);
        },
        (err) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again");
        }
      );
    } else {
      setConfirmDialogShow(!confirmDialogShow);
    }
  };

  const CustomSwitch = withStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: 'grey',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#EF6C00',
          borderColor: '#EF6C00',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid grey`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: 'white',
    },
    checked: {},
  })(Switch);

  return (
    <>
      <Container>
      <Link to="/campaigns" className="dash-back-btn">
        <i className="fas fa-angle-left"></i> Back
      </Link>
        <Row className="m-0 cus-content-div">
              <Form style={{paddingLeft: '0', paddingRight: '0'}}>
                <div className="main-header-div">
                  <div className="header-div">
                    <h5 className="sec-heading2">New Campaign</h5>
                    <FormControlLabel
                      label="Test Mode"
                      labelPlacement="start"
                      className={(mode === "TEST") ? "switch-label-test" : "switch-label-live"}
                      control={
                        <CustomSwitch
                          checked= {(mode === "TEST")}
                          onChange={() => handleMode(mode)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                    />
                  </div>
                  {(mode == 'TEST') ? (
                    <div>
                      <hr className="test-hr"/>
                      <Badge bg="warning" text="dark" className="test-label">
                      TEST MODE
                      </Badge>
                    </div>
                  ) : (<hr className="live-hr" />)}
                </div>
                {(!verifiedUser) ?
                  (<div>
                  <div
                    style={{background:"#e3f2fd", fontSize:"14px", padding:"10px", marginTop:"20px", marginBottom:"20px", textAlign:"left"}}
                    >
                      Your account is not verified yet.
                    </div>
                    <div className="download-report-div create-btn-div" style={{textAlign:"left"}}>
                      <button onClick={() => history.replace('/request-verification')} style={{color: '#2979FF', marginLeft:"0"}}>
                        Request Verification
                      </button>
                    </div>
                  </div>
                  )
                  : null
                }
                <h6 style={{ fontSize: '1rem', color: "#333333", marginBottom: "12px", fontWeight: "500"}} className="settings-subtitle">Campaign Info</h6>
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Campaign Name
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        type="text"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.nameErr,
                        })}
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                        <Form.Control.Feedback type="invalid">
                          {state.nameErr}
                        </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <Form>
                  <Form.Group as={Row} style={{ marginTop: "18px" }}>
                    <Form.Label column sm="2">
                      Contact Groups
                    </Form.Label>
                    <Col sm="5">
                      <Typeahead
                          id="basic-typeahead-multiple"
                          labelKey="name"
                          multiple
                          onChange={changeSelectErrText}
                          options={groups}
                          placeholder="Choose groups..."
                          selected={selectedGroups}
                        />
                      <label type="invalid" className="error-label">
                        {state.include_groupsErr}
                      </label>
                    </Col>
                  </Form.Group>
                </Form>
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Sending Time
                    </Form.Label>
                    <Col sm="5">
                      {/* <select className="form-control mb-3 select-form-control new-campaign-select" onChange={handleDropdownChange}>
                        <option value="immediate">Immediately</option>
                        <option value="schedule">Scheduled Time</option>
                      </select> */}
                      <Dropdown>
                        <Dropdown.Toggle className="sending-time-drop-down" variant="outline" id="dropdown-basic" size="sm" onChange={handleDropdownChange} style={{alignItems: 'center', width: '100%', display: 'flex', background: '#f0f0f0', justifyContent: 'space-between'}}>
                          {isImmediate ? "Immediately" : "Scheduled Time"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '70%'}}>
                          <Dropdown.Item
                            onClick={() => handleDropdownChange('immediate')}
                            className={isImmediate ? "selected-item" : "un-selected-item"}
                          >
                            Immediately
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleDropdownChange('scheduled')}
                            className={!isImmediate ? "selected-item" : "un-selected-item"}
                          >
                            Scheduled Time
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Form.Group>
                </Form>
                {(!isImmediate) ? (
                  <Form style={{marginTop: '20px'}}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="2">
                       Scheduled Time
                      </Form.Label>
                      <Col sm="5">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          timeInputLabel="Time:"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTimeInput
                          minDate={new Date()}
                          className="custom-form-control form-control mb-3"
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                  ) : (
                    null
                )}
                <h6 style={{ fontSize: '1rem', color: "#333333", marginBottom: "12px", marginTop: "21px", fontWeight: "500"}} className="settings-subtitle">Message Content</h6>
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Message Content
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        multiline
                        rows={3}
                        as="textarea"
                        className={classnames("custom-form-control", {
                            "is-invalid": state.contentErr,
                        })}
                        name="content"
                        value={state.content}
                        onChange={handleChange}
                        maxLength={characterLimit}
                      />
                      <div style={{display:"flex", justifyContent:"space-between",fontSize: "13px",color:"#333333"}}>
                      <span>
                        Characters: {state.content.length} / {characterLimit}
                      </span>
                      <span>
                        SMS Segments: {Math.ceil(state.content.length/160)}/10
                      </span>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {state.contentErr}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <h6 style={{ fontSize: '1rem', color: "#333333", marginBottom: "12px", marginTop: "35px", fontWeight: "500"}} className="settings-subtitle">Sender Info</h6>
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Sender ID
                    </Form.Label>
                    <Col sm="5">
                      {/* <select className="form-control mb-3 select-form-control" onChange={handleSenderChange}>
                        {(senders) && senders.map((el, i) => (
                          <option value={el.id}>{el.id}</option>
                        ))}
                      </select> */}
                      <Dropdown>
                        <Dropdown.Toggle className="sending-time-drop-down" variant="outline" id="dropdown-basic" size="sm" style={{alignItems: 'center', width: '100%', display: 'flex', background: '#f0f0f0', justifyContent: 'space-between'}}>
                          {senderId}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{width: '60%'}}>
                          {(senders) && senders.map((el, i) => (
                             <Dropdown.Item
                              onClick={() => handleSenderChange(el.id)}
                              className={el.id==senderId ? "selected-item" : "un-selected-item"}
                            >
                              {el.id}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Form.Group>
                </Form>
                <Row className="">
                    <Form.Label column sm="2">
                    </Form.Label>
                    <Col sm="5" style={{display: 'flex', justifyContent: 'right'}}>
                      <button
                        type="submit"
                        className="btn-submit mt-3 save-btn"
                        onClick={handleSubmit}
                        style={{width: '35%'}}
                      >
                        {loading ? (
                          <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                          >
                              <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {loading ? "" : "Review and Send"}
                      </button>
                    </Col>
                </Row>
              </Form>
        </Row>
      </Container>
      <ConfirmCampaignModal
        title="Create Campaign"
        message="Are you sure you want to create this campaign ?"
        confirmBtnName="Send"
        confirmModalType="Update"
        callback={handleConfirmation}
        show={confirmDialogShow}
        onHide={() => setConfirmDialogShow(false)}
        content={state.content}
        count={count}
        messagesPreview={messagesPreview}
        sendAt={isImmediate ? 'Now' : startDate.toISOString().split('.')[0]}
      />
    </>
  );
}

export default withRouter(NewCampaignComp);
