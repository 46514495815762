import React from "react";

import DashboardLayout from "../components/layouts/DashboardLayout";
import IdVerification from "../components/verification/IdVerification";

function Verification() {
  return (
    <DashboardLayout>
      <IdVerification />
    </DashboardLayout>
  );
}

export default Verification;
