import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import classnames from "classnames";
import qs from "qs";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { MdCheckCircle } from "react-icons/md";
import Logo from "../../images/logo.png";

function PaymentSuccessComp() {

  return (
    <Container>
      <Row className="m-0 cus-content-div">
        <Col className="p-0">
              <Col>
                <h5 className="sec-heading" style={{marginBottom:'6px'}}>Payment Success</h5>
              </Col>
              <hr style={{ marginLeft:'auto', marginRight:'auto', marginTop:'0'}}/>
              <div className="" style={{textAlign:'center'}}>
                <Col xl="7" style={{margin:'auto', paddingTop:'10%'}}>
                  <MdCheckCircle style={{color:'#3bb54a', height: '100%', width: '15%'}}/>
                  <p>Your Payment Is Successful</p>
                  <div className="">
                      <button onClick={() => window.location.href='/billing'} style={{color: '#2979FF', background:'transparent', border:'none', textDecoration:'underline'}}>
                        Continue to Billing Page
                      </button>
                    </div>
                </Col>
              </div>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(PaymentSuccessComp);
