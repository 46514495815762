import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import classnames from "classnames";
import qs from "qs";

import Close from "../../images/close.png";
import { refreshCSRF, requestResetPassword } from "../../api/authAPI";
import { toast } from "react-toastify";

function ForgetPasswordModal({ showPasswordModal, handleClosePasswordModal }) {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [authErr, setAuthErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    setEmailErr("");
  };

  // validate
  const validate = () => {
    let emailErr = "";

    if (!email) {
      emailErr = "Email is required";
    }

    if (emailErr) {
      setEmailErr(emailErr);

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const data = qs.stringify({
        email: email,
      });
      requestResetPassword(
        data,
        (data) => {
          toast.success("Password Reset Link Sent to Your Email Account");
          setLoading(false);
          handleClosePasswordModal();
        },
        (err) => {
          toast.error("Something went wrong. Please try again");
          setLoading(false);
        }
      );
    }
  };

  return (
    <Modal show={showPasswordModal} onHide={handleClosePasswordModal} centered>
      <Modal.Header>
        <Modal.Title>Forgot your password</Modal.Title>

        <img
          src={Close}
          alt="close"
          width="25"
          onClick={handleClosePasswordModal}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="mb-4" style={{ fontSize: 14, color: "#6d6d6d" }}>
          In order to receive your access code by email, please enter the
          address you provided during the registration process.
        </p>
        <p style={{ fontSize: 14, color: "#dc3545" }}>{authErr}</p>
        <Form>
          <Form.Group className="p-0">
            <Form.Control
              type="email"
              className={classnames("login-field form-control", {
                "is-invalid": emailErr,
              })}
              id="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={email}
            />
            <Form.Control.Feedback type="invalid">
              {emailErr}
            </Form.Control.Feedback>
          </Form.Group>

          <button
            type="submit"
            className="btn-submit mt-3"
            onClick={handleSubmit}
          >
            {loading ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            {loading ? "" : "Request reset link"}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ForgetPasswordModal;
