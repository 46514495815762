import React from "react";
import { Table, Modal, Row, Form, Col } from "react-bootstrap";
import Close from "../../images/close.png";

const ConfirmCampaignModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // className="confirm-action-dialog"
    >
      <Modal.Header className="modal-header">
        <Modal.Title className="modal-title">{props.title}</Modal.Title>
        <img
          src={Close}
          alt="close"
          onClick={() => props.callback(false)}
          style={{ cursor: "pointer"}}
          className="modal-close-btn"
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <hr className="live-hr modal-hr" />
        <div className="dialog">
          <div className="confirm-dialog-meg">Your message will be delivered to {props.count} recipients.</div>
          <div style={{marginTop: '20px'}}>
            <Table
              className="custom-table"
              responsive
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th align="center">Number</th>
                  <th align="center">From</th>
                  <th align="center">Send Time</th>
                  <th align="center">Message</th>
                </tr>
              </thead>
              <tbody>
                {props.messagesPreview.length > 0 &&
                  (props.messagesPreview).map((el, i) => (
                    <tr>
                      <td>{el.to}</td>
                      <td>{el.from}</td>
                      <td>{props.sendAt}</td>
                      <td>{el.body}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
          <Row className="">
            <Form.Label column sm="4">
            </Form.Label>
            <Col sm="8" style={{display:"flex", justifyContent: "right"}}>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mt-3"
                onClick={() => props.callback(false)}
                style={{ width: 120, color: "#333333" }}
              >
                Make Changes
              </button>
              <button
                type="submit"
                className="btn-submit mt-3"
                onClick={() => props.callback(true)}
                style={{width: "33%", marginLeft: "5%"}}
              >
                {props.confirmBtnName}
              </button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmCampaignModal;
