import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import classnames from "classnames";
import axios from "axios";
import qs from "qs";

// import { getCSRFToken, storeAuth } from "../../../auth/auth";
import { registerAccount } from "../../api/authAPI";

import LogoColComp from "./LogoColComp";

const formInitial = {
  fname: "",
  lname: "",
  email: "",
  password: "",
  passwordConf: "",
  fnameErr: "",
  lnameErr: "",
  emailErr: "",
  passwordErr: "",
  passwordConfErr: "",
  region: "LK",
};

function LoginComp(props) {
  const [form, setForm] = useState(formInitial);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  // validate
  const validate = () => {
    let fnameErr = "";
    let lnameErr = "";
    let emailErr = "";
    let passwordErr = "";
    let passwordConfErr = "";

    if (!form.fname) {
      fnameErr = "First name is required";
    } else if (form.fname.length < 2) {
      fnameErr = "First name is too short";
    } else if (!form.fname.match(/^\D+$/)) {
      fnameErr = "First name is Invalid";
    }

    if (!form.lname) {
      lnameErr = "Last name is required";
    } else if (!form.lname.match(/^\D+$/)) {
      lnameErr = "Last name is Invalid";
    }

    if (!form.email) {
      emailErr = "Email is required";
    } else if (
      !form.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      emailErr = "Email is invalid";
    }

    if (!form.password) {
      passwordErr = "Password is required";
    } else if (
      !form.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      passwordErr =
        "Minimum eight characters, at least one uppercase letter, one lowercase letter and one special character";
    }

    if (!form.passwordConf) {
      passwordConfErr = "Confirm password is required";
    } else if (form.password !== form.passwordConf) {
      passwordConfErr = "Password must match";
    }

    if (fnameErr || lnameErr || emailErr || passwordErr || passwordConfErr) {
      setForm({
        ...form,
        fnameErr,
        lnameErr,
        emailErr,
        passwordErr,
        passwordConfErr,
      });

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        first_name: form.fname,
        last_name: form.lname,
        email: form.email,
        password: form.password,
        region_code: form.region,
      });

      registerAccount(
        data,
        (data) => {
          props.history.push({pathname: '/verification-required', state: {email: form.email}});
          setLoading(false);
        },
        (err) => {
          if (err.response?.data?.code === 4000) {
            setForm({
              ...form,
              emailErr: "Email already exist",
            });
          }
          setLoading(false);
        }
      );
    }
  };

  return (
    <Container fluid>
      <Row>
        <LogoColComp />
        <Col xs={12} md={6} className="login-reg-col">
          <div className="login-reg-container">
            <p className="heading">
              Join with <b>SMS160</b>
            </p>
            {/* <p style={{ fontSize: 14, color: "#dc3545" }}>{authErr}</p> */}
            <Form>
              <Form.Group className="p-0">
                <Form.Control
                  type="text"
                  className={classnames("login-field form-control mt-3", {
                    "is-invalid": form.fnameErr,
                  })}
                  name="fname"
                  placeholder="First Name"
                  onChange={handleChange}
                  value={form.fname}
                />
                <Form.Control.Feedback type="invalid">
                  {form.fnameErr}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="p-0">
                <Form.Control
                  type="text"
                  className={classnames("login-field form-control mt-3", {
                    "is-invalid": form.lnameErr,
                  })}
                  name="lname"
                  placeholder="Last Name"
                  onChange={handleChange}
                  value={form.lname}
                />
                <Form.Control.Feedback type="invalid">
                  {form.lnameErr}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="p-0">
                <Form.Control
                  type="email"
                  className={classnames("login-field form-control mt-3", {
                    "is-invalid": form.emailErr,
                  })}
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={form.email}
                />
                <Form.Control.Feedback type="invalid">
                  {form.emailErr}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="p-0">
                <Form.Control
                  type="password"
                  className={classnames("login-field form-control mt-3", {
                    "is-invalid": form.passwordErr,
                  })}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={form.password}
                />
                <Form.Control.Feedback type="invalid">
                  {form.passwordErr}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="p-0 mb-0">
                <Form.Control
                  type="password"
                  className={classnames("login-field form-control mt-3", {
                    "is-invalid": form.passwordConfErr,
                  })}
                  name="passwordConf"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  value={form.passwordConf}
                />
                <Form.Control.Feedback type="invalid">
                  {form.passwordConfErr}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="p-0 mb-0">
                <Form.Control as="select" className="login-field form-control mt-3" onChange={handleChange} value={form.region} name="region">
                  <option value="LK" selected>
                    LK
                  </option>
                  <option value="AU">AU</option>
                </Form.Control>
              </Form.Group>

              <button
                type="submit"
                className="btn-submit"
                onClick={handleSubmit}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {loading ? "" : "Sign Up"}
              </button>
            </Form>
            <p className="mt-3 form-bellow-link">
              <span style={{ color: "#033d5e" }}>Already have an account?</span>{" "}
              <Link to="/" className="login-now-link">
                Login
              </Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(LoginComp);
