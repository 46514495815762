import React,{useState,useRef} from "react";
import {Modal, Form, Row, Col, Button, Table} from "react-bootstrap";
import Close from "../../images/close.png";
import { bulkContact } from "../../api/contactAPI";
import csv_format from "../../images/csv_format.png";

function AddContactCSV({showAddContactCsvModal, setAddContactCsvModal,fetchContacts}) {

  const [dragActive, setDragActive] = useState(false);
  const [displayUpload, setDisplayUpload] = useState("disabled");
  const [displayForm, setDisplayForm] = useState(true);
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState(false);
  const inputRef = useRef(null);
  const fileReader = new FileReader();
  const formdata = new FormData();


    //on click close btn
    const handleClose = () => {
        setAddContactCsvModal(false);
        setDisplayUpload("disabled");
        setDisplayForm(true);
        setArray([]);
        setFile();
        setError(false);
    }

    //on hide modal
    const handleOnHide = () => {
        setAddContactCsvModal(false);
        setDisplayUpload("disabled");
        setDisplayForm(true);
        setArray([]);
        setFile();
        setError(false);
    }

    //Handle clear
    const handleClear = () => {
        setDisplayForm(true);
        setFile();
        setDisplayUpload("disabled");
        setArray([]);
        setFile();
        setError(false);
    }

    //convert csv to array
    const csvFileToArray = string => {
        const csvHeader = ["Mobile","FirstName","LastName","Group1","Group2"]
        const csvRows = string.slice().split("\n");
        console.log("t2",csvRows);
    
        const array = csvRows.map(i => {
          const values = i.split(",");
          const obj = csvHeader.reduce((object, header, index) => {
            object[header] = values[index];
            return object;
          }, {});
          return obj;
        });
    
        setArray(array);
      };


    //on click upload btn
    const handleOnSubmit = (e) => {
        e.preventDefault();
        console.log(array)
        setLoading(true);
        formdata.append("csv",file);
        if(formdata){
            bulkContact(formdata,
                (data) => {
                    fetchContacts();
                    setLoading(false);
                    handleClose();
                },
                (err) => {
                    setLoading(false);
                    setError(true);
                    setDisplayUpload("disabled");
                }
                )
        }
    
    };

     // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
        } else if (e.type === "dragleave") {
        setDragActive(false);
        }
    };
    
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
         setDisplayUpload("");
         setDisplayForm(false);
         setFile(e.dataTransfer.files[0]);
         if (e.dataTransfer.files[0]) {
            fileReader.onload = function (event) {
              const text = event.target.result;
              csvFileToArray(text);
            };
      
            fileReader.readAsText(e.dataTransfer.files[0]);
          }
        }
    };

    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
        // handleFiles(e.target.files);
        setDisplayUpload("");
        setDisplayForm(false);
        setFile(e.target.files[0]);
        if (e.target.files[0]) {
            fileReader.onload = function (event) {
              const text = event.target.result;
              csvFileToArray(text);
            };
      
            fileReader.readAsText(e.target.files[0]);
          }
        }
    };
    
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        <Modal
            show={showAddContactCsvModal}
            onHide={handleOnHide}
            centered
            className="bulk_contact_modal"
        >
            <Modal.Header className="modal-header">
                <Modal.Title className="modal-title">Upload From File</Modal.Title>
                <img
                    src={Close}
                    alt="close"
                    onClick={handleClose}
                    style={{ cursor: "pointer"}}
                    className="modal-close-btn"
                />
            </Modal.Header>
            <Modal.Body className="bulk_modal pt-0">
                <hr className="live-hr modal-hr" />
                        {displayForm &&
                        <Row className="bulk_modal_subheading mt-3">
                            <span>Add a list of contacts at once by uploading a csv file. The file should be of the following format:</span>
                            <img className="mt-2 mb-2" src={csv_format} alt="csv format"/>
                        </Row>
                        }
                        <Row className="upload-box mt-4">
                            {displayForm &&
                                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                                    <input ref={inputRef} type="file" id="input-file-upload" multiple={false} accept="text/csv" onChange={handleChange} />
                                    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                                        <div>
                                        <span className="dragDrop">Drag and drop</span><br/>
                                        <span>or</span><br/>
                                        <button className="upload-button mt-2" onClick={onButtonClick}>Browse</button>
                                        </div> 
                                    </label>
                                    { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                                </form>
                            }
                            </Row>
                            <Row>
                            {file && 
                                  <Table
                                  className="custom-table"
                                  responsive
                                  style={{ width: "100%" }}
                                > 
                                    <thead>
                                        <tr>
                                        <th align="center">Number</th>
                                        <th align="center">First Name</th>
                                        <th align="center">Last Name</th>
                                        <th align="center">Group 1</th>
                                        <th align="center">Group 2</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {array.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.Mobile}</td>
                                                <td>{item.FirstName}</td>
                                                <td>{item.LastName}</td>
                                                <td>{item.Group1}</td>
                                                <td>{item.Group2}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            }
                            </Row>
                        </Modal.Body>

                        {error &&
                        <div className="csv-warning ml-2">
                            <span style={{color:"red"}}>*Something went wrong!</span>
                        </div> 
                        }

                        <Row className="mb-3">
                            <Form.Label column sm="4">
                            </Form.Label>
                            <Col sm="7" style={{display:"flex", justifyContent: "right"}}>
                                <button
                                    type="button"
                                    className="btn btn-outline-secondary btn-sm mt-3"
                                    onClick={displayForm ? handleClose : handleClear}
                                    style={{ width: 90, color: "#333333" }}
                                >
                                    {displayForm ? "Cancel" : "Clear"}
                                </button>
                                <Button 
                                className="btn-submit mt-3"
                                style={{width: "33%", marginLeft: "5%"}}
                                disabled={displayUpload}
                                onClick={handleOnSubmit}
                                >
                                     {loading ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-light"
                                            role="status"
                                        >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        ) : null}
                                        {loading ? "" : "Upload"}
                                </Button>
                            </Col>
                        </Row>
        </Modal>
    )
}

export default AddContactCSV;
