import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Modal, Form, Row} from 'react-bootstrap';
import Close from "../../images/close.png";
import classnames from "classnames";
import qs from "qs";
import "../../styles/login-register.css"

import { refreshCSRF, loginSession} from "../../api/authAPI";
import { getTeamsAPI } from "../../api/teamAPI";
import ForgetPasswordModal from "./ForgetPasswordModal";

function LoginModal(showLoginModal, handleLoginModalClose) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [authErr, setAuthErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showModal, setShowModal] = useState("block");

    //refresh CSRF token
    useEffect(() => {
        refreshCSRF(
          (data) => {},
          (err) => {
            console.log(err);
          }
        );
    }, []);

    //validate input
    const validate = () => {
        let emailErr = "";
        let passwordErr = "";

        if (!email) {
        emailErr = "Email is required";
        }

        if (!password) {
        passwordErr = "Password is required";
        }

        if (emailErr || passwordErr) {
        setEmailErr(emailErr);
        setPasswordErr(passwordErr);

        return false;
        }

        return true;
    };

    //handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
          setLoading(true);
          setEmailErr("");
          setPasswordErr("");
          setAuthErr("");
    
          const data = qs.stringify({
            user_id: email,
            password: password,
          });
    
          loginSession(
            data,
            (data) => {
              getTeamsAPI(
                (data) => {
                  setShowModal("none");
                  clearState();
                  window.location.reload(false);
                },
                (err) => {
                  console.log(err);
                }
              );
            },
            (err) => {
              if (
                err.response.data.code === 4003 ||
                err.response.data.code === 4002
              ) {
                setAuthErr("Incorrect username or/and password!");
              }
              setLoading(false);
            }
          );
        }
      };

    //close login modal
    const handleModalClose = () => {
      setShowModal("none");
        clearState();
        window.location.href = "/";
    }

    //clear state
    const clearState = () => {
        setEmail("");
        setPassword("");
        setEmailErr("");
        setPasswordErr("");
        setAuthErr("");
        setLoading(false);
    }

    //show forgot password
    const handleShowPasswordModal = () => {
        setShowModal("none");
        setShowPasswordModal(true);
      };
    
    //close forgot password
    const handleClosePasswordModal = () => {
        setShowPasswordModal(false);
        setShowModal("block");
    };

    return (
        <>
        <Modal
            centered
            show={showLoginModal}
            onHide={handleLoginModalClose}
            backdrop="static"
            style={{display:showModal}}
        >
            <Modal.Header className="modal-header">
                <Modal.Title className="modal-title">Login</Modal.Title>
                <img
                    src={Close}
                    alt="close"
                    onClick={handleModalClose}
                    style={{ cursor: "pointer"}}
                    className="modal-close-btn"
                />
            </Modal.Header>
            <Modal.Body className="pt-2">
                <Row>
                    <span className="mt-0 lg-mdl-description">Your authentication failed. Try re-login again.</span>
                </Row>
                <hr className="live-hr modal-hr mt-1" />
                <Row>
                    <span className="auth-error">{authErr}</span>
                    <Form>
                        <Form.Group className="p-0">
                        <Form.Control
                            type="email"
                            className={classnames("login-field form-control mt-3", {
                            "is-invalid": emailErr,
                            })}
                            id="email"
                            name="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {emailErr}
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="p-0 mb-0">
                        <Form.Control
                            type="password"
                            className={classnames("login-field form-control mt-3", {
                            "is-invalid": passwordErr,
                            })}
                            id="pwd"
                            name="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {passwordErr}
                        </Form.Control.Feedback>
                        </Form.Group>

                        <button
                        type="submit"
                        className="btn-submit"
                        onClick={handleSubmit}
                        >
                          {loading &&
                             <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          }
                        {loading ? "" : "Login"}
                        </button>
                    </Form>

                    <Link
                        to="#"
                        className="forget-password-link"
                        onClick={handleShowPasswordModal}
                    >
                        Forgot your Password?
                    </Link>
                </Row>
            </Modal.Body> 
        </Modal>
        <ForgetPasswordModal
                showPasswordModal={showPasswordModal} 
                handleClosePasswordModal={handleClosePasswordModal}
            />
        </>
    )
}

export default LoginModal;