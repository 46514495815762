import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Table, Spinner, Dropdown } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getCampaignsForProject } from "../../api/campaignAPI";
import "../../styles/billing.css";
import { getUserProfileAPI } from "../../api/authAPI";
import { getTeamAPI } from "../../api/teamAPI";
import AddPaymentModal from "../modals/AddPaymentModal";
import { getAccountUsageForMonth } from "../../api/billingAPI";

function BillingOverviewComp() {
  const [pageLoading, setPageLoading] = useState(false);
  const [lastPaymentDate, setLastPaymentDate] = useState("date-here");
  const [lastPaymentAmount, setLastPaymentAmount] = useState(0.00);
  const [usageMonth, setUsageMonth] = useState("usage-month");
  const [outGoingQuantity, setOutGoingQuantity] = useState(0);
  const [outGoingCost, setOutGoingCost] = useState(0.00);
  const [incommingQuantity, setIncommingQuantity] = useState(0);
  const [incommingCost, setIncommingCost] = useState(0.00);
  const [phoneNumbersQuantity, setPhoneNumbersQuantity] = useState(0);
  const [phoneNumbersCost, setPhoneNumbersCost] = useState(0.00);
  const [verificationsQuantity, setVerificationsQuantity] = useState(0);
  const [verificationsCost, setVerificationsCost] = useState(0.00);
  const [subTotal, setSubTotal] = useState(0.00);
  const [taxes, setTaxes] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency")
      ? localStorage.getItem("currency")
      : ""
  );
  const [currentBalance, setCurrentBalance] = useState(
    localStorage.getItem("display_balance")
      ? localStorage.getItem("display_balance")
      : ""
  );
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

  useEffect(() => {
    if (!currency || !currentBalance) {
      getTeamAPI(
        (data) => {
          setCurrency(data[0].region.currency_symbol);
          setCurrentBalance(data[0].display_balance);
          localStorage.setItem("currency", data[0].region.currency_symbol);
          localStorage.setItem("display_balance", data[0].display_balance);
        },
        (error) => {}
      );
    }
    fetchData();
  }, []);

  const handleMakePayment = () => {
    setShowAddPaymentModal(true);
  };

  const fetchData = () => {
    var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = months.indexOf(new Date().toLocaleString('default', { month: 'long' })) + 1;
    setUsageMonth(new Date().toLocaleString('default', { month: 'long' }));
    getAccountUsageForMonth(
      "LIVE",
      month,
      new Date().getUTCFullYear(),
      (data) => {
        let outGoingObject = (data.api_usage).filter(data => data.api_direction == "OUTGOING" && data.api_type == "MESSAGING")[0];
        let quantitySMS = 0;
        let costSMS = 0;
        if (data.region.tax_included) {
          setSubTotal(data.total-data.tax);
          setTaxes(data.tax);
          setTotal(data.total);
        } else {
          setSubTotal(data.total);
          setTaxes(data.total*data.region.tax_percentage);
          setTotal(data.total*data.region.tax_percentage + data.total);
        }
        if(outGoingObject.projects) {
          (outGoingObject.projects).map((el, i) => (
            quantitySMS = (quantitySMS + el.qty)
          ));
          (outGoingObject.projects).map((el, i) => (
            costSMS = (costSMS + el.cost)
          ));
        }
        setOutGoingQuantity(quantitySMS);
        setOutGoingCost(costSMS);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <>
      <Container className="cus-container main-container billing-cus-container" style={{marginLeft: '2%'}}>
        <Row className="m-0 cus-content-div">
          <Col className="p-0">
            {pageLoading ? (
              <div className="loading-container">
                <Spinner animation="border" size="md" />
              </div>
            ) : (
              <>
                <div className="main-header-div">
                  <div className="header-div">
                    <h5 className="sec-heading2">Billing Overview</h5>
                  </div>
                  <hr className="live-hr" />
                </div>
                <div className="overview-det-div">
                  <p style={{marginTop: '5px'}}>
                    <div className="billing-small-label"><h6>{localStorage.getItem("team") && JSON.parse(localStorage.getItem("team")).name}</h6></div>
                  </p>
                  <p>
                    <div className="billing-small-label">Current Balance</div>
                    <div className="current-balance">{currency} {currentBalance}</div>
                  </p>
                  <p>
                    <div className="billing-small-label">Last Payment (on {lastPaymentDate})</div>
                    <div><b>{currency} {lastPaymentAmount.toFixed(2)}</b></div>
                  </p>
                  <p>
                    <button
                      type="submit"
                      className="btn-submit make-payment-btn"
                      onClick={handleMakePayment}
                    >
                    Make Payment
                    </button>
                  </p>
                </div>
                <div
                  style={{ position: "relative", margin: "auto" }}
                >
                  <h5 className="usage-summary-heading">Usage Summary</h5>
                  <span style={{fontSize: "14px"}}>{usageMonth}</span>
                  <Table
                    className="custom-table"
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    <thead>
                      <tr>
                        <th align="center">Product</th>
                        <th align="center">Quantity</th>
                        <th align="center">Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Outgoing SMS</td>
                        <td>{outGoingQuantity}</td>
                        <td align="right">{currency} {outGoingCost.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Incomming SMS</td>
                        <td>{incommingQuantity}</td>
                        <td align="right">{currency} {incommingCost.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Phone Numbers</td>
                        <td>{phoneNumbersQuantity}</td>
                        <td align="right">{currency} {phoneNumbersCost.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Verifications</td>
                        <td>{verificationsQuantity}</td>
                        <td align="right">{currency} {verificationsCost.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table
                    className="custom-table"
                    style={{ width: "100%", marginTop: "-15px" }}
                  >
                    <thead style={{ background: "transparent" }}>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>                     
                      <tr className="customized-row">
                        <td>Subtotal</td>
                        <td></td>
                        <td align="right">{currency} {subTotal ? subTotal.toFixed(2) : '0.00'}</td>
                      </tr>
                      <tr className="customized-row">
                        <td>Government Taxes and Levies</td>
                        <td></td>
                        <td align="right">{currency} {taxes ? taxes?.toFixed(2) : '0.00'}</td>
                      </tr>
                      <tr className="customized-row">
                        <td><b>Total</b></td>
                        <td></td>
                        <td align="right"><b>{currency} {total ? total?.toFixed(2) : '0.00'}</b></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <AddPaymentModal
        title="Create Campaign"
        message="Are you sure you want to create this campaign ?"
        confirmBtnName="Create"
        confirmModalType="Update"
        show={showAddPaymentModal}
        onHide={() => setShowAddPaymentModal(false)}
      />
    </>
  );
}

export default withRouter(BillingOverviewComp);