import React, { useState, useEffect } from "react";
import { Link, withRouter, useParams } from "react-router-dom";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Table,
  Breadcrumb,
  Spinner,
  Badge
} from "react-bootstrap";
import {
  Switch,
  FormControlLabel
} from "@material-ui/core";
import { getCampaignDetails } from "../../api/campaignAPI";
import { withStyles } from '@material-ui/core/styles';
import { getGroups } from "../../api/groupAPI";

function CampaignDetailsComp({location}) {
  const history = useHistory()
  const [campaignData, setCampaignData] = useState({});
  const { id: campaignId } = useParams();
  const [groups, setGroups] = useState([]);
  const [proId, setProId] = useState(
    localStorage.getItem("selected_project")
      ? localStorage.getItem("selected_project")
      : ""
  );
  const [preLoading, setPreLoading] = useState(true);
  useEffect(() => {
    getCampaignDetails(
      campaignId,
      proId,
      true,
      (data) => {
        setPreLoading(false);
        setCampaignData(data);
      },
      (err) => {
        setPreLoading(false);
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    getGroups(
      (data) => {
        setGroups(data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const CustomSwitch = withStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: 'white',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#EF6C00',
          borderColor: 'white',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid white`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#BDBDBD',
    },
    checked: {},
  })(Switch);

  return (
    <Container>
      <button
        onClick={()=> history.replace("/campaigns",location.state)}
        style={{ color: '#333333', background: 'transparent', display: 'inlineBlock', fontSize: '14px', textDecoration: 'none', border: 'none', paddingLeft: "0px", paddingBottom: "10px" }}
      >
        <i className="fas fa-angle-left"></i> Back
      </button>
      <Breadcrumb>
        <Breadcrumb.Item href="/campaigns">Campaigns</Breadcrumb.Item>
        <Breadcrumb.Item active style={{fontFamily:'monospace'}}>{campaignId}</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="m-0 cus-content-div">
        <Col className="p-0">
          <div className="main-header-div">
            <div className="header-div">
              <h5 className="sec-heading2">Campaign Details</h5>
              <FormControlLabel
                style={{pointerEvents: 'none'}}
                label="Test Mode"
                labelPlacement="start"
                className="switch-label-live"
                control={
                  <CustomSwitch
                    checked= {false}
                    name="checkedB"
                    color="primary"
                  />
                }
              />
            </div>
            <div>
              <hr className='live-hr' style={{marginBottom: '4px'}}/>
            </div>
          </div>
          {preLoading ? (
            <div className="loading-container">
              <Spinner animation="border" size="md" />
            </div>
          ) : (
            <>              
              <div className="sms-detail-sec">
                <Row className="m-0">
                  <Col xs="4" md="2" className="first-col">
                    Campaign ID :
                  </Col>
                  <Col xs="8" md="4" style={{fontFamily:'monospace'}}>
                    {campaignData.id}
                  </Col>
                  <Col xs="4" md="2" className="first-col">
                    Campaign Name :
                  </Col>
                  <Col xs="8" md="4">
                    {campaignData.name}
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs="4" md="2" className="first-col">
                    Sent At :
                  </Col>
                  <Col xs="8" md="4">
                    {campaignData.send_at &&
                      moment
                        .utc(campaignData.send_at)
                        .local()
                        .format("ddd, DD MMM YYYY hh:mm:ss a")}
                  </Col>
                  <Col xs="4" md="2" className="first-col">
                    From :
                  </Col>
                  <Col xs="8" md="4">
                    {campaignData.from}
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs="4" md="2" className="first-col">
                    Message Count :
                  </Col>
                  <Col xs="8" md="4">
                    {(campaignData.messages) ? campaignData.messages.length : '0'}
                  </Col>
                </Row>
                <Row className="mt-3 mb-5 m-0">
                  <Col xs="4" md="2" className="first-col">
                    Message Content :
                  </Col>
                  <Col xs="8" md="10" className="messege-content">
                    {campaignData.content}
                  </Col>
                </Row>                
              </div>
              <h5 className="sec-heading">Group Details</h5>
              <div style={{ position: "relative" }}>
                <Table className="custom-table" responsive>
                  <thead>
                    <tr>
                      <th>Campaign</th>
                      <th>Group Id</th>
                      <th>Group Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups && groups.length > 0 && campaignData.include_groups_detail && campaignData.include_groups_detail.length > 0 && campaignData.include_groups_detail.map((el, i) => (
                      <tr>
                        <td>{i+1}</td>
                        <td style={{fontFamily:'monospace'}}>{el.id}</td>
                        <td>{groups.filter(group => group.id == el.id).length > 0 ? groups.filter(group => group.id == el.id)[0].name : " "}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(CampaignDetailsComp);
