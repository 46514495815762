import React from "react";

import DashboardLayout from "../components/layouts/DashboardLayout";
import DashboardComp from "../components/dashboard/DashboardComp";
import PageTitle from "../components/common/PageTitle";

import "../styles/dashboard.css";

function Dashboard() {
  return (
    <DashboardLayout>
      <PageTitle page="Home" />
      <DashboardComp />
    </DashboardLayout>
  );
}

export default Dashboard;
