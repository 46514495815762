import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Table } from "react-bootstrap";
import { getUserSessionsAPI } from "../../api/authAPI";
import Pagination from "@material-ui/lab/Pagination";

const LoginSessionsTable = (props) => {
  const [sessions, setSessions] = useState([]);
  const [page, setPage] = useState(1);

  // pagination
  const itemsPerPage = 10;
  const noOfPages = Math.ceil(sessions.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = sessions.slice(indexOfFirst, indexOfLast);

  const handlePagination = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    handleGetUserSessions();
  }, [props.loading]);

  const handleGetUserSessions = () => {
    getUserSessionsAPI(
      (data) => {
        setSessions(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getBrowser = (userAgent) => {
    let agent = function (regexp) {
      return regexp.test(userAgent);
    };
    switch (true) {
      case agent(/edg/i):
        return "Microsoft Edge";
      case agent(/trident/i):
        return "Microsoft Internet Explorer";
      case agent(/firefox|fxios/i):
        return "Mozilla Firefox";
      case agent(/opr\//i):
        return "Opera";
      case agent(/ucbrowser/i):
        return "UC Browser";
      case agent(/samsungbrowser/i):
        return "Samsung Browser";
      case agent(/chrome|chromium|crios/i):
        return "Google Chrome";
      case agent(/safari/i):
        return "Apple Safari";
      default:
        return "Unknown";
    }
  };

  return (
    <Col xl="8">
      <Table
        responsive
        style={{ width: "100%" }}
        className="custom-table sms-custom-table"
      >
        <thead className="table-header-bg">
          <tr>
            <th>LOCATION</th>
            <th>DEVICE</th>
            <th>IP ADDRESS</th>
            <th>TIME</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((session) => (
              <tr key={session.created_at}>
                <td>
                  {session.country?.name}{" "}
                  {session.is_current_session ? (
                    <span className="active-session-text">
                      (Active session)
                    </span>
                  ) : null}
                </td>
                <td>
                  {getBrowser(session.user_agent)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                <td>{session.ip}</td>
                <td>
                  {moment
                    .utc(session.created_at)
                    .local()
                    .format("YYYY-MM-DD [at] hh:mm:ss a")}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <div className="pagination-div">
        {noOfPages > 1 && (
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handlePagination}
            className="pagi"
          />
        )}
      </div>
    </Col>
  );
};

export default LoginSessionsTable;
