import React, { useState } from "react";

import SettingsLayout from "../components/layouts/SettingsLayout";
import ProjectSettings from "../components/settings/ProjectSettings";
import PageTitle from "../components/common/PageTitle";
import SettingsComp from "../components/settings/SettingsComp";

function Settings() {
  return (
    <SettingsLayout>
      <PageTitle page="Settings" />
      <SettingsComp/>
    </SettingsLayout>
  );
}

export default Settings;
