import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../../styles/main.css";
import Logo from "../../images/logo.png";
import {
  NavLink,
} from "react-router-dom";

const Navigation = () => { 
  return (
    <div className="nav-bar-main-div">
      <Navbar bg="dark" variant="dark" className="main-nav">
        <Container>
          <div className="nav-icon-project-select-wrapper">
            <div className="nav-logo">
              <img src={Logo} alt="sms160 logo" />
            </div>
          </div>
          <div className="main-nav-links-wrapper">
            <div className="main-nav-links">
              <Nav className="mr-auto">
                <NavLink
                  to="/about"
                  className="nav-link"
                  activeClassName="active-nav-link"
                >
                  Api
                </NavLink>
                {/* <NavLink
                  to="/about"
                  className="nav-link"
                  activeClassName="active-nav-link"
                >
                  Support
                </NavLink> */}
                <NavLink
                  to="/"
                  className="nav-link"
                  activeClassName=""
                >
                  Login
                </NavLink>
              </Nav>
            </div>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigation;
