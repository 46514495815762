import React, { useState, useEffect } from "react";
import { Link, withRouter, useParams } from "react-router-dom";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Table,
  Breadcrumb,
  Form,
  InputGroup,
  Spinner,
  Badge
} from "react-bootstrap";
import {
  Switch,
  FormControlLabel
} from "@material-ui/core";
import { getMessageDetails } from "../../api/smsAPI";
import { withStyles } from '@material-ui/core/styles';

function LogsDetailsComp({location}) {
  const history = useHistory()
  const [smsData, setSmsData] = useState({});
  const { id: smsId } = useParams();
  const [proId, setProId] = useState(
    localStorage.getItem("selected_project")
      ? localStorage.getItem("selected_project")
      : ""
  );
  const [preLoading, setPreLoading] = useState(true);

  useEffect(() => {
    getMessageDetails(
      smsId,
      proId,
      (data) => {
        setPreLoading(false);
        setSmsData(data);
      },
      (err) => {
        setPreLoading(false);
        console.log(err);
      }
    );
  }, []);

  const getTimeDifference = (time1, time2) => {
    const diff = (moment(time1).valueOf() - moment(time2).valueOf());

    return (diff / 1000).toFixed(3);
  };

  const renderStatus = (param) => {
    switch(param) {
      case 'DELIVERED':
        return'Delivered';
      case 'ACCEPTED':
        return 'Queued';
      case 'SENT':
        return 'Sent';
      case 'SEND_ERROR':
        return 'Failed';
      default:
        return 'Failed';
    }
  }

  const CustomSwitch = withStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: 'white',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#EF6C00',
          borderColor: 'white',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid white`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#BDBDBD',
    },
    checked: {},
  })(Switch);

  return (
    <Container>
      <button
        onClick={()=> history.replace("/logs",location.state)}
        style={{ color: '#333333', background: 'transparent', display: 'inlineBlock', fontSize: '14px', textDecoration: 'none', border: 'none', paddingLeft: "0px", paddingBottom: "10px" }}
      >
        <i className="fas fa-angle-left"></i> Back
      </button>
      <Breadcrumb>
        <Breadcrumb.Item href="/logs">Logs</Breadcrumb.Item>
        <Breadcrumb.Item active style={{fontFamily:'monospace'}}>{smsId}</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="m-0 cus-content-div">
        <Col className="p-0">
          <div className="main-header-div">
            <div className="header-div">
              <h5 className="sec-heading2">SMS Details</h5>
              <FormControlLabel
                style={{pointerEvents: 'none'}}
                label="Test Mode"
                labelPlacement="start"
                className={(smsData.mode === "TEST") ? "switch-label-test" : "switch-label-live"}
                control={
                  <CustomSwitch
                    checked= {(smsData.mode === "TEST")}
                    name="checkedB"
                    color="primary"
                  />
                }
              />
            </div>
            {smsData.mode == "LIVE" &&
              <div>
                <hr className='live-hr' style={{marginBottom: '4px'}}/>
              </div>
            }
            {smsData.mode == "TEST" &&
              <div>
                <hr className='test-hr'/>
                <Badge bg="warning" text="dark" className="test-label">
                  TEST MODE
                </Badge>
              </div>
            }
          </div>
          {preLoading ? (
            <div className="loading-container">
              <Spinner animation="border" size="md" />
            </div>
          ) : (
            <>
              <div className="sms-detail-sec">
                <Row className="m-0">
                  <Col xs="4" md="2" className="first-col">
                    Message ID :
                  </Col>
                  <Col xs="8" md="4">
                    {smsData.id}
                  </Col>
                  <Col xs="4" md="2" className="first-col">
                    API :
                  </Col>
                  <Col xs="8" md="4">
                    {smsData.direction === "OUTGOING"
                      ? "Outgoing"
                      : smsData.direction === "INCOMING"
                      ? "Incoming"
                      : "Verify"}
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs="4" md="2" className="first-col">
                    Sent At :
                  </Col>
                  <Col xs="8" md="4">
                    {smsData.events &&
                      moment
                        .utc(smsData.events.sent_at)
                        .local()
                        .format("ddd, DD MMM YYYY hh:mm:ss a")}
                  </Col>
                  <Col xs="4" md="2" className="first-col">
                    Message Length :
                  </Col>
                  <Col xs="8" md="4">
                    {smsData.length}
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs="4" md="2" className="first-col">
                    From :
                  </Col>
                  <Col xs="8" md="4">
                    {smsData.from}
                  </Col>
                  <Col xs="4" md="2" className="first-col">
                    Message Pages :
                  </Col>
                  <Col xs="8" md="4">
                    {smsData.segments}
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs="4" md="2" className="first-col">
                    To :
                  </Col>
                  <Col xs="8" md="4">
                    {smsData.to}
                  </Col>
                  <Col xs="4" md="2" className="first-col">
                    Cost :
                  </Col>
                  <Col xs="8" md="4">
                    {smsData.currency} {smsData.cost}
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs="4" md="2" className="first-col">
                    Status :
                  </Col>
                  <Col xs="8" md="4">
                    {renderStatus(smsData.status)}
                  </Col>
                  <Col xs="4" md="2" className="first-col">
                  </Col>
                  <Col xs="8" md="4">
                  </Col>
                </Row>
                <Row className="mt-3 mb-5 m-0">
                  <Col xs="4" md="2" className="first-col">
                    Message Content :
                  </Col>
                  <Col xs="8" md="10" className="messege-content">
                    {smsData.body}
                  </Col>
                </Row>
              </div>

              {/* <Link to="#" className="view-related-messages">
            View Related Messages
          </Link> */}

              <h5 className="sec-heading">Delivery Details</h5>

              <div style={{ position: "relative" }}>
                <Table className="custom-table" responsive>
                  <thead>
                    <tr>
                      <th>Event</th>
                      <th>Platform</th>
                      <th>Timestamps</th>
                      <th>Elapsed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {smsData.events && smsData.events.accepted_at && (
                      <tr>
                        <td>Message Accepted</td>
                        <td>SMS160 Platform</td>
                        <td>
                          {smsData.events &&
                            moment
                              .utc(smsData.events.accepted_at)
                              .local()
                              .format("YYYY-MM-DD hh:mm:ss a")}
                        </td>
                        <td>0 sec</td>
                      </tr>
                    )}
                    {smsData.events && smsData.events.queued_at && (
                      <tr>
                        <td>Queued</td>
                        <td>SMS160 Outgoing Queue</td>
                        <td>
                          {smsData.events &&
                            moment
                              .utc(smsData.events.queued_at)
                              .local()
                              .format("YYYY-MM-DD hh:mm:ss a")}
                        </td>
                        <td>
                          {getTimeDifference(
                            smsData.events.queued_at,
                            smsData.events.accepted_at
                          )}{" "}
                          sec
                        </td>
                      </tr>
                    )}
                    {smsData.events && smsData.events.sent_at && (
                      <tr>
                        <td>Sent</td>
                        <td>Network Service Provider</td>
                        <td>
                          {smsData.events &&
                            moment
                              .utc(smsData.events.sent_at)
                              .local()
                              .format("YYYY-MM-DD hh:mm:ss a")}
                        </td>
                        <td>
                          {getTimeDifference(
                            smsData.events.sent_at,
                            smsData.events.queued_at
                          )}{" "}
                          sec
                        </td>
                      </tr>
                    )}
                    {smsData.events && smsData.events.delivered_at && (
                      <tr>
                        <td>Delivered</td>
                        <td>Network Service Provider</td>
                        <td>
                          {smsData.events &&
                            moment
                              .utc(smsData.events.delivered_at)
                              .local()
                              .format("YYYY-MM-DD hh:mm:ss a")}
                        </td>
                        <td>
                          {getTimeDifference(
                            smsData.events.delivered_at,
                            smsData.events.sent_at
                          )}{" "}
                          sec
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {smsData.status_callback &&
                smsData.status_callback.url &&
                smsData.status_callback.url && (
                  <Form.Row className="mt-3 m-auto" style={{ width: "96%" }}>
                    <Form.Group as={Col} controlId="validationFormikUsername2">
                      <Form.Label style={{ fontWeight: 500 }}>
                        Status Callback
                      </Form.Label>
                      <InputGroup className="status-callback">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">
                            200 OK
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="text"
                          aria-describedby="inputGroupPrepend"
                          value={smsData.status_callback.url}
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>
                )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(LogsDetailsComp);
